import {
  ArrowUp,
  VolumetricWeightImg,
  VolumetricWeightMobileImg
} from '../../constants/asset-constants';

export const PricingInfo = ({ handleToggle, activeArticle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'pricing-info' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>What are the shipping options on Topship?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'pricing-info' ? 'show' : 'hide'}`}>
        <div>
          <p>
            Topship offers flexibile shipping options based on your shipment
            pick up and destination details. To find out the options available
            to you, follow any of these steps:
          </p>
          <ul>
            <li>
              Visit the <b>Price Calculator</b> on topship.africa, enter your
              delivery details. All available shipping options for your route
              will be presented to you.
            </li>
            <li>
              Go to the <b>Services</b> dropdown menu on the top navigation bar
              on our website and select your preferred service. For example, if
              you select <b>Export</b>, you will be directed to our dedicated
              page for <b>Exports</b> showcasing price, timeline, and other
              considerations.
            </li>
            <li>
              Visit one of our physical hubs. You can find our complete hub list
              here:{' '}
              <a
                href='/about'
                target='_blank'
                rel='noreferrer'
                style={{
                  fontWeight: 'bold'
                }}
              >
                topship.africa/about
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export const VolumetricWeight = ({ handleToggle, activeArticle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${
        activeArticle === 'volumetric-weight' ? 'active' : ''
      }`}
    >
      <div className='flexed-heading'>
        <h2>What is volumetric weight?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div
        className={`${activeArticle === 'volumetric-weight' ? 'show' : 'hide'}`}
      >
        <img
          src={VolumetricWeightImg}
          alt='volumetric weight'
          className='volumetric-img-web'
        />
        <img
          src={VolumetricWeightMobileImg}
          alt='volumetric weight-mobile'
          className='volumetric-img-mobile'
        />
      </div>
    </div>
  );
};

export const ProhibitedItems = ({ handleToggle, activeArticle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${
        activeArticle === 'prohibited-lists' ? 'active' : ''
      }`}
    >
      <div className='flexed-heading'>
        <h2>Prohibited and restricted items</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div
        className={`${activeArticle === 'prohibited-lists' ? 'show' : 'hide'}`}
      >
        <p>
          See the exhaustive list of prohibited items{' '}
          <a
            href='https://blog.topship.africa/prohibited-restricted-items/'
            rel='noreferrer'
            target={'_blank'}
          >
            here
          </a>
          .
        </p>
      </div>
    </div>
  );
};
