import { addressLists } from '../constants/contact-lists';
import { buildingImage } from '../constants/asset-constants';

const DropOffHubs = ({ children }: any) => {
  return (
    <div id='drop-off-centers' className='about'>
      <div className='about__contact'>
        <div className='about__contact__wrap'>
          <div className='about__contact__header'>
            <h2 className='mb-2'>Topship Drop-off centers</h2>
            <p className='sub-content'>
              Don't want to book online? Walk in and drop off your packages at
              any of these locations.
            </p>
          </div>

          <div
            style={{
              width: '100%'
            }}
            className=''
          >
            <div className='about__contact__item__content'>
              <div
                className=''
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  width: '100%',
                  textAlign: 'left',
                  border: '1px solid #e5eaf4',
                  borderRadius: '12px',
                  boxShadow: '0px 15px 24px rgba(0, 0, 0, 0.04)',
                  overflow: 'hidden',
                  background: '#fff'
                }}
              >
                {addressLists.map((item, index) => {
                  return (
                    <div
                      style={{
                        borderRight: index === 3 ? '' : '1px solid #e5eaf4'
                      }}
                      key={index}
                      className='drops'
                    >
                      <div className='about__contact__item__icon mb-3'>
                        <img src={buildingImage} className='img-fluid' alt='' />
                      </div>

                      <div className='address_header mb-1'>
                        <h4>{item.location}</h4>
                      </div>
                      <div className='address_content'>
                        <p
                          style={{
                            textAlign: 'left'
                          }}
                        >
                          {item.address}
                        </p>
                      </div>
                    </div>
                  );
                })}
                <div className='drops hide-space'></div>
                <div className='drops hide-space'></div>
              </div>
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default DropOffHubs;
