import React from 'react';

interface Props {
  heading: string;
  ctaText?: string;
  ctaLink?: string;
}

const FooterCta: React.FC<Props> = ({ heading, ctaText, ctaLink }) => {
  return (
    <div className='new-home-page__footer__cta'>
      <div
        style={{
          position: 'relative'
        }}
        className='new-home-page__footer__cta__header'
      >
        <h2>{heading}</h2>
      </div>
      <div className='new-home-page__footer__cta__buttons'>
        <a
          href='https://ship.topship.africa/signup'
          target='_blank'
          rel='noreferrer'
        >
          Get Started
        </a>
        <a
          href={!!ctaLink ? ctaLink : '/#request-quote'}
          target={ctaText ? '_blank' : ''}
          rel='noreferrer'
        >
          {!!ctaText ? ctaText : 'Request a quote'}
        </a>
      </div>
    </div>
  );
};

export default FooterCta;
