import moment from "moment";
import { Helmet } from "react-helmet";
import { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import calendar from "../../assets/images/calendar.png";
import user from "../../assets/images/user.png";
import Footer from "../footer";
import Nav from "../nav";

interface Props {
  title?: string;
  description?: string;
  date?: string;
  author?: string;
  image?: string;
  slug?: string;
  content?: string;
}

const BlogDetail: FC<Props> = () => {
  const [mobileMenu, setMobileMenu] = useState<string>("default");
  const { state } = useLocation();
  const post: any = state;

  return (
    <>
      <Helmet>
        <meta name="description" content={`${post?.post?.slug}`} />
        <title>Topship Africa: Blog</title>
        <link
          rel="canonical"
          href={`https://topship.africa/blog/${post?.post?.slug} `}
        />
      </Helmet>
      <Nav mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />

      <article className="blog-detail">
        <div className="blog-detail__metadata">
          <div>
            <img src={calendar} alt="calendar icon" />
            <span>{moment(post?.post?.date).format("LL")}</span>
          </div>

          <div>
            <img src={user} alt="user icon" />
            <span>
              {post?.post?.yoast_head_json?.twitter_misc["Written by"]}
            </span>
          </div>
        </div>
        <h1>{post?.post?.title?.rendered?.replace("&#8217;", "'")}</h1>
        <div className="blog-detail__image">
          <figure>
            <img
              src={
                post?.post?.yoast_head_json?.og_image === undefined
                  ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoBrkn-BRHxL0K7mN_7Kfbj8ptPaUhaqrrFw&usqp=CAU"
                  : post?.post?.yoast_head_json?.og_image[0]?.url
              }
              alt="blog-detail item"
            />
          </figure>
        </div>
        <div
          className="blog-detail__content"
          dangerouslySetInnerHTML={{ __html: post?.post?.content?.rendered }}
        />
      </article>

      <Footer />
    </>
  );
};

export default BlogDetail;
