import { Link } from 'react-router-dom';
import NewFooter from '../components/new-footer';
import GetMobileApp from '../components/new-home-page/get-mobile-app';
import {
  CircularCheck,
  CircularWarning,
  ListCheck
} from '../constants/asset-constants';
import { useState } from 'react';

const ShippingChecklistPage = () => {
  const [trueShipper, setTrueShipper] = useState(false);
  return (
    <>
      <div className='shipping-checklist'>
        <section className='shipping-checklist__intro-section'>
          <div className='intro-wrap'>
            <h2 className='heading'>
              Shipping Checklist
              <br />
              for Smart Shippers
            </h2>
            <p>
              When shipping internationally, certain aspects can be
              unpredictable and expensive, like deliveries to the wrong
              location, high customs clearance fees, weight discrepancies, etc.
              Follow these shipping guidelines to save both time and money.
            </p>
          </div>
        </section>

        <section className='shipping-checklist__guidelines'>
          <p className='tag'>SMART SHIPPING CHECKLIST</p>
          <h2 className='heading'>
            Shipping Guidelines to save <br /> you time and money
          </h2>
          <div className='guideline-group'>
            <div className='list'>
              <img src={ListCheck} alt='' />
              <div>
                <p>Ensure contents are acceptable for air transportation</p>
                <Link to='/prohibited-items'>
                  <p
                    style={{
                      color: '#22428f'
                    }}
                  >
                    See Prohibited Items
                  </p>
                </Link>
              </div>
            </div>
            <div className='list'>
              <img src={ListCheck} alt='' />

              <p>
                Ensure{' '}
                <span
                  style={{
                    color: '#22428f',
                    cursor: 'pointer'
                  }}
                  onClick={() => setTrueShipper(!trueShipper)}
                >
                  True Shipper
                </span>{' '}
                and recipient details are written on the waybill and commercial
                invoice.
              </p>
              {trueShipper ? (
                <div className='true-shipper'>
                  <p
                    style={{
                      textTransform: 'capitalize'
                    }}
                  >
                    a true shipper is a identifiable human with unique{' '}
                    <br className='hide-break' /> personal information
                  </p>
                  <div className='correct-true-shipper'>
                    <img src={CircularCheck} alt='check' />
                    <p className='mb-0'>
                      Ekene Peter 09027265637 ekp@yahoo.com
                    </p>
                  </div>
                  <div className='wrong-true-shipper'>
                    <img src={CircularWarning} alt='check' />
                    <p className='mb-0'>
                      Ekene Peter 09027265637 ekp@yahoo.com
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
            <div className='list'>
              <img src={ListCheck} alt='' />
              <p>
                Ensure all items are confirmed/counted and match your commercial
                invoice and item description. Be thorough to avoid
                under-declaring
              </p>
            </div>
            <div className='list'>
              <img src={ListCheck} alt='' />
              <p>
                Ensure proper and transparent item breakdown is done on the
                commercial invoice and invoice assigned by the shipper.
              </p>
            </div>
          </div>
        </section>

        <GetMobileApp />
        <section className='new-home-page__footer'>
          <NewFooter />
        </section>
      </div>
    </>
  );
};

export default ShippingChecklistPage;
