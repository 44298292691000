import axios from 'axios';
import { NigerianPopularCities } from '../constants/cities';
import { allCountriesJSONData } from '../constants/countries';
import { NigerianStates } from '../constants/states';
import { History, LocationState } from 'history';

const getLocationTexts = (
  shippingInfo: any,
  history: History<LocationState>,
  { success, failure, setSuccess, setFailure }: any
) => {
  const pageURL = window?.location
    ? window?.location?.pathname?.split('/')
    : [];

  let senderParams = pageURL?.[1] === 'uk' ? 'gb' : pageURL?.[1];
  const keywords = pageURL.filter(page => page).slice(1);
  const receiverParams = keywords.length
    ? keywords[keywords.length - 1]
    : pageURL[2];
  const removeWhiteSpace = (array: string[]) =>
    array.map(state => state.split(' ').join(''));
  const statesAndCities = [...NigerianPopularCities, ...NigerianStates];
  const regex = (word?: string) =>
    word?.toLowerCase() !== 'nigeria' && pageURL[2]?.toLowerCase() !== 'nigeria'
      ? new RegExp(removeWhiteSpace(statesAndCities).join('|'), 'i').test(
          word || pageURL[2]
        )
      : false;

  const receiverCountryCode = regex()
    ? 'ng'
    : pageURL[2] === 'uk'
    ? 'gb'
    : pageURL[2];

  //eslint-disable-next-line
  const removeHyphens = (word: string) =>
    word?.split('-')?.join(' ')?.toLowerCase();

  const handle = async () => {
    if (keywords.length) {
      keywords.forEach((word, wordIndex) => {
        if (wordIndex === 0) {
          const wordCouldBeUK =
            word?.toLowerCase() === 'uk' ? 'gb' : word?.toLowerCase();
          const existingCountry = allCountriesJSONData.find(
            country =>
              country.code?.toLowerCase() === wordCouldBeUK ||
              (regex(word) && country.code.toLowerCase() === 'ng')
          );
          if (existingCountry) {
            setSuccess((num: number) => num + 1);
          } else {
            axios
              .get(
                `${process.env.REACT_APP_REST_API}?city=${
                  word.split('-')[0]
                }&countryCode=${receiverCountryCode}`
              )
              .then(({ data }) => {
                if (data?.postalLocationList?.length) {
                  setSuccess((num: number) => num + 1);
                } else {
                  setFailure((num: number) => num + 1);
                }
              });
          }
        } else {
          axios
            .get(
              `${process.env.REACT_APP_REST_API}?city=${
                word.split('-')[0]
              }&countryCode=${receiverCountryCode}`
            )
            .then(({ data }) => {
              if (data?.postalLocationList?.length) {
                setSuccess((num: number) => num + 1);
              } else {
                setFailure((num: number) => num + 1);
              }
            });
        }
      });
    }
  };
  const isExecuted = success + failure < keywords.length;
  if (isExecuted) {
    handle();
  }

  let sender = senderParams?.charAt(0).toUpperCase() + senderParams?.slice(1);
  sender =
    sender?.toLocaleLowerCase() ===
    shippingInfo?.sender?.countryCode?.toLocaleLowerCase()
      ? shippingInfo?.sender?.country
      : sender;

  let receiver = receiverParams
    ? receiverParams?.charAt(0).toUpperCase() + receiverParams?.slice(1)
    : '';

  receiver =
    (pageURL?.[2] &&
      receiver?.toLocaleLowerCase() ===
        shippingInfo?.receiver?.countryCode?.toLocaleLowerCase()) ||
    receiver?.toLocaleLowerCase() === 'uk'
      ? shippingInfo?.receiver?.country
      : receiver;

  const senderLocation = sender || shippingInfo?.sender?.country;

  const allKeywordsAreCorrect =
    shippingInfo?.receiver?.country && !failure && success >= keywords.length;

  const receiverLocation = allKeywordsAreCorrect ? receiver : '';

  if (failure) {
    history.push('/404');
  }

  return {
    senderLocation,
    receiverLocation
  };
};

export default getLocationTexts;
