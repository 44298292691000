import { ArrowUp } from '../../constants/asset-constants';

export const AllowedFoodstuffs = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${
        activeArticle === 'allowed-foodstuffs' ? 'active' : ''
      }`}
    >
      <div className='flexed-heading'>
        <h2>What foodstuff can I ship?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div
        className={`${
          activeArticle === 'allowed-foodstuffs' ? 'show' : 'hide'
        }`}
      >
        <p>
          You can ship grains, beans, spices, dry pepper, ogbono, egusi
          (grounded), Crayfish (Blended and Dried), prawns, dry fish, and oils
          (vegetable, palm, coconut, castor, etc.)
        </p>
        <p>
          If shipping dried fish, please note that you can only ship a maximum
          of 10kg per waybill
        </p>
        <p>
          If shipping goat meat, please note that you can only ship a maximum of
          2kg per waybill, and it can only be shipped with our Budget service.
        </p>
        <p>
          If shipping snails, please note that a snail classification form is
          required, and you can only ship a maximum of 2kg per waybill. Also,
          snails can only be shipped with our Express or Budget service.
        </p>
      </div>
    </div>
  );
};

export const ProhibitedFoodstuffs = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${
        activeArticle === 'prohibited-foodstuffs' ? 'active' : ''
      }`}
    >
      <div className='flexed-heading'>
        <h2>What foodstuff is prohibited?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div
        className={`${
          activeArticle === 'prohibited-foodstuffs' ? 'show' : 'hide'
        }`}
      >
        <p>
          Prohibited foodstuffs include cow skin (ponmo), beef, fresh fish, and
          pre-cooked meals due to strict customs and import laws.
        </p>
      </div>
    </div>
  );
};

export const ImportLocation = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${
        activeArticle === 'import-location' ? 'active' : ''
      }`}
    >
      <div className='flexed-heading'>
        <h2>Where can I ship foodstuff to?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div
        className={`${activeArticle === 'import-location' ? 'show' : 'hide'}`}
      >
        <p>
          At the moment, you can ship foodstuff only to Canada, the UK, and the
          US guaranteed. If you're looking to ship to an unlisted country,
          please contact support to inquire about import laws. Email us at
          hello@topship.africa
        </p>
      </div>
    </div>
  );
};

// export const ImportLocation = ({ activeArticle, handleToggle }: any) => {
//   return (
//     <div
//       onClick={handleToggle}
//       className={`content ${
//         activeArticle === 'import-location' ? 'active' : ''
//       }`}
//     >
//       <div className='flexed-heading'>
//         <h2>Where can I ship foodstuff to?</h2>
//         <img
//           style={{
//             height: '10px',
//             width: '10px'
//           }}
//           src={ArrowUp}
//           alt='accordion toggle button'
//         />
//       </div>
//       <div
//         className={`${activeArticle === 'import-location' ? 'show' : 'hide'}`}
//       >
//         <p>
//           At the moment, you can ship foodstuff only to Canada, the UK, and the
//           US guaranteed. If you're looking to ship to an unlisted country,
//           please contact support to inquire about import laws. Email us at
//           hello@topship.africa
//         </p>
//       </div>
//     </div>
//   );
// };
