import { instagram, linkedin, twitter } from './asset-constants';
export const addressLists = [
  {
    location: 'Abuja',
    address: '30 Howeidy A. Street, Kado, Abuja'
  },
  {
    location: 'Lagos Island',
    address: '25B, Bisola Durosinmi Etti Drive Lekki Phase 1.'
  },
  {
    location: 'Lagos Mainland',
    address: '3 Dapo Bode Thomas Street, Sabo, Yaba.'
  },
  {
    location: 'Ibadan',
    address: 'Shop A24, Grand Mall, Secretariat Road, Bodija Market.'
  },
  {
    location: 'Port Harcourt',
    address: '24 Eze Gbaka-Gbaka Avenue, Woji, Port Harcourt, Rivers state.'
  }
];

export const socialLists = [
  {
    link: 'https://twitter.com/topshipafrica',
    imgSrc: twitter
  },
  {
    link: 'https://www.instagram.com/topshipafrica/',
    imgSrc: instagram
  },
  {
    link: 'https://www.linkedin.com/company/topshipafrica/',
    imgSrc: linkedin
  }
];
