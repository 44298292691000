import React from 'react';
import NewFooter from '../components/new-footer';
import {
  featureIconFour,
  FrozenFoodsImg,
  phoneImage,
  Rate
} from '../constants/asset-constants';
import { ContactForm } from '../components/contact-form';
import { Helmet } from 'react-helmet';

export default function FrozenFoodsPage() {
  return (
    <>
      <Helmet>
        <title>Import Fresh and Frozen Foods to Nigeria with Topship</title>
        <meta
          name='description'
          content='Import fresh & frozen foods into Nigeria & receive it in 48 hours with Topship. Ensure your perishable goods stay fresh during transit with our specialized shipping solutions.'
        />
        <meta
          name='keywords'
          content='Frozen food, import frozen foods, frozen meat, import fresh food, import nigeria, import duty, import trade'
        />
      </Helmet>
      <div className='lsp'>
        <section className='lsp__intro'>
          <div
            style={{
              paddingBottom: '150px'
            }}
            className='intro-content'
          >
            <h1 className='header'>
              Import fresh & frozen foods into Nigeria & receive it in 48 hours
              with Topship!
            </h1>
            <p>
              Order your meats, poultry and more from the U.K, Amsterdam, South
              Africa & more to your preferred location in Nigeria without
              worrying about customs charges or long delivery timelines.
            </p>

            <a href='#contact-form' className='access-button'>
              Contact us
            </a>
          </div>

          <div
            style={{
              width: '55%'
            }}
            className='image-container'
          >
            <img
              style={{
                objectFit: 'contain'
              }}
              src={FrozenFoodsImg}
              alt='developer-img'
            />
          </div>
        </section>
        <section className='about__features'>
          <div className='about__features__container'>
            <div className='about__features__header'>
              <div className='about__features__question'>
                <p>WHAT WE DO</p>
              </div>
              <div className='about__howItWorks__content'>
                <h2
                  style={{
                    color: '#22428f'
                  }}
                  className=''
                >
                  Why import with Topship?
                </h2>
              </div>
            </div>
            <div className='about__services__wrap'>
              <div className='about__service about__feature'>
                <div className='about__feature__wrap'>
                  <div className='about__feature__icon'>
                    <img src={Rate} className='img-fluid' alt='' />
                  </div>

                  <div className='about__feature__content'>
                    <p>
                      YFixed, low rate of{' '}
                      <strong
                        style={{
                          fontWeight: '700'
                        }}
                      >
                        $10/kg
                      </strong>
                    </p>
                  </div>
                </div>
              </div>

              <div className='about__service about__feature'>
                <div className='about__feature__wrap'>
                  <div className='about__feature__icon'>
                    <img src={featureIconFour} className='img-fluid' alt='' />
                  </div>

                  <div className='about__feature__content'>
                    <p>Your goods are delivered to Nigeria in 48 hours</p>
                  </div>
                </div>
              </div>

              <div className='about__service about__feature'>
                <div className='about__feature__wrap'>
                  <div className='about__feature__icon'>
                    <img src={phoneImage} className='img-fluid' alt='' />
                  </div>

                  <div className='about__feature__content'>
                    <p>Dedicated customer support channel for your business</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='lsp__next-step'>
          <div className='next-step-content'>
            <p className='small-text'>What's the NEXT STEP?</p>

            <h1 className=''>
              Interested? Contact our sales team for a custom quote and
              procurement support.
            </h1>
          </div>

          <div className='btn-container'>
            <a href='#contact-form' className=''>
              Contact Us
            </a>
          </div>
        </section>
      </div>
      <ContactForm serviceTitle={'Frozen Foods'} />

      <section className='new-home-page__footer'>
        <NewFooter />
      </section>
    </>
  );
}
