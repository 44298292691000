import React, { useEffect } from 'react';
import {
  aramex,
  cargoPlug,
  curacel,
  danaAir,
  dellyman,
  dhl,
  fedex,
  overland,
  uber,
  ups
} from './asset-constants';

const Scroller = () => {
  useEffect(() => {
    const scrollers = document.querySelectorAll('.scroller');
    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      addAnimation(scrollers);
    }
  }, []);

  const addAnimation = scrollers => {
    scrollers.forEach(scroller => {
      scroller.setAttribute('data-animated', true);
      const scrollerInner = scroller.querySelector('.scroller__inner');
      const scrollerContent = Array.from(scrollerInner.children);

      scrollerContent.forEach(item => {
        const duplicatedItem = item.cloneNode(true);
        duplicatedItem.setAttribute('aria-hidden', true);
        scrollerInner.appendChild(duplicatedItem);
      });
    });
  };

  return (
    <div className='scroller'>
      <div className='scroller__inner'>
        <img src={ups} alt='UPS Logo' />
        <img src={fedex} alt='FedEx Logo' />
        <img src={cargoPlug} alt='Cargo Plug Logo' />
        <img src={aramex} alt='Aramex Logo' />
        <img src={overland} alt='Overland Logo' />
        <img src={danaAir} alt='Dana Air Logo' />
        <img src={curacel} alt='Curacel Logo' />
        <img src={dellyman} alt='Dellyman Logo' />
        <img src={uber} alt='Uber Logo' />
        <img src={dhl} alt='DHL Logo' />
        {/* Add your content here */}
      </div>
    </div>
  );
};

export default Scroller;
