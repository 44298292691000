export const NigerianPopularCities = [
  'Lagos',
  'Kano',
  'Ibadan',
  'Onitsha',
  'Owerri',
  'Abuja',
  'Maiduguri',
  'Benin City',
  'Ikare',
  'Ogbomoso',
  'Port Harcourt',
  'Abeokuta',
  'Jos',
  'Ilorin',
  'Warri',
  'Kaduna',
  'Oyo',
  'Sokoto',
  'Enugu',
  'Bauchi',
  'Ile-Ife',
  'Aba',
  'Calabar',
  'Ado-Ekiti',
  'Katsina',
  'Uyo',
  'Akure',
  'Osogbo',
  'Zaria',
  'Yola',
  'Umuahia',
  'Sapele',
  'Minna',
  'Awka',
  'Makurdi',
  'Owo',
  'Gombe',
  'Ondo',
  'Damaturu',
  'Iwo',
  'Gusau',
  'Mubi',
  'Apapa',
  'Burutu',
  'Ijebu-Ode',
  'Mai’Adua',
  'Epe',
  'Funtua',
  'Bida',
  'Offa',
  'Zango',
  'Dutse',
  'Lafia',
  'Gashua',
  'Bama',
  'Jalingo',
  'Nsukka',
  'Nguru',
  'Birnin Kebbi'
];

export const lagosCities = [
  { name: 'Abuja', link: `/abuja` },
  { name: 'Ibadan', link: `/oyo/ibadan` },
  { name: 'Lagos', link: `/lagos` },
  { name: 'Onitsha', link: `/anambra/onitsha` },
  { name: 'Benin', link: `/edo/benin` },
  { name: 'Maiduguri', link: `/borno/maiduguri` },
  { name: 'Calabar', link: `/crossriver/calabar` },
  { name: 'Port Harcourt', link: `/rivers/port-harcourt` },
  { name: 'Asaba', link: `/delta/asaba` },
  { name: 'Akure', link: `/ondo/akure` },
  { name: 'Ekiti', link: `/ekiti` },
  { name: 'Enugu', link: `/enugu` },
  { name: 'Aba', link: `/abia/aba` },
  { name: 'Uyo', link: `/akwaibom/uyo` },
  { name: 'Adamawa', link: `/adamawa` },
  { name: 'Oyo', link: `/oyo` },
  { name: 'Delta', link: `/delta` },
  { name: 'Ebonyi', link: `/ebonyi` },
  { name: 'Abeokuta', link: `/ogun/abeokuta` },
  { name: 'Kogi', link: `/kogi` }
];
