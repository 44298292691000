import { ArrowUp } from '../../constants/asset-constants';

export const ShippingTimeExport = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${
        activeArticle === 'shipping-time-export' ? 'active' : ''
      }`}
    >
      <div className='flexed-heading'>
        <h2>How long does the shipping take?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div
        className={`${
          activeArticle === 'shipping-time-export' ? 'show' : 'hide'
        }`}
      >
        <p>Delivery timeline depends on the shipping service you select.</p>
        <p>
          Express takes 3 - 7 working days (depending on your destination
          address), Saver-Priority takes 5 - 7 working days, Saver takes 10 - 12
          working days, Budget takes 7 - 15 working days, and Sea Freight takes
          8 - 12 weeks.
        </p>
      </div>
    </div>
  );
};

export const MinimumWeight = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${
        activeArticle === 'minimum-weight' ? 'active' : ''
      }`}
    >
      <div className='flexed-heading'>
        <h2>Is there a minimum weight?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div
        className={`${activeArticle === 'minimum-weight' ? 'show' : 'hide'}`}
      >
        <p>
          This depends on the shipping service you select. Sea Freight (
          <b>Ship Via Sea</b>) has a minimum weight limit of 100kg, while
          Budget, Saver, Saver-Priority, and Express have no minimum weight
          limits.
        </p>
      </div>
    </div>
  );
};

export const BudgetAndCargo = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'budget-cargo' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>Where can I ship to with Budget?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'budget-cargo' ? 'show' : 'hide'}`}>
        <p>
          You can ship to the UK, USA, Australia, Canada, France and 30+ more
          European countries with Topship’s Budget service. We’re working on
          opening up more routes in the coming months.
        </p>
      </div>
    </div>
  );
};

export const DoorstepDeliveryExport = ({
  activeArticle,
  handleToggle
}: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${
        activeArticle === 'doorstep-delivery-export' ? 'active' : ''
      }`}
    >
      <div className='flexed-heading'>
        <h2>Do you offer doorstep delivery?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div
        className={`${
          activeArticle === 'doorstep-delivery-export' ? 'show' : 'hide'
        }`}
      >
        <p>
          Yes. All shipping options, excluding Sea Freight (<b>Ship Via Sea</b>
          ), include doorstep delivery to the recipient’s home. With Sea
          Freight, the recipient will be expected to pick up the package from a
          local warehouse or pay an additional charge for doorstep delivery.
        </p>
      </div>
    </div>
  );
};
