import { GraySearchIcon, SearchIcon } from '../constants/asset-constants';

const SearchInput = ({
  value,
  onChange,
  onSearch,
  setArticleTitle,
  searchData,
  showFilter,
  setShowFilter
}) => {
  const toggleRouter = filtered => {
    setArticleTitle(article => ({
      ...article,
      title: filtered.title,
      textContent: filtered.textContent,
      articleNumber: filtered.articleNumber
    }));
    setShowFilter(false);
  };
  return (
    <div className='new-faq__search-wrapper'>
      <div className='new-faq__search-input-container'>
        <img className='search-icon white-svg' src={SearchIcon} alt='' />
        <img className='search-icon gray-svg' src={GraySearchIcon} alt='' />
        <input
          type='text'
          placeholder='Search for Articles'
          value={value}
          onChange={onChange}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onSearch();
            }
          }}
        />
      </div>
      {showFilter && value.trim() !== '' && (
        <div className='filtered-article'>
          {searchData.map((list, index) => {
            return (
              <div key={index} onClick={() => toggleRouter(list)}>
                <h4>{list.title}</h4>
                <p>{list.textContent}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchInput;
