import React from 'react';
import CustomSpinner from './custom-spinner';
import { useRecoilState } from 'recoil';
import { busyOverlayState } from '../recoil/atoms';

const BusyOverlay = ({ loading = false, text = '', speed = '.8s' }) => {
  const [isBusy] = useRecoilState(busyOverlayState);

  return (
    <div className={loading || isBusy ? 'busy-overlay--show' : 'busy-overlay'}>
      <CustomSpinner
        text={text}
        textSize='1.5rem'
        textColor='var(--color-primary)'
        size='4rem'
        thickness='.6rem'
        background='#e6e3df'
        spinColor='var(--color-blue-dark)'
        speed={speed}
      />
    </div>
  );
};

export default BusyOverlay;
