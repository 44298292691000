import React, { useEffect, useMemo, useState } from 'react';
import FaqBlock from '../components/faq-block';
import NewFooter from '../components/new-footer';
import GetMobileApp from '../components/new-home-page/get-mobile-app';
import Testimonials from '../components/testimonials';
import {
  CancelCheck,
  CancelIcon,
  CountryFlags,
  DropdownIcon,
  InfoIcon,
  LightListCheck,
  ListCheck,
  PolygonIcon
} from '../constants/asset-constants';
import { importsquestions } from '../constants/faqs';
import {
  deliveryBenefitsArray,
  packagesArray
} from '../constants/imports-lists';
import { TermsAndConditions } from '../constants/terms-&-condition';
import { ContactForm } from '../components/contact-form';
import { Helmet } from 'react-helmet';
import { budgetRatePerCountries } from '../components/new-home-page/constants';

export const CardComponent = ({ data }: any) => {
  function isFirstWordNoOrNot(tag: string) {
    const words = tag.split(' ');

    const firstWord = words[0];
    return firstWord && (firstWord === 'No' || firstWord === 'Not');
  }
  const [options, setOptions] = useState({
    country: '',
    toggleDropdown: false
  });
  const budgetRatePerCountry: { nonFlats: string; flats: string[] } =
    useMemo(() => {
      const budgetData = budgetRatePerCountries.find(rate =>
        rate.country.includes(options.country)
      );
      return budgetData ? budgetData.rates : { nonFlats: '', flats: [] }; // Return a default value if no data is found
    }, [options.country]);

  const [howItWorks, setHowItWorks] = useState(false);
  useEffect(() => {
    setOptions(options => ({
      ...options,
      country: budgetRatePerCountries[0].country
    }));
  }, []);
  return (
    <div
      className={`package-card ${
        data.title === 'Shop and Ship' || data.title === 'Budget'
          ? 'isBudget'
          : ''
      }`}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%'
        }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '20px',
              alignItems: 'center'
            }}
          >
            <p
              className={`title mb-0 ${
                data.title === 'Shop and Ship' || data.title === 'Budget'
                  ? 'isBudget'
                  : ''
              }`}
            >
              {data.title}
            </p>
            {data.isRecommended ? (
              <span className='recommended'>Recommended</span>
            ) : null}
          </div>

          <p
            className={`from ${
              data.title === 'Shop and Ship' || data.title === 'Budget'
                ? 'isBudget'
                : ''
            }`}
          >
            Starts from
          </p>
          <div className='amount-div'>
            {data.currency} {data.amount}{' '}
            <span>
              {data.currency === 'USD' ||
              (data.title === 'Budget' && data.weighsMore)
                ? 'per kg'
                : ''}
            </span>
          </div>
          <ul className='package-list'>
            {data.tags.map((tag: any, index: number) => (
              <li
                className={`${
                  data.title === 'Shop and Ship' || data.title === 'Budget'
                    ? 'isBudget'
                    : ''
                }`}
                key={index}
              >
                <img
                  src={
                    isFirstWordNoOrNot(tag)
                      ? CancelCheck
                      : data.title === 'Shop and Ship'
                      ? LightListCheck
                      : ListCheck
                  }
                  alt='check'
                />

                <p className='mb-0'>{tag}</p>
              </li>
            ))}
          </ul>
          {data.additionalNote ? (
            <div>
              <div
                className='mt-3'
                style={{
                  borderTop: '1px solid rgba(214, 226, 255, 0.3)',
                  display: 'flex',
                  alignItems: 'flex-start',
                  paddingTop: '20px'
                }}
              >
                <img
                  style={{
                    paddingTop: '2px'
                  }}
                  src={InfoIcon}
                  alt=''
                />
                <p
                  className=''
                  style={{
                    color: '#D6E2FF',
                    fontSize: '14px',
                    lineHeight: '26px',
                    fontWeight: 500,
                    paddingLeft: '10px'
                  }}
                >
                  {data.additionalNote}
                </p>
              </div>
              <div
                style={{
                  borderTop: '1px solid rgba(214, 226, 255, 0.3)',
                  marginTop: '20px'
                }}
              ></div>
              {data.title === 'Budget' ? (
                <div>
                  <div
                    onClick={() => setHowItWorks(true)}
                    className={`how-it-works `}
                  >
                    <p>See how budget works</p>
                    {howItWorks && (
                      <div className='absolute-position'>
                        <div className='how-guide'>
                          <img
                            onClick={e => {
                              e.stopPropagation();
                              setHowItWorks(false);
                            }}
                            style={{
                              position: 'absolute',
                              top: 20,
                              right: 20,
                              cursor: 'pointer'
                            }}
                            src={CancelIcon}
                            alt='cancel'
                          />
                          <h3
                            style={{
                              color: '#545859',
                              fontSize: '18px',
                              lineHeight: '22px',
                              fontWeight: 600,
                              textAlign: 'left'
                            }}
                          >
                            How budget works
                          </h3>
                          <p
                            className=''
                            style={{
                              color: '#545859',
                              fontSize: '14px',
                              lineHeight: '22px',
                              fontWeight: 400,
                              textAlign: 'left'
                            }}
                          >
                            Send More, Spend Less!
                          </p>
                          <div
                            style={{
                              position: 'relative'
                            }}
                          >
                            <p
                              style={{
                                color: '#545859',
                                fontSize: '14px',
                                lineHeight: '22px',
                                fontWeight: 500,
                                textAlign: 'left'
                              }}
                            >
                              Select country:
                            </p>
                            <div
                              style={{
                                position: 'relative'
                              }}
                            >
                              <div
                                onClick={() =>
                                  setOptions(options => ({
                                    ...options,
                                    toggleDropdown: !options.toggleDropdown
                                  }))
                                }
                                className='budget-country-lists'
                              >
                                <span
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 500
                                  }}
                                >
                                  {options.country}{' '}
                                </span>
                                <img
                                  className='faq-item-accordion-button'
                                  src={DropdownIcon}
                                  style={{
                                    width: '20px',
                                    transform: options.toggleDropdown
                                      ? 'rotate(180deg)'
                                      : 'rotate(0deg)',
                                    transition: 'transform 0.3s ease-in-out',
                                    cursor: 'pointer'
                                  }}
                                  alt='accordion toggle button'
                                />
                              </div>

                              {options.toggleDropdown && (
                                <div
                                  style={{
                                    marginTop: '20px'
                                  }}
                                  className='country-popup'
                                >
                                  {budgetRatePerCountries.map((c, i) => {
                                    return (
                                      <div
                                        style={{
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'flex-start',
                                          cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                          setOptions(prev => ({
                                            ...prev,
                                            country: c.country,
                                            toggleDropdown: false
                                          }));
                                        }}
                                        key={i}
                                      >
                                        <span>{c.country} </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            {options.country.includes('Rest of Africa') ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  border: '1px solid rgb(226, 228, 232)',
                                  padding: '30px 10px',
                                  borderRadius: '6px'
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: '30px'
                                  }}
                                >
                                  🌍
                                </p>
                                <p
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 500
                                  }}
                                >
                                  Coming soon!
                                </p>
                              </div>
                            ) : (
                              <div className='rate-wrap'>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start'
                                  }}
                                >
                                  <p className='shipping-text'>
                                    Non-flat rates
                                  </p>

                                  <div
                                    className='mb-2'
                                    style={{
                                      color: '#22428F',
                                      fontSize: '14px',
                                      fontWeight: 500
                                    }}
                                  >
                                    💰 {budgetRatePerCountry?.nonFlats}
                                  </div>
                                </div>
                                <span
                                  style={{
                                    borderRight: '1px solid #E2E4E8'
                                  }}
                                ></span>

                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start'
                                  }}
                                >
                                  <p className='shipping-text'>Flat rates</p>

                                  {budgetRatePerCountry.flats.map(rate => {
                                    return (
                                      <div
                                        className='mb-2'
                                        style={{
                                          color: '#22428F',
                                          fontSize: '14px',
                                          fontWeight: 500
                                        }}
                                      >
                                        💰 {rate}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>

                          <i
                            style={{
                              fontSize: '12px',
                              display: 'block',
                              textAlign: 'left',
                              paddingTop: '15px',
                              fontWeight: 500
                            }}
                          >
                            Includes customs clearance
                          </i>
                        </div>
                        <img className='poly-icon' src={PolygonIcon} alt='' />
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>

        <a
          href={'https://ship.topship.africa/login'}
          target={'_blank'}
          rel='noreferrer'
          className={`ship-now ${
            data.title === 'Shop and Ship' || data.title === 'Budget'
              ? 'isBudget'
              : ''
          }`}
        >
          Ship Now
        </a>
      </div>
    </div>
  );
};

const ImportsPage = () => {
  return (
    <>
      <Helmet>
        <title>Imports Shipping from Nigeria with Topship Africa</title>
        <meta
          name='description'
          content='Ship internationally to Nigeria with Topship. Deliver to your doorstep from over 150 cities in the world. Find the best international shipping rates and express delivery services with Topship.'
        />
        <meta
          name='keywords'
          content='Delivery companies, worldwide shipping, import shipping, international delivery companies, express delivery company, import duty, import trade, import export business, international shipping, express shipping'
        />
      </Helmet>
      <div className='exports'>
        <section className='exports__flexible-options'>
          <div
            style={{
              width: '100%'
            }}
            className='intro-wrap'
          >
            <h2 className='heading'>
              Bring the <span className='text-with-img'>world</span> to your
              doorstep
            </h2>
            <p>
              Receive packages from over 200+ cities to Nigeria with Topship.
            </p>
            <img src={CountryFlags} alt='country flags' />
            <div className='cta-group mt-5'>
              <a href='#contact-form' className='contact-support'>
                Contact Us
              </a>
            </div>
          </div>
        </section>
        <section className='exports__package-options'>
          <p
            className='title mb-3'
            style={{
              textAlign: 'center',
              width: 'auto'
            }}
          >
            Pricing Options
          </p>

          <div className='web-verion'>
            <div className='card-container'>
              {packagesArray.map(item => {
                return <CardComponent data={item} />;
              })}
            </div>
          </div>
        </section>
        <section className='exports__shipping-option '>
          <h2
            className='title'
            style={{
              textAlign: 'left'
            }}
          >
            Enjoy all of these and more on each shipping options
          </h2>
          <div className='benefit-wrap'>
            {deliveryBenefitsArray.map(benefit => {
              return (
                <div className='benefit-item'>
                  <img src={benefit.icon} alt='' />
                  <p>{benefit.content}</p>
                </div>
              );
            })}
          </div>
        </section>

        <TermsAndConditions />
        <Testimonials />
        <ContactForm serviceTitle={'Import'} />
        <FaqBlock questions={importsquestions} />
        <GetMobileApp />
        <section className='new-home-page__footer'>
          <NewFooter />
        </section>
      </div>
    </>
  );
};

export default ImportsPage;
