import { allCountriesJSONData } from './countries';

const someAllowedCountries = allCountriesJSONData.map(country =>
  country.code === 'NG' ? `/ng` : `/ng/${country.code.toLowerCase()}`
);

const allowedCountries = [...someAllowedCountries, '/ng/uk'];

const allowedCities = [
  '/abia/aba',
  '/abia/umuahia',
  '/adamawa',
  '/adamawa/yola',
  '/adamawa/mubi',
  '/adamawa/jimeta',
  '/akwaibom',
  '/akwaibom/uyo',
  '/akwaibom/ikot-ekpenne',
  '/anambra',
  '/anambra/awka',
  '/anambra/onitsha',
  '/anambra/okpoko',
  '/bauchi',
  '/benue',
  '/benue/makurdi',
  '/benue/gboko',
  '/benue/otukpo',
  '/borno',
  '/borno/maiduguri',
  '/borno/bama',
  '/borno/kukawa',
  '/crossriver',
  '/crossriver/calabar',
  '/crossriver/ugep',
  '/delta',
  '/delta/asaba',
  '/delta/sapele',
  '/delta/warri',
  '/ebonyi',
  '/ebonyi/abakaliki',
  '/edo',
  '/edo/benin',
  '/ekiti',
  '/ekiti/ado-ekiti',
  '/enugu',
  '/enugu/nsukka',
  '/abuja',
  '/gombe',
  '/imo',
  '/imo/owerri',
  '/jigawa',
  '/katsina',
  '/kaduna',
  '/kaduna/zaria',
  '/kano',
  '/kogi',
  '/kogi/okene',
  '/kogi/lokoja',
  '/kwara',
  '/kwara/ilorin',
  '/nasarawa',
  '/niger',
  '/niger/minna',
  '/niger/suleja',
  '/niger/bida',
  '/ogun',
  '/ogun/sagamu',
  '/ogun/abeokuta',
  '/ogun/ijebu',
  '/ondo',
  '/ondo/akure',
  '/ondo/owo',
  '/osun',
  '/osun/ife',
  '/osun/osogbo',
  '/oyo',
  '/oyo/ibadan',
  '/oyo/ogbomoso',
  '/oyo/saki',
  '/oyo/iseyin',
  '/plateau',
  '/plateau/jos',
  '/rivers',
  '/rivers/port-harcourt',
  '/sokoto',
  '/taraba',
  '/yobe',
  '/zamfara',
  '/lagos',
  '/abuja'
];

export const international = word =>
  word.toLowerCase() === 'us' || word.toLowerCase() === 'uk' || false;

export const domestic = word =>
  word.toLowerCase() === 'lagos' ||
  word.toLowerCase() === 'ibadan' ||
  word.toLowerCase() === 'abuja' ||
  false;

const allowURL = (pathname = '') => {
  const lastWord = pathname.split('/')[pathname.split('/').length - 1];
  const parsedPathname = pathname
    .trim()
    .slice(
      0,
      pathname.split('')[pathname.length - 1] === '/'
        ? pathname.length - 1
        : pathname.length
    );
  const isInternational =
    international(pathname.split('/')[1]) && lastWord === 'ng';
  const isLocal = pathname.split('/')[1] === 'local-delivery';
  const isAcceptedLocal = isLocal && domestic(lastWord);

  const isNotRepeated =
    parsedPathname !== '/lagos/lagos' && parsedPathname !== '/abuja/abuja';
  const isOneKeyword = pathname.split('/').length === 2;
  const isDomestic = domestic(pathname.split('/')[1]);
  const cityPath = pathname
    .split('/')
    .filter((_, i) => i !== 1)
    .join('/');
  const isAllowedCountry = allowedCountries.find(
    country => country.trim() === parsedPathname
  );

  const isAllowedCity = allowedCities.find(
    city =>
      city.toLowerCase().trim() ===
      cityPath
        .toLowerCase()
        .slice(
          0,
          cityPath.split('')[cityPath.length - 1] === '/'
            ? cityPath.length - 1
            : cityPath.length
        )
        .trim()
  );

  return (
    pathname === '/' ||
    (isOneKeyword && isDomestic) ||
    Boolean(isAllowedCountry) ||
    Boolean(isNotRepeated && isDomestic && isAllowedCity) ||
    isAcceptedLocal ||
    isInternational ||
    (isOneKeyword && isLocal) ||
    false
  );
};

export default allowURL;
