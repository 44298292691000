import React, { useState } from 'react';
import FaqBlock from '../components/faq-block';
import NewFooter from '../components/new-footer';
import GetMobileApp from '../components/new-home-page/get-mobile-app';
import {
  seaFreightExportList,
  seaFreightImportList
} from '../constants/exports-lists';
import { seaFreightQuestions } from '../constants/faqs';
import { ContactForm } from '../components/contact-form';
import { Helmet } from 'react-helmet';
import { AllFlags, UsFlag } from '../constants/asset-constants';

const CardComponent = ({ data, isImport = false }: any) => {
  return (
    <div className={`package-card`}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%'
        }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '20px',
              alignItems: 'center'
            }}
          >
            <p className={`title mb-0 `}>
              {isImport
                ? 'Import Via Sea'
                : `Export Via Sea to the ${data.country}`}
            </p>
          </div>

          <ul className='package-list'>
            {data.items.map((tag: any, index: number) => (
              <li
                className={`${
                  data.title === 'Shop and Ship' || data.title === 'Budget'
                    ? 'isBudget'
                    : ''
                }`}
                key={index}
              >
                <img
                  src={tag.icon}
                  style={{
                    height: '20px',
                    width: '20px'
                  }}
                  alt='check'
                />

                <p className='mb-0'>{tag.item}</p>
              </li>
            ))}
          </ul>
        </div>

        <a
          href={'https://ship.topship.africa/login'}
          target={'_blank'}
          rel='noreferrer'
          className={`ship-now `}
        >
          {isImport ? 'Request a quote' : 'Ship Now'}
        </a>
      </div>
    </div>
  );
};

const SeaFreightPage = () => {
  const [activeTab, setActiveTab] = useState('export');

  return (
    <>
      <Helmet>
        <title>Sea Freight Shipping from Nigeria with Topship Africa</title>
        <meta
          name='description'
          content='Sea Freight from Nigeria to over 150 cities worldwide with Topship. Ship abroad to family, friends, and customers. Enjoy the best shipping rates and fast delivery with Topship.'
        />
        <meta
          name='keywords'
          //   content='Topship, Export shipping services, export shipping, international shipping, best shipping rates, most affordable shipping, shipping cargo, express shipping, fast delivery, international delivery services'
        />
      </Helmet>
      <div className='sea-freight'>
        <section className='sea-freight__header-intro'>
          <div className='intro-wrap'>
            <h2 className='heading'>SEA FREIGHT</h2>
            <p className='content'>
              Import and export larger volume items with our Sea Freight
              service. For business or personal use.
            </p>
          </div>
        </section>
        <section className='sea-freight__package-options'>
          <div className='package-switch'>
            <div
              onClick={() => setActiveTab('export')}
              className={`package-switch-item ${
                activeTab === 'export' ? ' isActive' : ''
              }`}
            >
              Export Via Sea
            </div>
            <div
              onClick={() => setActiveTab('import')}
              className={`package-switch-item ${
                activeTab === 'import' ? ' isActive' : ''
              }`}
            >
              Import Via Sea
            </div>
          </div>

          {activeTab === 'export' && (
            <div>
              <div className='sub-heading'>
                <p>Export to the UK & USA</p>
                <img src={UsFlag} alt='flag' />
              </div>
              <div className='card-container'>
                {seaFreightExportList.map(item => {
                  return <CardComponent data={item} />;
                })}
              </div>
            </div>
          )}
          {activeTab === 'import' && (
            <div>
              <div className='sub-heading'>
                <p>Import from the UK, USA, Canada, China, India and Ghana</p>
                <img src={AllFlags} alt='flag' />
              </div>
              <div className='card-container'>
                {seaFreightImportList.map(item => {
                  return <CardComponent data={item} isImport />;
                })}
              </div>
            </div>
          )}
        </section>

        <ContactForm serviceTitle={'Sea Fright'} />
        <FaqBlock questions={seaFreightQuestions} />
        <GetMobileApp />
        <section className='new-home-page__footer'>
          <NewFooter />
        </section>
      </div>
    </>
  );
};

export default SeaFreightPage;
