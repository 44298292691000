import NewFooter from '../components/new-footer';
import GetMobileApp from '../components/new-home-page/get-mobile-app';
import { file } from '../constants/asset-constants';

const shippingDocumentation = [
  {
    title: 'FDA FORM',
    content: (
      <div>
        <p>This is required for food products destined for the USA</p>
        <p>
          The United States Food and Drug Administration (FDA or US FDA) is a
          federal agency of the Department of Health and Human Services.{' '}
        </p>
        <p>
          The FDA is responsible for protecting and promoting public health
          through the control and supervision of food safety, tobacco products,
          caffeine products, dietary supplements, prescription and
          over-the-counter pharmaceutical drugs(meditations), vaccines ,
          biopharmaceuticals, blood transfusions, medical devices,
          electromagnetic radiation emitting devices (ERED), cosmetics, animal
          foods & feed and veterinary product
        </p>
        <b />
        <p>
          Packages that contain any of the examples above must have a completed
          FDA form attached to the package before shipping, especially when
          shipping with the Topship Saver service
        </p>
        <a
          className='mt-3'
          style={{
            color: '#22428f',
            display: 'flex',
            justifyContent: 'flex-start',
            fontWeight: 500,
            fontSize: '16px'
          }}
          target='_blank'
          rel='noreferrer'
          href='https://www.access.fda.gov/oaa/logonFlow.htm;jsessionid=__JruHdx8imPMGzLhO9eTf3aAYlKNI9LEcZNgR7VxjHZcUJeyogM!-2014682382?execution=e1s1'
        >
          Get FDA Form
        </a>
      </div>
    )
  },
  {
    title: 'PHYTOSANITARY / QUARANTINE CERTIFICATE',
    content: (
      <div>
        <p>
          Quarantine or phytosanitary certificates are issued to indicate that
          consignments f plants, plant products, herbs or other regulated
          articles meet specified
        </p>
        <a
          className='mt-3'
          style={{
            color: '#22428f',
            display: 'flex',
            justifyContent: 'flex-start',
            fontWeight: 500,
            fontSize: '16px'
          }}
          target='_blank'
          rel='noreferrer'
          href='https://naqsportal.net/New-user-registration'
        >
          Get Phytosanitary / Quarantine Certificate
        </a>
      </div>
    )
  },
  {
    title: 'MUSEUM CERTIFICATE',
    content: (
      <div>
        <p>
          This is to be provided when shipping original artworks or art prints.
          A fixed cost of 10% of the value of the artwork.
        </p>
        <p
          style={{
            fontSize: '12px'
          }}
        >
          Need help in securing a museum certificate?
        </p>
        <div className='cta-item mt-2'>
          <a
            style={{
              textDecoration: 'none'
            }}
            href='mailto: hello@topship.africa'
          >
            <div
              style={{
                width: 'fit-content',
                padding: '10px 20px'
              }}
              className='new-home-page__link-button bttn-sm bttn-blue'
            >
              Contact Support
            </div>
          </a>
        </div>
      </div>
    )
  },
  {
    title: 'FOOTWEAR FORM',
    content: (
      <div>
        <p>
          This is to be completed when shipping packages that contain footwear
          via Topship Saver Service. The form is not required for other Topship
          services.
        </p>
        <a
          className='mt-3'
          style={{
            color: '#22428f',
            display: 'flex',
            justifyContent: 'flex-start',
            fontWeight: 500,
            fontSize: '16px'
          }}
          target='_blank'
          rel='noreferrer'
          href='https://docs.google.com/document/d/1zFGxckKTq6Bhp0WZQMr8NZokCkB5vllR/edit?usp=sharing&ouid=101415785163992144337&rtpof=true&sd=true'
        >
          Download Form for Free
        </a>
      </div>
    )
  },
  {
    title: 'ADDENDUM FORM',
    content: (
      <div>
        <p>
          This is to be completed when shipping packages that contain Clothing
          and textile goods to the USA, and Australia via Topship’s Saver
          service. The form is not required for other Topship services.
        </p>
        <a
          className='mt-3'
          style={{
            color: '#22428f',
            display: 'flex',
            justifyContent: 'flex-start',
            fontWeight: 500,
            fontSize: '16px'
          }}
          target='_blank'
          rel='noreferrer'
          href='https://drive.google.com/file/d/1c2NIE2jBQBYKPo5dEHQeXtHPorPuOZKh/view?usp=drive_link'
        >
          Download Form for Free
        </a>
      </div>
    )
  },
  {
    title: 'LITHIUM DECLARATION FORM ',
    content: (
      <div>
        <p>
          This is to be completed when shipping packages that contain lithium.
        </p>
        <a
          className='mt-3'
          style={{
            color: '#22428f',
            display: 'flex',
            justifyContent: 'flex-start',
            fontWeight: 500,
            fontSize: '16px'
          }}
          target='_blank'
          rel='noreferrer'
          href='https://drive.google.com/file/d/1ag_lQoJ76mCeMGYqBLI99cnmwR33FPSu/view?usp=drive_link'
        >
          Download Form for Free
        </a>
      </div>
    )
  },
  {
    title: 'SNAIL CLASSIFICATION FORM',
    content: (
      <div>
        <p>
          This is to be completed when shipping packages that contain snails
          (live, dead, or dried).
        </p>
        <a
          className='mt-3'
          style={{
            color: '#22428f',
            display: 'flex',
            justifyContent: 'flex-start',
            fontWeight: 500,
            fontSize: '16px'
          }}
          target='_blank'
          rel='noreferrer'
          href='https://docs.google.com/document/d/12uhloC1ST92UN4tPbUCxk8YXhJOLytnF/edit?usp=drive_link&ouid=101415785163992144337&rtpof=true&sd=true'
        >
          Download Form for Free
        </a>
      </div>
    )
  },
  {
    title: 'DROP BALL CERTIFICATE',
    content: (
      <div>
        <p>
          The Food and Drug Administration (FDA) requires shipments of
          nonprescription sunglasses include a “Drop Ball Test” certificate.
          This certificate confirms the safety and durability of the lens for
          impact resistance.
        </p>
        <p>
          This is required only when shipping via the Topship Saver service.
          This form is not required for other Topship services.
        </p>
        <a
          className='mt-3'
          style={{
            color: '#22428f',
            display: 'flex',
            justifyContent: 'flex-start',
            fontWeight: 500,
            fontSize: '16px'
          }}
          target='_blank'
          rel='noreferrer'
          href='https://www.fedex.com/content/dam/fedex/apac-asia-pacific/downloads/fedex-drop-ball-test-cert-tw.pdf'
        >
          Get Certificate
        </a>
      </div>
    )
  }
];

const ShippingDocumentationPage = () => {
  return (
    <>
      <div className='shipping-checklist'>
        <section className='shipping-checklist__documentation'>
          <div className='intro-wrap'>
            <h2 className='heading'>SHIPPING DOCUMENTATION</h2>
            <div className='prohibited-group'></div>

            {/* <div className='list-group'> */}
            {shippingDocumentation.map((list, index) => {
              return (
                <div
                  key={index}
                  className={`${index === 1 ? 'mb-10' : 'mb-4'}  list-group`}
                >
                  <img
                    className='mb-1'
                    style={{
                      width: '40px'
                    }}
                    src={file}
                    alt='file icon'
                  />
                  <h3>{list.title}</h3>
                  <div>{list.content}</div>
                </div>
              );
            })}
          </div>
        </section>

        <GetMobileApp />
        <section className='new-home-page__footer'>
          <NewFooter />
        </section>
      </div>
    </>
  );
};

export default ShippingDocumentationPage;
