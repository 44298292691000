import { FC } from "react";

interface Props {
  emailLabel?: string;
  firstNameLabel?: string;
}

export const MailChimpForm: FC<Props> = ({ emailLabel, firstNameLabel }) => {
  return (
    <div id="mc_embed_signup">
      <form
        action="https://africa.us1.list-manage.com/subscribe/post?u=4d24713387004f555404238a7&id=8858a0d740"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        noValidate
      >
        <div id="mc_embed_signup_scroll">
          <div className="form-controls">
            <div className="mc-field-group">
              <label htmlFor="mce-EMAIL">
                {emailLabel} <span className="asterisk">*</span>
              </label>
              <input
                type="email"
                // defaultValue
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
              />
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-FNAME">
                {firstNameLabel} <span className="asterisk">*</span>
              </label>
              <input
                type="text"
                // defaultValue
                name="FNAME"
                className="required"
                id="mce-FNAME"
              />
            </div>
            <div className="clear">
              <button
                type="submit"
                // defaultValue="Subscribe"
                // value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const MailChimp = () => {
  return (
    <div className="mailchimp">
      <h3>Subscribe to our mailing list!</h3>
      <div className="indicates-required">
        <span className="asterisk">*</span> indicates required
      </div>

      <MailChimpForm emailLabel="Email Address" firstNameLabel="First name" />
      <div id="mce-responses" className="clear">
        <div
          className="response"
          id="mce-error-response"
          style={{ display: "none" }}
        />
        <div
          className="response"
          id="mce-success-response"
          style={{ display: "none" }}
        />
      </div>
      {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
      <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
        <input
          type="text"
          name="b_4d24713387004f555404238a7_8858a0d740"
          tabIndex={-1}
          // defaultValue
        />
      </div>
    </div>
  );
};

export default MailChimp;
