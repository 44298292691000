import { FC } from "react";

interface Props {
  title?: string;
  description?: string;
  date?: string;
  author?: string;
  image?: string;
  slug?: string;
  post: {};
}

const BlogItem: FC<Props> = ({ title, description, date, author, image }) => {
  return (
    <article className="blog-item">
      <div className="blog-item__text">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className="blog-item__image">
        <figure>
          <img src={image} alt="blog item" />
        </figure>
      </div>
    </article>
  );
};

export default BlogItem;
