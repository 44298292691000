import React from 'react';
import { Link } from 'react-router-dom';
import Bye from './../assets/svgs/bye.svg';

const DeleteAccount = ({
  heading = `We'll miss you!`,
  children
}: any) => (
  <div className='delete-account'>
    <div>
      <figure className='text-center'>
        <img src={Bye} alt='not found' className='bye-image' />
      </figure>

      <h1>{heading}</h1>

      {!children && (
        <div className='description'>
          <p>Okay, here's what to expect...</p>

          <div className='description-list'>
            <ul>
              <li className='description-list-item'>Your Topship account will be put on hold <strong>immediately</strong> </li>

              <li className='description-list-item'>Your wallet balance will be sent to your bank account</li>

              <li className='description-list-item'>You'll no longer be able to login to or access your dashboard</li>

              <li className='description-list-item'>We'll email you the link to download your Topship data. The link will expire in one week</li>
              
              <li className='description-list-item'>We'll keep your Topship data for 6 months, free of charge. You can reactivate your account at any time during that window by requesting for an account revamp.</li>
              
              <li className='description-list-item'>If you don't reactivate, we'll securely delete your Topship data after 6 months. There are no recovery options</li>
            </ul>
          </div>

          <p className='thanks'>Thanks for using Topship!</p>

          <div className="actions">
            <div className='button'>
              <a href='https://ship.topship.africa'>
                <button className='red'>Delete my account</button>
              </a>
            </div>

            <div className='button'>
              <Link to='/'>
                <button className='cancel'>Cancel</button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default DeleteAccount;
