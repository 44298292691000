import React from 'react';
import FaqBlock from '../components/faq-block';
import NewFooter from '../components/new-footer';
import GetMobileApp from '../components/new-home-page/get-mobile-app';
import {
  featureIconFour,
  ShopImg,
  ShopNShipMockup,
  ShopNShipTopImg,
  StoreImg,
  VanImg
} from '../constants/asset-constants';
import { shopNShipquestions } from '../constants/faqs';
import { Helmet } from 'react-helmet';

export default function ShopNShipPage() {
  return (
    <>
      <Helmet>
        <title>Shop from international stores and deliver to Nigeria</title>
        <meta
          name='description'
          content='Shop from international stores abroad and pay zero customs fees with Topship. Enjoy hassle-free online shopping and shipping from stores in the USA, UK, and more to Nigeria.'
        />
        <meta
          name='keywords'
          content='Online shopping, international online stores, amazon to Nigeria, shop US stores, shop UK stores, shop and ship, ship from the USA to Nigeria, ship from the UK to Nigeria, zero customs fees'
        />
      </Helmet>
      <div className='shopnship about lsp'>
        <section className='shopnship__intro'>
          <div className='intro-content'>
            <h1 className='header'>
              Shop international stores and pay ZERO customs fees when you use
              the{' '}
              <span
                style={{
                  color: '#FFD708'
                }}
              >
                Topship Shop & Ship
              </span>{' '}
              Service.
            </h1>
            <p>
              Doorstep Delivery across Nigeria. <br />
              Fixed, predictable rates.
            </p>
            <a
              rel='noreferrer'
              target='_blank'
              href='https://ship.topship.africa/signup'
              className='access-button'
            >
              Start Here
            </a>
          </div>

          <div className='image-container'>
            <img src={ShopNShipTopImg} alt='developer-img' />
          </div>
        </section>

        <section className='shopnship__features'>
          <div className='shopnship__features__container'>
            <div className='shopnship__features__header'>
              <div className='shopnship__features__question'>
                <p>SHOP & SHIP</p>
              </div>
              <div className=''>
                <h1 className='header'>How does this work?</h1>
              </div>
            </div>
            <div className='shopnship__features__wrap'>
              <div className='shopnship__feature'>
                <div className='shopnship__feature__wrap'>
                  <div className='shopnship__feature__icon'>
                    <img src={ShopImg} className='img-fluid' alt='' />
                  </div>
                  <div className='shopnship__feature__header'>
                    <h4>Step 01</h4>
                  </div>
                  <div className='shopnship__feature__content'>
                    <p>You go to an international online store, shop, & pay</p>
                  </div>
                </div>
              </div>
              <div className='shopnship__feature'>
                <div className='shopnship__feature__wrap'>
                  <div className='shopnship__feature__icon'>
                    <img src={StoreImg} className='img-fluid' alt='' />
                  </div>
                  <div className='shopnship__feature__header'>
                    <h4>Step 02</h4>
                  </div>
                  <div className='shopnship__feature__content'>
                    <p>
                      You have it delivered to our UK or US Drop-off center
                      (using the appropriate identifier tag)
                    </p>
                  </div>
                </div>
              </div>
              <div className='shopnship__feature'>
                <div className='shopnship__feature__wrap'>
                  <div className='shopnship__feature__icon'>
                    <img src={featureIconFour} className='img-fluid' alt='' />
                  </div>
                  <div className='shopnship__feature__header'>
                    <h4>Step 03</h4>
                  </div>
                  <div className='shopnship__feature__content'>
                    <p>
                      You go to your Topship dashboard, select Shop & Ship,
                      complete the order form & pay
                    </p>
                  </div>
                </div>
              </div>
              <div className='shopnship__feature'>
                <div className='shopnship__feature__wrap'>
                  <div className='shopnship__feature__icon'>
                    <img src={VanImg} className='img-fluid' alt='' />
                  </div>
                  <div className='shopnship__feature__header'>
                    <h4>Step 04</h4>
                  </div>
                  <div className='shopnship__feature__content'>
                    <p>
                      We will deliver your package in 7 - 14 business days. A
                      pick-up option is available. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='about__howItWorks'>
          <div className='about__howItWorks__container'>
            <div className='about__howItWorks__description'>
              <div className='about__howItWorks__header'>
                <h3
                  style={{
                    color: '#FFD708',
                    whiteSpace: 'nowrap'
                  }}
                >
                  What's in the fine print?
                </h3>
              </div>
              <div className='about__howItWorks__content'>
                <h2
                  style={{
                    whiteSpace: 'nowrap'
                  }}
                >
                  Important <br className='break-line' /> things to note
                </h2>
              </div>
            </div>
            <div className='about__howItWorks__items'>
              <div className='about__howItWorks__items__wrap'>
                <div className='about__howItWorks__item'>
                  <div className='about__howItWorks__item__wrap'>
                    <div className='about__howItWorks__item__header'>
                      <h4
                        style={{
                          color: '#FFD708'
                        }}
                      >
                        01
                      </h4>
                    </div>
                    <div className='about__howItWorks__item__content'>
                      <p>
                        Packages are shipped out every Friday. This means the 7
                        – 14 day delivery window begins from the Friday of the
                        week when your package arrives at our UK, USA or Canada
                        Drop-off centers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='about__howItWorks__item'>
                  <div className='about__howItWorks__item__wrap'>
                    <div className='about__howItWorks__item__header'>
                      <h4
                        style={{
                          color: '#FFD708'
                        }}
                      >
                        02
                      </h4>
                    </div>
                    <div className='about__howItWorks__item__content'>
                      <p>
                        Occasionally, package weight may be less or more than
                        the weight you disclosed upon booking. When this
                        happens, we will contact you (via email and phone) about
                        the discrepancy and request the balance payment or
                        refund your wallet with the excess charge. 
                      </p>
                    </div>
                  </div>
                </div>
                <div className='about__howItWorks__item'>
                  <div className='about__howItWorks__item__wrap'>
                    <div className='about__howItWorks__item__header'>
                      <h4
                        style={{
                          color: '#FFD708'
                        }}
                      >
                        03
                      </h4>
                    </div>
                    <div className='about__howItWorks__item__content'>
                      <p>
                        Packages that do not have the accurate identifier tag
                        won’t be processed and shipped to Nigeria. Remember to
                        append UAC-mujeq to your name if your package is to be
                        delivered to our USA Drop-off center, UAC-ytroe for our
                        UK Drop-off center and UAC-rkipz for our Canada Drop-off
                        center.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='about__howItWorks__item'>
                  <div className='about__howItWorks__item__wrap'>
                    <div className='about__howItWorks__item__header'>
                      <h4
                        style={{
                          color: '#FFD708'
                        }}
                      >
                        04
                      </h4>
                    </div>
                    <div className='about__howItWorks__item__content'>
                      <p>
                        Shipping costs $20 or £20 per kg (inclusive of customs
                        clearance charges and doorstep delivery in Lagos)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='lsp__intro'>
          <div
            style={{
              marginTop: '5%'
            }}
            className='intro-content'
          >
            <div className='shopnship__features__question'>
              <p>SHOP & SHIP</p>
            </div>
            <h1 className='header'>
              Shop from any store worldwide and receive your package in 7 - 14
              working days!
            </h1>
          </div>

          <div className='image-container'>
            <img src={ShopNShipMockup} alt='developer-img' />
          </div>
        </section>
        <FaqBlock questions={shopNShipquestions} />
      </div>
      <GetMobileApp />

      <section className='new-home-page__footer'>
        <NewFooter />
      </section>
    </>
  );
}
