// import getLink from "./link";
// type faqType = {
//   question: string;
//   answer: string;
// }[];

import {
  FaqContactIcon,
  FaqCostIcon,
  FaqFoodstuffIcon,
  FaqWeightsIcon
} from './asset-constants';

const faqs = [
  {
    question: 'How much should I budget for Customs Clearance?',
    answer: (
      <div>
        <p>
          Customs clearance can be unpredictable as they are at the discretion
          of customs officials and import regulations in the recipient’s
          country. However, the charge depends on the declared value of your
          package and is typically 30% - 50% of the declared value.
        </p>
        <p>
          Please note that declaring a lower value than your item is worth could
          attract an additional fine from customs officials.
        </p>
        <p>
          To waive this charge, consider shipping with Topship’s Budget service
          (export) or Shop and Ship service (import).
        </p>
      </div>
    ),
    expand: false
  },

  {
    question: 'What food stuff can I ship?',
    answer: (
      <div>
        <p>
          You can ship grains, beans, spices, dry pepper, ogbono, egusi
          (grounded), Crayfish (Blended and Dried), prawns, dry fish, and oils
          (vegetable, palm, coconut, castor, etc.)
        </p>
        <p>
          If shipping dried fish, please note that you can only ship a maximum
          of 10kg per waybill
        </p>
        <p>
          If shipping goat meat, please note that you can only ship a maximum of
          2kg per waybill, and it can only be shipped with our Budget service.
        </p>
        <p>
          At the moment, you can ship foodstuff only to Canada, the UK, and the
          US guaranteed. If you're looking to ship to an unlisted country,
          please contact support to inquire about import laws. Email us at
          hello@topship.africa If shipping snails, please note that a snail
          classification form is required, and you can only ship a maximum of
          2kg per waybill. Also, snails can only be shipped with our Express or
          Budget service.
        </p>
      </div>
    ),
    expand: false
  },
  {
    question: 'What foodstuff is prohibited?',
    answer:
      'Prohibited foodstuffs include cow skin (ponmo), beef, fresh fish, and pre-cooked meals due to strict customs and import laws.',
    expand: false
  },
  {
    question: 'Where can i ship foodstuff to?',
    answer: (
      <div>
        <p>
          At the moment, you can ship foodstuff only to Canada, the UK, and the
          US guaranteed. If you're looking to ship to an unlisted country,
          please contact support to inquire about import laws. Email us at
          hello@topship.africa
        </p>
      </div>
    ),
    expand: false
  },

  {
    question: 'How much should I budget for Customs Clearance?',
    answer: (
      <div>
        <p>
          Customs clearance can be unpredictable as they are at the discretion
          of customs officials and import regulations in the recipient’s
          country. However, the charge depends on the declared value of your
          package and is typically 30% - 50% of the declared value.
        </p>{' '}
        <p>
          Please note that declaring a lower value than your item is worth could
          attract an additional fine from customs officials.
        </p>
        <p>
          To waive this charge, consider shipping with Topship’s{' '}
          <a
            target={'_blank'}
            rel='noreferrer'
            href='https://topship.africa/exports'
          >
            Budget service
          </a>{' '}
          (export) or{' '}
          <a
            target={'_blank'}
            rel='noreferrer'
            href='https://topship.africa/shop-&-ship'
          >
            Shop and Ship
          </a>{' '}
          service (import).
        </p>
      </div>
    ),
    expand: false
  },
  {
    question:
      'How do I import or export without paying customs clearance fees?',
    answer: (
      <div>
        <p>
          Consider shipping with Topship’s{' '}
          <a
            target={'_blank'}
            rel='noreferrer'
            href='https://topship.africa/exports'
          >
            Budget service
          </a>{' '}
          (export) or{' '}
          <a
            target={'_blank'}
            rel='noreferrer'
            href='https://topship.africa/shop-&-ship'
          >
            Shop and Ship
          </a>{' '}
          service (import).{' '}
        </p>
      </div>
    ),
    expand: false
  }
];
// const calendlyLink =
//   'https://calendly.com/topship-marketing/introductory-call-with-topship-team?month=2023-08';

const gatewayFaqs = [
  {
    question: 'How do I get started?',
    answer: (
      <span>
        Go to{' '}
        <a href='https://ship.topship.africa/signup?isLsp=true'>
          GateWay sign up page{' '}
        </a>
        and create your account. Select ‘Logistics Company’ as your account
        type. You’re all set to book a shipment.
      </span>
    ),
    expand: false
  },
  {
    question: 'How do I get a discount?',
    answer: `Discounts are based on your expected number of monthly shipments. Contact our Sales team via gateway@topship.africa to learn your options.`,
    expand: false
  },
  {
    question: 'How can I use the web app?',
    answer: (
      <span>
        After you{' '}
        <a
          target={'_blank'}
          rel='noreferrer'
          href='https://ship.topship.africa/signup?isLsp=true'
        >
          create an account
        </a>{' '}
        for your Logistics Company. You can start shipping immediately. Don’t
        forget to complete your account verification to access more features
        like pricing control and customized waybills.
      </span>
    ),
    id: 'contact-sales',

    expand: false
  },
  {
    question: 'How do I contact sales?',
    answer: (
      <span>
        {' '}
        Need help getting started? Contact our Sales Team at 09163842013 or
        email gateway@topship.africa
      </span>
    ),
    expand: false
  }
];

const articleData = [
  {
    icon: FaqContactIcon,
    title: 'Getting started on Topship (video guides)',
    textContent: 'Create a FREE account on the Topship web app',
    articleNumber: 5
  },
  {
    icon: FaqWeightsIcon,
    title: 'Shipping Options & Considerations',
    textContent:
      'All you need to know about our services, delivery timelines, and weight measurements',
    articleNumber: 3
  },
  {
    icon: FaqCostIcon,
    title: 'Shipping Costs',
    textContent:
      'All you need to know about imports, export & nationwide deliveries',
    articleNumber: 3
  },
  {
    icon: FaqFoodstuffIcon,
    title: 'Shipping foodstuff abroad',
    textContent:
      'All you need to know about shipping foodstuff and perishable items',
    articleNumber: 3
  },
  {
    icon: FaqWeightsIcon,
    title:
      'Getting started as a Logistics Business on Topship (GateWay by Topship)',
    textContent: 'How to make the most of Gateway by Topship ',
    articleNumber: 3
  },
  {
    icon: FaqFoodstuffIcon,
    title: 'Customs Clearance Fee / Customs Duty',
    textContent: 'All you need to know about customs duty',
    articleNumber: 2
  },
  {
    icon: FaqFoodstuffIcon,
    title: 'Shop & Ship',
    textContent: 'All you need to know about the shop and ship feature',
    articleNumber: 6
  },
  {
    icon: FaqFoodstuffIcon,
    title: 'China Imports',
    textContent: 'All you need to know about china imports',
    articleNumber: 6
  },
  {
    icon: FaqFoodstuffIcon,
    title: 'Imports',
    textContent: 'All you need to know about our import service',
    articleNumber: 5
  },
  {
    icon: FaqFoodstuffIcon,
    title: 'Exports',
    textContent: 'All you need to know about our export service',
    articleNumber: 7
  }
];
const searchFaqs = query => {
  return articleData.filter(
    article =>
      article.title.toLowerCase().includes(query) ||
      article.textContent.toLowerCase().includes(query)
  );
};

const shopNShipquestions = [
  {
    expand: false,
    question: 'How do i get your UK/US/Canada address?',
    answer:
      'Our partner addresses can be found on the Topship app. Sign in, go to Shop & Ship, select the country you are shopping from and follow the instructions listed on the page.'
  },
  {
    expand: false,
    question: 'How long does delivery take?',
    answer:
      'Delivery takes 7-14 working days. Packages are shipped out on Fridays and the delivery timeline will begin the Friday after your package has been delivered to our Canada, UK or USA partner Drop-off centers.'
  },
  {
    expand: false,
    question: 'Why is my delivery taking longer than 7-14 days?',
    answer:
      'We ship once a week on Fridays and your delivery timeline will begin on the Friday after your package is delivered. Also, peculiar items like drones, pure gold or precious metals may require longer customs processing times.'
  },
  {
    expand: false,
    question: 'What are your rates?',
    answer:
      'Shipping costs $20 or £20 per kg (inclusive of customs clearance charges and doorstep delivery in Lagos)'
  },
  {
    expand: false,
    question: 'Do I have to pay any other fee?',
    answer:
      'No, you do not. Our prices are inclusive of customs duties and doorstep delivery to Lagos. However, if you live outside Lagos, you’ll have to either arrange for the item to be picked up or pay an extra fee to have it delivered to you.'
  },
  {
    expand: false,
    question: 'Why do you need my ID and Proof of purchase?',
    answer:
      'This is in line with our fraud protection policy. It helps us run the Shop & Shop service in line with law enforcement requirements. We only require you to upload your proof of identification (a government-issued ID) and clear evidence of payment (bank statement or a transaction receipt from your bank). If you used another person’s card for shopping and shipping, please provide the person’s ID and evidence of payment.'
  }
];

const exportsquestions = [
  {
    expand: false,
    question: 'How long does the shipping take?',
    answer: (
      <div>
        <p>Delivery timeline depends on the shipping service you select.</p>
        <p>
          Express takes 3 - 7 working days (depending on your destination
          address), Saver-Priority takes 5 - 7 working days, Saver takes 10 - 12
          working days, Budget takes 7 - 15 working days, and Sea Freight takes
          8 - 12 weeks.
        </p>
      </div>
    )
  },

  {
    expand: false,
    question: 'Is there a minimum weight?',
    answer: (
      <p>
        This depends on the shipping service you select. Sea Freight (
        <b>Ship Via Sea</b>) has a minimum weight limit of 100kg, while Budget,
        Saver, Saver-Priority, and Express have no minimum weight limits.
      </p>
    )
  },
  {
    expand: false,
    question: `Where can I ship to with Budget?`,
    answer: `You can only ship to the UK, USA, Australia, and Canada with Topship’s
          Budget service. We’re working on opening up more routes in
          the coming months.`
  },

  {
    expand: false,
    question: 'Do you offer doorstep delivery?',
    answer: (
      <p>
        Yes. All shipping options, excluding Sea Freight (<b>Ship Via Sea</b>
        ), include doorstep delivery to the recipient’s home. With Sea Freight,
        the recipient will be expected to pick up the package from a local
        warehouse or pay an additional charge for doorstep delivery.
      </p>
    )
  }
];

const importsquestions = [
  {
    expand: false,
    question: 'How long does the shipping take?',
    answer: (
      <div>
        <p>Delivery timeline depends on the shipping service you select.</p>
        <p>
          Express takes 3 - 7 working days (depending on pick-up and destination
          addresses), The timeline for Sea Freight import service differs. To
          get an accurate quote, visit the Topship app and request a quote or
          contact support.
        </p>
      </div>
    )
  },
  {
    expand: false,
    question: `Is there a minimum weight?`,
    answer: `No, there is not`
  },
  {
    expand: false,
    question: `Can you pick up from me?`,
    answer: `Yes, Pick-ups are available for Express and Sea Freight (“Ship Via Sea”). However, we will provide you with a shipping address for Shop & Ship and China Imports.`
  },
  {
    expand: false,
    question: 'Do you offer doorstep delivery?',
    answer: (
      <div>
        <p>
          This depends on the service you select and your destination address.
        </p>

        <p>
          If you live in Lagos, Nigeria, Topship will deliver your Express or
          Shop and Ship orders to your home at no additional cost. However,
          orders booked via China Imports or Sea Frieght will be charged for
          home delivery. The same thing applies to Shop & Ship orders delivered
          to other cities in Nigeria, outside Lagos.
        </p>
      </div>
    )
  }
];

const chinaImportQuestions = [
  {
    question: 'How do I access your address in China?',
    answer:
      'Our China address is in Guangzhou. To be able to see the full address, you have to complete the booking on the app.',
    expand: false
  },
  {
    question: 'Who handles pick-up from my supplier in China?',
    answer:
      'You will be responsible for shipping the goods to the provided China warehouse in Guangzhou. There are no pick-ups from your supplier.',
    expand: false
  },
  {
    question: 'Who handles delivery when it arrives in Lagos?',
    answer:
      'You will be responsible for delivery of your package. Additional charges will be determined once it lands in Lagos based on the final destination address.',
    expand: false
  },
  {
    question: 'If I cancel my order, how do you handle returns?',
    answer:
      'If, at any point, you decide not to proceed with shipping your package, you are responsible for covering the return delivery costs to their designated destination within China. Please note that you are unable to arrange pickups directly from the China warehouse.',
    expand: false
  },
  {
    question: 'How much is the return fee?',
    answer: (
      <div>
        <p>
          If you no longer want to ship your package once it arrives at the
          China warehouse, please be aware that you are responsible for paying
          for the delivery back to the specified destination in China. You
          cannot make pickups from the China warehouse.
        </p>
        <p>
          The shipping price for returns starts at $3.00 for the first kg, while
          the remaining kg will be $.50 for each kg. The maximum you will be
          charged is $50.{' '}
        </p>
        <p>
          For example,
          <br />
          Returning a 1kg package to your supplier will cost $3. Returning a
          10kg package to your supplier will cost $7.50. Returning a 100kg
          package to your supplier will cost a maximum of $50.
        </p>
      </div>
    ),
    expand: false
  },
  {
    question: 'Are there any prohibited items on this service?',
    answer: `At the moment, the following are prohibited: guns, military items, gas canister, chemicals and food items. Please reach out to our customer support team to clarify before shipping. Email hello@topship.africa`,
    expand: false
  }
];

const seaFreightQuestions = [
  {
    expand: false,
    question: 'How long does Sea Freight shipping take?',
    answer: (
      <div>
        <b>Sea Freight Import:</b>

        <p>
          The delivery timeline for the Sea Freight Import service differs based
          on pick-up and destination addresses. To get an accurate quote, go to
          the Topship app to request a quote or contact support.
        </p>
        <b>Sea Freight Export:</b>

        <p>8 - 12 weeks depending on pick-up and destination addresses.</p>
      </div>
    )
  },
  {
    expand: false,
    question: `Is there a minimum weight?`,
    answer: (
      <div>
        <b>Sea Freight Import: </b>
        <p>
          No minimum weight, however, this is subject to change based on pick-up
          and destination addresses.
        </p>
        <b>Sea Freight Export: </b>

        <p>
          This depends on your shipment destination. Exports to the UK have a
          min. weight of 100kg. There is no min. weight for exports to the USA.
        </p>
      </div>
    )
  },

  {
    expand: false,
    question: 'Can you pick it up from my location?',
    answer: (
      <div>
        <p>
          Yes, pick-ups can be done for a fee. Request a quote (Import) or book
          a shipment (export) to find out the cost.
        </p>
      </div>
    )
  },
  {
    expand: false,
    question: 'Do you offer doorstep delivery?',
    answer: (
      <div>
        <b>Sea Freight Import: </b>

        <p>
          Yes, doorstep delivery is available for a fee. This fee will be
          communicated as soon as your package arrives in Nigeria and is
          processed for delivery.
        </p>
        <b>Sea Freight Export: </b>
        <p>
          Yes, doorstep delivery is available for a fee. Alternatively, you can
          arrange a pick-up from our partner warehouses. Scan to download the
          Topship Mobile App Name Enter Name Phone Number Enter Phone Number
          Submit Name Enter Name Phone Number Enter Phone Number Submit Ship Now
          Ship Now Email Address hello@topship.africa +234801234578 Whatsapp
          Terms Privacy Whatsapp Line Whatsapp Line.
        </p>
      </div>
    )
  }
];
export {
  faqs,
  gatewayFaqs,
  articleData,
  searchFaqs,
  shopNShipquestions,
  exportsquestions,
  importsquestions,
  chinaImportQuestions,
  seaFreightQuestions
};
