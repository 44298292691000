import NewFooter from '../components/new-footer';
import GetMobileApp from '../components/new-home-page/get-mobile-app';

const countryLists = [
  { country: 'Burma', code: 'MM' },
  { country: 'Central Africa Republic', code: 'CF' },
  { country: 'Comoros', code: 'KM' },
  { country: 'Cuba', code: 'CU' },
  { country: 'Equatorial Guinea', code: 'GQ' },
  { country: 'Falklands', code: 'FK' },
  { country: 'Guinea Bissau', code: 'GW' },
  { country: 'Iran', code: 'IR' },
  { country: 'Johnston Island', code: '' },
  { country: 'Kiribati', code: 'KI' },
  { country: 'Mayotte Island', code: 'YT' },
  { country: 'Myanmar', code: 'MM' },
  { country: 'Nauru', code: 'NR' },
  { country: 'Niue', code: 'NU' },
  { country: 'North Korea', code: 'KP' },
  { country: 'Sao Tome and Principe', code: 'ST' },
  { country: 'St Helena (S. Atlantic)', code: 'SH' },
  { country: 'Saint Pierre et Miquelon', code: 'PM' },
  { country: 'Sierra Leone', code: 'SL' },
  { country: 'Solomon Islands', code: 'SB' },
  { country: 'Somalia', code: 'SO' },
  { country: 'Sudan', code: 'SD' },
  { country: 'Syria', code: 'SY' },
  { country: 'Tajikistan', code: 'TJ' },
  { country: 'Tokelau Islands', code: 'TK' },
  { country: 'Turkmenistan Republic', code: 'TM' },
  { country: 'Tuvalu', code: 'TV' },
  { country: 'Wake Islands', code: '' },
  { country: 'Yemen', code: 'YE' }
];

const restrictedCountries = [
  { country: 'Cambodia', code: 'KH' },
  { country: 'Congo', code: 'CD' },
  { country: 'Afghanistan', code: 'AF' },
  { country: 'Russia', code: 'RU' },
  { country: 'Ukraine', code: 'UA' },
  { country: 'Belarus', code: 'BY' },
  { country: 'Venezuela', code: 'VE' }
];

const cyprusCities = [
  'Nicosia',
  'Limassol',
  'Famagusta',
  'Paphos',
  'Larnaca',
  'Kyrenia',
  'Trikomo',
  'Ayia Napa',
  'Lefke',
  'Morphou',
  'North Nicosia',
  'Lapithos',
  'Bellapais',
  'Karpass Peninsula',
  'Kyrenia District',
  'Kakopetria',
  'Karavas',
  'Esentepe',
  'Boğaz',
  'Karaman',
  'Çatalköy'
];

const NonServingCountryPage = () => {
  const halfLength = Math.ceil(countryLists.length / 2);
  const firstColumn = countryLists.slice(0, halfLength);
  const secondColumn = countryLists.slice(halfLength);
  return (
    <>
      <div className='shipping-checklist'>
        <section className='shipping-checklist__non-serving'>
          <div className='intro-wrap'>
            <h2 className='heading'>Non-served countries and territories</h2>
            <p className='sub-heading'>
              For various reasons, the following countries and territories are
              not served by Topship’s partners, and packages destined for these
              countries are not permitted to be picked up.{' '}
            </p>
            <div className='prohibited-group'></div>
            <div className='list-group mt-5'>
              <ul
                className=''
                style={{
                  paddingLeft: '20px'
                }}
              >
                {firstColumn.map((list, index) => {
                  return (
                    <li key={index} className='list mb-4'>
                      {list.code && (
                        <img
                          style={{
                            paddingRight: '5px',
                            marginTop: '-6px'
                          }}
                          loading='lazy'
                          width='26'
                          height='12'
                          src={`https://flagcdn.com/w20/${list.code?.toLocaleLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w20/${list.code?.toLocaleLowerCase()}.png 2x`}
                          alt=''
                        />
                      )}
                      <span>{list.country}</span>
                    </li>
                  );
                })}
              </ul>
              <ul className='row-2'>
                {secondColumn.map((list, index) => {
                  return (
                    <li key={index} className='list mb-4'>
                      {list.code && (
                        <img
                          style={{
                            paddingRight: '5px',
                            marginTop: '-6px'
                          }}
                          loading='lazy'
                          width='26'
                          height='12'
                          src={`https://flagcdn.com/w20/${list.code?.toLocaleLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w20/${list.code?.toLocaleLowerCase()}.png 2x`}
                          alt=''
                        />
                      )}
                      <span>{list.country}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </section>

        <section
          style={{
            padding: '100px 0'
          }}
          className='shipping-checklist__non-serving'
        >
          <div className='intro-wrap'>
            <h3
              className=''
              style={{
                color: '#545859'
              }}
            >
              TEMPORARILY SUSPENDED SERVICES
            </h3>
            <p className='sub-heading'>
              For various reasons, the following countries and territories are
              not served by Topship’s partners, and packages destined for these
              countries are not permitted to be picked up.{' '}
            </p>
            <div className='list-group mt-5'>
              <div>
                <p className='country mb-4'>🏁 COUNTRIES</p>
                <ul
                  style={{
                    paddingLeft: '20px'
                  }}
                >
                  {restrictedCountries.map((list, index) => {
                    return (
                      <li key={index} className='list mb-4'>
                        {list.code && (
                          <img
                            style={{
                              paddingRight: '5px',
                              marginTop: '-6px'
                            }}
                            loading='lazy'
                            width='26'
                            height='12'
                            src={`https://flagcdn.com/w20/${list.code?.toLocaleLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w20/${list.code?.toLocaleLowerCase()}.png 2x`}
                            alt=''
                          />
                        )}
                        <span>{list.country}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className='row-2'>
                <p className='country mb-4'>🇨🇾 NORTHERN CYPRUS CITIES</p>

                <ul className=''>
                  {cyprusCities.map((list, index) => {
                    return (
                      <li key={index} className='list mb-4'>
                        <span>{list}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </section>
        <GetMobileApp />
        <section className='new-home-page__footer'>
          <NewFooter />
        </section>
      </div>
    </>
  );
};

export default NonServingCountryPage;
