import { makeStyles } from '@mui/styles';
import {
  PersonFourImg,
  PersonFourLogo,
  PersonOneImg,
  PersonOneLogo,
  PersonThreeImg,
  PersonThreeLogo,
  PersonTwoImg,
  PersonTwoLogo,
  WhiteChatIcon,
  WhiteEmailIcon,
  WhiteSupportIcon
} from '../../constants/asset-constants';

export const useStyles = makeStyles(theme => ({
  input: {
    background: '#FFFFFF'
  },
  autoComplete: {
    fontSize: '16px'
  },
  option: {
    fontSize: '12px'
  }
}));

export const defaultTestimonialData = {
  description: `“Topship provides an excellent way for designers to consolidate their shipping process and has made it easier to find multiple shipping options in one place.”`,
  author: `Ifeanyi Nwune`,
  company: `Creative Director, I.N Official`,
  logo: PersonOneLogo,
  videoId: 'fa8WQ9CoSWo'
};

export const getTestimonials = [
  {
    id: '1',
    description: `“Topship provides an excellent way for designers to consolidate their shipping process and has made it easier to find multiple shipping options in one place.”`,
    author: `Ifeanyi Nwune`,
    company: `Creative Director, I.N Official`,
    logo: PersonOneLogo,
    authorImg: PersonOneImg,
    videoId: 'https://youtu.be/4w_hnNjn_tk?si=PHADCr1EuQmbuhwm'
  },
  {
    id: '2',
    description: `“We currently export to 14 different countries, and Topship has helped our business with all our international shipping needs.”`,
    author: `Winston`,
    company: `Director and CEO of Winston Leather`,
    logo: PersonTwoLogo,
    authorImg: PersonTwoImg,
    videoId: 'https://youtu.be/B4DGNRov0V4?si=KyzZczbcAbUiCgxg'
  },
  {
    id: '3',
    description: `“Topship has been amazing for jersey delivery. I book a delivery from my desk, and Topship does the rest.”`,
    author: `Nneoma`,
    company: `Operations Manager, Sporting Lagos`,
    logo: PersonThreeLogo,
    authorImg: PersonThreeImg,
    videoId: 'https://youtu.be/gGGBZNLaou8?si=sbnVWEnWoNM_Vx1c'
  },
  {
    id: '4',
    description: `“Topship’s API documentation and developer support is excellent! We integrated in 3 days and the team was flexible enough to accommodate changes specific to our platform.”`,
    author: `Abon Ayodeji`,
    company: `Co-Founder & CTO, ShipBubble`,
    logo: PersonFourLogo,
    authorImg: PersonFourImg
  }
];

export const productArray = [
  {
    path: '/gateway',
    product: 'GateWay (For Logistics Companies)'
  },
  {
    path: '/chinaimports',
    product: 'China - Nigeria Import Route'
  },
  {
    path: '/shop-&-ship',
    product: 'Shop & Ship'
  },

  {
    path: '/exports',
    product: 'Exports'
  },
  {
    path: '/imports',
    product: 'Imports'
  },
  {
    path: '/sea-freight',
    product: 'Sea Freight'
  },
  {
    path: '/frozen-foods',
    product: 'Fresh & Frozen Foods'
  },
  {
    path: '/api',
    product: 'API Documentation'
  },
  {
    path: '/plug-ins',
    product: 'Plug-ins (For e-commerce brands)'
  }
];

export const ResourcesArray = [
  {
    path: '/shipping-checklist',
    product: 'Smart Shipping Checklist'
  },
  {
    path: '/prohibited-items',
    product: 'Prohibited Items'
  },
  {
    path: '/non-served-countries',
    product: 'Non-Served Countries & Territories'
  },
  {
    path: '/shipping-documentation',
    product: 'Shipping Documentation'
  },
  {
    path: '/broker-directory',
    product: 'Broker Directory'
  }
];

export const supportArray = [
  {
    title: 'Customer Care Line',
    label: '02013302594',
    icon: WhiteSupportIcon
  },
  {
    title: 'Email Address',
    label: 'hello@topship.africa',
    icon: WhiteEmailIcon
  },
  {
    title: 'Whatsapp Line',
    label: '+2349080777728',
    icon: WhiteChatIcon
  }
];

export const directoryPerCountries = [
  {
    country: '🇺🇬 Uganda',
    brokers: [
      {
        brokerName: 'G M C and SONS Logisitics',
        location: 'Tulip House, Kimera Road, Ntinda',
        tel: '+256 701 7488733'
      },
      {
        brokerName: 'BROSWEST LOGISTICS',
        location: 'Plot 2220 Portbell Road, Nakawa, Central Region',
        tel: '+256 759 712417'
      },
      {
        brokerName: 'Wazol Logisitcs Ltd',
        tel: '+256 704 147235'
      }
    ]
  },
  {
    country: '🇨🇲 Cameroon',
    brokers: [
      {
        brokerName: 'Universal Network Service (UNINET)',
        location: 'P.O BOX 571, Limbe , South West Region, Cameroon',
        tel: '+237 6807 38111'
      },
      {
        brokerName: 'Newtek Logistics Services',
        location: `Near Banque Atlantique, Rue Dika Mpondo, Douala, Cameroon`,
        tel: '+237 696 091 376',
        tel2: '+237 33 423 114 '
      }
    ]
  },
  {
    country: '🇨🇮 Cote D"Ivoire',
    brokers: [
      {
        brokerName: 'Zon Customs Brokers',
        location: '9 Garfield Terrace, Surfers Paradise, Queensland 4217',
        tel: '+61 1300 742 357'
      },
      {
        brokerName: 'Wallace International Freight & Customs Brokers',
        location: 'Unit 116 Lambeck Drive, Tullamarine VIC 3043',
        tel: '(07) 3399 6722'
      },
      {
        brokerName: 'Barry Taylor & Partners Customs Brokers',
        location: 'UNIT 11 43 LINKS AVE N, Eagle Farm, QLD 4009',
        tel: '(07) 3630 2368',
        tel2: '(07) 3630 1767'
      },
      {
        brokerName: 'G C M AND Air - Maritime Customs Brokerage Co Ltd',
        location: '5/9 Garfield Terrace, Gold Coast, 42',
        tel: ' +22 162 423 624'
      }
    ]
  }
];

export const budgetRatePerCountries = [
  {
    country: '🇺🇸 United States of America',
    rates: {
      nonFlats: '9.1kg and above - ₦21,000 per kg',
      flats: [
        '0.5kg - 1kg - ₦74,500',
        '1.1kg - 2kg - ₦88,600',
        '2.1kg - 3kg - ₦98,800',
        '3.1kg - 4kg - ₦112,500',
        '4.1kg - 5kg - ₦128,000',
        '5.1kg - 6kg - ₦143,300',
        '6.1kg - 7kg - ₦158,800',
        '7.1kg - 8kg - ₦159,000',
        '8.1kg - 9kg - ₦180,000'
      ]
    }
  },
  {
    country: '🇬🇧 United Kingdom',
    rates: {
      nonFlats: '9.1kg and above - ₦19,500 per kg',
      flats: [
        '0.5kg - 1kg - ₦64,000',
        '1.1kg - 2kg - ₦74,300',
        '2.1kg - 3kg - ₦85,500',
        '3.1kg - 4kg - ₦96,000',
        '4.1kg - 5kg - ₦108,000',
        '5.1kg - 6kg - ₦121,000',
        '6.1kg - 7kg - ₦132,000',
        '7.1kg - 8kg - ₦145,000',
        '8.1kg - 9kg - ₦147,000'
      ]
    }
  },
  {
    country: '🇨🇦 Canada',
    rates: {
      nonFlats: '9.1kg - 10kg and above = N24,500 per kg',
      flats: [
        '0.5kg - 1kg - ₦109,500',
        '1.1kg - 2kg - ₦123,600',
        '2.1kg - 3kg - ₦133,800',
        '3.1kg - 4kg - ₦147,500',
        '4.1kg - 5kg - ₦163,000',
        '5.1kg - 6kg - ₦178,500',
        '6.1kg - 7kg - ₦194,000',
        '7.1kg - 8kg - ₦207,800',
        '8.1kg - 9kg - ₦227,000'
      ]
    }
  },
  {
    country: '🇪🇺 Rest of Europe',
    rates: {
      nonFlats: '10kg and above - ₦17,000 per kg',
      flats: [
        '1kg - ₦72,000',
        '2kg - ₦87,000',
        '3kg - ₦102,000',
        '4kg - ₦112,000',
        '5kg - ₦127,000',
        '6kg - ₦142,000',
        '7kg - ₦157,000',
        '8kg - ₦162,000',
        '9kg - ₦177,000'
        // '9.1kg - 10kg - ₦165,000'
      ]
    }
  },
  {
    country: '🌍 Rest of Africa',
    rates: {
      nonFlats: '',
      flats: []
    }
  }
];
