import React, {
  FC,
  useState,
  useEffect,
  Dispatch,
  SetStateAction
} from 'react';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import * as yup from 'yup';
// import { useRecoilState } from "recoil";
import { makeStyles } from '@mui/styles';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import {
  useGetShipmentRateQuery,
  useGetCountriesLazyQuery,
  // useGetStatesQuery,
  useGetCitiesQuery
} from './../operations/queries';
import {
  // quoteRequestState,
  // quotesWeight as quotesWeightState,
  localAndExportsQuotesLocationDataState as quotesLocationDataState
  // localAndExportsQuotesAddressesState as quotesAddressesState,
  // localAndExportsQuotesSenderAddress as quotesSenderAddress,
  // localAndExportsQuotesReceiverAddress as quotesReceiverAddress,
  // quotesAddressesState as requestQuotesAddressesState,
  // localAndExportsQuotesWeight as quotesWeight,
} from './../recoil/atoms';
import BusyOverlay from './../components/busy-overlay';
import SubmitButton from './../components/submit-button';
import { getDefaultValue } from './../utilities/get-default-value';
import { roundUp } from './../utilities/round-up';
import logoBlue from '../assets/svgs/logo-blue.svg';
import ellipse from '../assets/svgs/ellipse.svg';
import Globe from './../assets/svgs/GlobeSimple.svg';
import Flag from './../assets/svgs/FlagBanner.svg';
import Weight from './../assets/svgs/weight.svg';

interface Props {
  mobileMenu: string;
  setMobileMenu: Dispatch<SetStateAction<string>>;
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
});

const Nav: FC<Props> = ({ mobileMenu, setMobileMenu }) => {
  const classes = useStyles();
  const [locationType]: any = useState();

  const [getCountries, countries]: any =
    useGetCountriesLazyQuery('localAndExports');
  const countriesValue = countries?.data?.getCountries || [];

  // const [getStates, statesResult]: any = useGetStatesQuery(
  //   locationType,
  //   quotesLocationDataState
  // );

  const [getCities, { data: citiesResult, loading: citiesLoading }]: any =
    useGetCitiesQuery(locationType, quotesLocationDataState);
  const citiesValue = citiesResult?.getCities || [];

  const [
    getRecipientCities,
    { data: citiesRecipientResult, loading: citiesRecipientLoading }
  ] = useGetCitiesQuery() as any;
  const citiesRecipientValue = citiesRecipientResult?.getCities || [];

  // const [, setQuotesWeight]: any = useRecoilState(quotesWeightState);

  // const [quotesLocation]: any = useRecoilState(quotesLocationDataState);
  // const [senderAddress]: any = useRecoilState(quotesSenderAddress);
  // const [quotesAddresses, setQuotesAddresses]: any =
  //   useRecoilState(quotesAddressesState);
  // const [, setRequestQuotesAddresses]: any = useRecoilState(
  //   requestQuotesAddressesState
  // );

  // const [weight]: any = useRecoilState(quotesWeight);
  // const [receiverAddress]: any = useRecoilState(quotesReceiverAddress);
  // const [requestPage, setRequestPage]: any = useRecoilState(quoteRequestState);

  // const [mobileMenu, setMobileMenu] = useState<string>("default");

  const [getShipmentRates, { loading, data, error: shipmentError }]: any =
    useGetShipmentRateQuery();

  const validationSchema = yup.object().shape({
    senderDetail: yup.object().shape({
      country: yup.string().required(`Please enter sender's country`),
      city: yup.string().required(`Please enter sender's city`)
    }),
    receiverDetail: yup.object().shape({
      country: yup.string().required(`Please enter sender's country`),
      city: yup.string().required(`Please enter sender's city`)
    }),
    totalWeight: yup
      .number()
      .required('Weight is required')
      .min(0.09999, 'Please enter a weight greater than zero (0)')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      senderDetail: {
        country: 'Nigeria',
        city: '',
        countryCode: 'NG'
      },
      receiverDetail: {
        country: '',
        city: '',
        countryCode: ''
      },
      totalWeight: 0
    },
    validationSchema,
    onSubmit: async values => {
      // console.log(values);
      const requestRatesData = {
        senderDetails: {
          cityName: values.senderDetail.city,
          countryCode: values.senderDetail.countryCode
        },
        receiverDetails: {
          cityName: values.receiverDetail.city,
          countryCode: values.receiverDetail.countryCode
        },
        totalWeight: roundUp(values.totalWeight)
      };

      getShipmentRates(requestRatesData);
    }
  });

  const { errors, values, touched, handleSubmit, handleChange, setFieldValue } =
    formik;

  const sender = `${values.senderDetail.countryCode},${values.senderDetail.city}`;
  const receiver = `${values.receiverDetail.countryCode},${values.receiverDetail.city}`;

  const params = new URLSearchParams({
    sender,
    receiver,
    weight: String(values.totalWeight)
  }).toString();

  const quoteSummaryURL = `${'https://ship.topship.africa'}/viewquote?${params}`;

  if (data && !shipmentError) {
    window.location.href = quoteSummaryURL;
  }

  // const handleShowMobileMenu = () => {
  //   setMobileMenu(mobileMenu !== "open" ? "open" : "close");
  // };

  // const quotesRequestData = {
  //   senderAddress: senderAddress.address,
  //   receiverAddress: receiverAddress.address,
  //   itemWeight: Number(weight),
  // };

  useEffect(
    () => {
      getCountries();
      getCities('NG');
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div className='nav'>
      <a href='/' className='nav__sign_in'>
        <img className='nav__logo__desktop' src={logoBlue} alt='Topship Logo' />
      </a>

      <div className='nav__desktop'>
        <a href='/blog' className='nav__sign_in'>
          Blog
        </a>
        <a href='https://intercom.help/topship/en/' className='nav__sign_in'>
          FAQ
        </a>
        <a href='https://ship.topship.africa/login' className='nav__sign_in'>
          Sign in
        </a>
        <a
          href='https://ship.topship.africa/signup'
          className='nav__book_shipment'
        >
          Create account
        </a>
      </div>

      <div className='nav__mobile'>
        <div className='nav__mobile__top_content'>
          <a href='/' className='nav__sign_in'>
            <img
              className='nav__logo__mobile'
              src={logoBlue}
              alt='Topship Logo'
            />
          </a>

          <div className='header__navigation__mobile'>
            <div
              className={`header__navigation__mobile__lines  ${
                mobileMenu === 'open'
                  ? 'header__navigation__mobile__lines--open'
                  : mobileMenu === 'close'
                  ? 'header__navigation__mobile__lines--close'
                  : 'header__navigation__mobile__lines--default'
              } ${'header__navigation__mobile__lines--white'} ${
                mobileMenu === 'open' &&
                'header__navigation__mobile__lines--white--open'
              }`}
              onClick={() =>
                setMobileMenu(mobileMenu !== 'open' ? 'open' : 'close')
              }
            >
              <i
                onClick={() =>
                  setMobileMenu(mobileMenu !== 'open' ? 'open' : 'close')
                }
              ></i>
            </div>

            <ul
              className={`header__navigation__mobile__list ${
                mobileMenu === 'open'
                  ? 'header__navigation__mobile__list--open'
                  : mobileMenu === 'close'
                  ? 'header__navigation__mobile__list--close'
                  : 'header__navigation__mobile__list--default'
              }`}
            >
              <li className='header__navigation__mobile__list__item'>
                <div className='nav__quotes'>
                  <h1 className='nav__quotes__header'>Request a Quote</h1>
                  <p className='nav__quotes__header-description'>
                    Enter the details of your shipment below
                  </p>

                  <div className='request' key={'localAndExports'}>
                    <div className='request__wrapper'>
                      <form onSubmit={handleSubmit} className='request__form'>
                        <span className='request__form__node__text'>
                          <img src={ellipse} alt='ellipse' /> {'Ship From'}
                        </span>

                        <div className='request__form__node request__form__node--origin'>
                          <div className='inputGroup2__inputs'>
                            <FormControl className='form-control'>
                              <Autocomplete
                                id='senderDetail.country'
                                options={[{ code: 'NG', name: 'Nigeria' }]}
                                classes={{
                                  option: classes.option
                                }}
                                disablePortal
                                autoHighlight
                                getOptionLabel={(option: any) =>
                                  option ? option.name : ''
                                }
                                renderOption={(props, option) => (
                                  <Box
                                    component='li'
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                    key={props.id}
                                  >
                                    <img
                                      loading='lazy'
                                      width='20'
                                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                      srcSet={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png 2x`}
                                      alt=''
                                    />
                                    {option.name} ({option.code})
                                  </Box>
                                )}
                                style={{ marginBottom: '10px' }}
                                noOptionsText='Country not found'
                                onChange={(e, country) => {
                                  if (country) {
                                    getCities(country.code);
                                    setFieldValue(
                                      'senderDetail.countryCode',
                                      country.code
                                    );
                                    setFieldValue(
                                      `senderDetail.country`,
                                      country?.name,
                                      true
                                    );
                                  }
                                }}
                                value={
                                  getDefaultValue(
                                    countriesValue,
                                    values.senderDetail.country,
                                    'name'
                                  ) || ''
                                }
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label='Choose a country'
                                    variant='standard'
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: 'new-password' // disable autocomplete and autofill
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: (
                                        <>
                                          <InputAdornment position='start'>
                                            <img src={Globe} alt='globe' />
                                          </InputAdornment>
                                          {params.InputProps.startAdornment}
                                        </>
                                      ),
                                      style: { fontSize: 14 }
                                    }}
                                    InputLabelProps={{
                                      style: { fontSize: 14 }
                                    }}
                                    value={values.senderDetail.country}
                                    placeholder='Required'
                                    fullWidth
                                  />
                                )}
                                disabled={!countriesValue.length}
                              />

                              <small>
                                {touched.senderDetail?.country &&
                                  errors.senderDetail?.country}
                              </small>
                            </FormControl>
                          </div>

                          <div className='inputGroup2__inputs'>
                            <FormControl className='form-control'>
                              <Autocomplete
                                id='senderDetail.city'
                                options={citiesValue}
                                classes={{
                                  option: classes.option
                                }}
                                disablePortal
                                autoHighlight
                                getOptionLabel={(option: any) =>
                                  option ? option.cityName : ''
                                }
                                renderOption={(props, option) => (
                                  <Box
                                    component='li'
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                    key={props.id}
                                  >
                                    {option.cityName}
                                  </Box>
                                )}
                                style={{ marginBottom: '10px' }}
                                noOptionsText='City not found'
                                onChange={(e, city) => {
                                  if (city) {
                                    setFieldValue(
                                      `senderDetail.city`,
                                      city?.cityName,
                                      true
                                    );
                                  }
                                }}
                                value={
                                  getDefaultValue(
                                    citiesValue,
                                    values.senderDetail.city,
                                    'cityName'
                                  ) || ''
                                }
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label='Choose a city'
                                    variant='standard'
                                    inputProps={{
                                      ...params.inputProps,
                                      // type: 'search',
                                      autoComplete: 'new-password' // disable autocomplete and autofill
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: (
                                        <>
                                          <InputAdornment position='start'>
                                            <img src={Flag} alt='flag' />
                                          </InputAdornment>
                                          {params.InputProps.startAdornment}
                                        </>
                                      ),
                                      style: { fontSize: 14 }
                                    }}
                                    InputLabelProps={{
                                      style: { fontSize: 14 }
                                    }}
                                    value={values.senderDetail.city}
                                    placeholder='Required'
                                    fullWidth
                                  />
                                )}
                                disabled={!citiesValue.length}
                              />

                              <small>
                                {touched.senderDetail?.city &&
                                  errors.senderDetail?.city}
                              </small>
                            </FormControl>
                          </div>

                          <div className='inputGroup2__inputs'>
                            <FormControl
                              className='form-control'
                              style={{ marginTop: '10px' }}
                            >
                              <InputLabel
                                className='form-label'
                                htmlFor='totalWeight'
                                style={{ fontSize: '14px' }}
                              >
                                Item Weight (KG)
                              </InputLabel>
                              <Input
                                id='totalWeight'
                                type='number'
                                name='totalWeight'
                                value={values.totalWeight}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.totalWeight && errors.totalWeight
                                )}
                                style={{ fontSize: '14px' }}
                                fullWidth
                                startAdornment={
                                  <InputAdornment position='start'>
                                    <img
                                      src={Weight}
                                      alt='weight'
                                      style={{ width: '20px' }}
                                    />
                                  </InputAdornment>
                                }
                                placeholder='Required'
                              />

                              <small>
                                {touched.totalWeight && errors.totalWeight}
                              </small>
                            </FormControl>
                          </div>
                        </div>

                        <span className='request__form__node__text request__form__node__text--destination'>
                          <img src={ellipse} alt='ellipse' /> Deliver To
                        </span>

                        <div className='request__form__node request__form__node--origin'>
                          <div className='inputGroup2__inputs'>
                            <FormControl className='form-control'>
                              <Autocomplete
                                id='receiverDetail.country'
                                options={countriesValue}
                                classes={{
                                  option: classes.option
                                }}
                                disablePortal
                                autoHighlight
                                getOptionLabel={(option: any) =>
                                  option ? option.name : ''
                                }
                                style={{ marginBottom: '10px' }}
                                noOptionsText='Country not found'
                                renderOption={(props, option) => (
                                  <Box
                                    component='li'
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                    key={props.id}
                                  >
                                    <img
                                      loading='lazy'
                                      width='20'
                                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                      srcSet={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png 2x`}
                                      alt=''
                                    />
                                    {option.name} ({option.code})
                                  </Box>
                                )}
                                onChange={(e, country) => {
                                  if (country) {
                                    getRecipientCities(country.code);
                                    setFieldValue(
                                      'receiverDetail.countryCode',
                                      country.code
                                    );
                                    setFieldValue(
                                      `receiverDetail.country`,
                                      country?.name,
                                      true
                                    );
                                  }
                                }}
                                value={
                                  getDefaultValue(
                                    countriesValue,
                                    values.receiverDetail.country,
                                    'name'
                                  ) || ''
                                }
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label='Choose a country'
                                    variant='standard'
                                    inputProps={{
                                      ...params.inputProps,
                                      // type: 'search',
                                      autoComplete: 'new-password' // disable autocomplete and autofill
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: (
                                        <>
                                          <InputAdornment position='start'>
                                            <img src={Globe} alt='globe' />
                                          </InputAdornment>
                                          {params.InputProps.startAdornment}
                                        </>
                                      ),
                                      style: { fontSize: 14 }
                                    }}
                                    InputLabelProps={{
                                      style: { fontSize: 14 }
                                    }}
                                    value={values.receiverDetail.country}
                                    placeholder='Required'
                                    fullWidth
                                  />
                                )}
                                disabled={!countriesValue.length}
                              />

                              <small>
                                {touched.receiverDetail?.country &&
                                  errors.receiverDetail?.country}
                              </small>
                            </FormControl>
                          </div>

                          <div className='inputGroup2__inputs'>
                            <FormControl className='form-control'>
                              <Autocomplete
                                id='receiverDetail.city'
                                options={citiesRecipientValue}
                                classes={{
                                  option: classes.option
                                }}
                                disablePortal
                                autoHighlight
                                getOptionLabel={(option: any) =>
                                  option ? option.cityName : ''
                                }
                                style={{ marginBottom: '10px' }}
                                renderOption={(props, option) => (
                                  <Box
                                    component='li'
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                    key={props.id}
                                  >
                                    {option.cityName}
                                  </Box>
                                )}
                                noOptionsText='City not found'
                                onChange={(e, city) => {
                                  if (city) {
                                    setFieldValue(
                                      `receiverDetail.city`,
                                      city?.cityName,
                                      true
                                    );
                                  }
                                }}
                                value={
                                  getDefaultValue(
                                    citiesRecipientValue,
                                    values.receiverDetail.city,
                                    'cityName'
                                  ) || ''
                                }
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label='Choose a city'
                                    variant='standard'
                                    inputProps={{
                                      ...params.inputProps,
                                      // type: 'search',
                                      autoComplete: 'new-password' // disable autocomplete and autofill
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: (
                                        <>
                                          <InputAdornment position='start'>
                                            <img src={Flag} alt='flag' />
                                          </InputAdornment>
                                          {params.InputProps.startAdornment}
                                        </>
                                      ),
                                      style: { fontSize: 14 }
                                    }}
                                    InputLabelProps={{
                                      style: { fontSize: 14 }
                                    }}
                                    value={values.receiverDetail.city}
                                    placeholder='Required'
                                    fullWidth
                                  />
                                )}
                                disabled={!citiesRecipientValue.length}
                              />

                              <small>
                                {touched.receiverDetail?.city &&
                                  errors.receiverDetail?.city}
                              </small>
                            </FormControl>
                          </div>
                        </div>

                        <div className='submit mt-2'>
                          <SubmitButton
                            text='Get Rates'
                            width={false ? '10%' : '100%'}
                            loading={false}
                          />
                        </div>
                      </form>
                    </div>
                    <BusyOverlay loading={countries.loading} />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className='nav__mobile__bottom_content'>
          <hr style={{ marginTop: '15px' }} />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <a
              href='https://ship.topship.africa/login'
              style={{ width: '35%' }}
            >
              <button className='nav__login buttons'>Sign in</button>
            </a>

            <a
              href='https://ship.topship.africa/signup'
              style={{ width: '60%' }}
            >
              <button className='nav__signups buttons'>Create account</button>
            </a>
          </div>
        </div>
      </div>

      <BusyOverlay
        loading={
          countries.loading ||
          citiesLoading ||
          citiesRecipientLoading ||
          loading
        }
      />
    </div>
  );
};

export default Nav;
