import axios from 'axios';
import gql from 'graphql-tag';

const GET_SHIPMENT_RATES = gql`
  query getShipmentRates($shipmentDetail: GetShipmentRateInput!) {
    getShipmentRate(shipmentDetail: $shipmentDetail) {
      mode
      cost
      duration
      currency
    }
  }
`;

const GET_COUNTRIES = gql`
  query {
    getCountries {
      code
      name
    }
  }
`;

const GET_STATES = gql`
  query getStates($countryCode: String!) {
    getStates(countryCode: $countryCode) {
      name
      countryCode
    }
  }
`;

const GET_CITIES = gql`
  query getCities($countryCode: String!) {
    getCities(countryCode: $countryCode) {
      cityName
      postcode
    }
  }
`;

const GET_CITIES_QUERY = async params => {
  const { countryCode, cityName } = params;
  return await axios.get(
    `${process.env.REACT_APP_REST_API}?city=${cityName || 'a'}&countryCode=${
      countryCode || 'NG'
    }`
  );
};

export {
  GET_SHIPMENT_RATES,
  GET_COUNTRIES,
  GET_STATES,
  GET_CITIES,
  GET_CITIES_QUERY
};
