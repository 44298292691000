import React from 'react';
import {
  aboutImageOne,
  aboutImageTwo,
  aboutImageThree,
  aboutImageFour,
  featureIconOne,
  featureIconTwo,
  featureIconThree,
  featureIconFour,
  howItWorksIconOne,
  howItWorksIconTwo,
  howItWorksIconThree,
  howItWorksIconFour,
  serviceIconOne,
  serviceIconTwo,
  serviceIconThree,
  charity,
  junaid,
  moses,
  frank,
  blessing,
  kenny,
  mosope,
  ima,
  louis,
  ope,
  michael,
  kayode,
  erma,
  adun,
  ups,
  fedex,
  cargoPlug,
  aramex,
  overland,
  danaAir,
  curacel,
  dellyman,
  uber,
  dhl,
  ShopnShipIcon
} from '../constants/asset-constants';
import NewFooter from '../components/new-footer';
import FooterCta from '../components/new-home-page/footer-cta';
import { CountUp } from 'use-count-up';
import { useHistory } from 'react-router-dom';
import ContactUs from '../components/contact-us';
import { Helmet } from 'react-helmet';
const About: React.FC = () => {
  const responsive = {
    desktopAverage: 1350,
    miniMobile: 500
  };
  const history = useHistory();
  const openUrl = (link: string) => {
    history.push(`/${link}`);
  };
  return (
    <>
      <Helmet>
        <title>Topship Africa: Imports and Exports Made Easy</title>
        <meta
          name='description'
          content='Take your business from Nigeria to the rest of the world when you ship internationally with Topship.'
        />
        <meta
          name='keywords'
          content='Topship, international shipping, express delivery, logistics delivery company, international delivery, local shipping, cheap international shipping companies, cargo delivery'
        />
      </Helmet>
      <div className='about'>
        <div className='about__intro'>
          <h3 className='about__intro__text'>Who we are</h3>
          <div className='about__hero'>
            <h1 className='about__hero__header'>
              Topship is the easiest way for African businesses to ship
              Worldwide
            </h1>
            <p
              style={{
                fontWeight: 500
              }}
              className='about__hero__text'
            >
              Topship is a global shipping platform that helps you import and
              export packages of all sizes at flexible prices. We are trusted by
              businesses of all sizes, individuals and third-party logistics
              companies.
            </p>
          </div>
        </div>
        <div className='about__counts'>
          <div className='about__counts__wrap'>
            <div className='about_count'>
              <span className='about__count__value'>
                <CountUp isCounting end={80000} duration={3.2} />+
              </span>
              <span className='about__count__text'>
                Booked {window.innerWidth <= responsive.miniMobile && <br />}{' '}
                Shipments
              </span>
            </div>
            <span className='about__count__liner'></span>
            <div className='about_count'>
              <span className='about__count__value'>
                <CountUp isCounting end={3000} duration={3.2} />+
              </span>
              <span className='about__count__text'>
                Paid {window.innerWidth <= responsive.miniMobile && <br />}{' '}
                Customers
              </span>
            </div>
            <span className='about__count__liner'></span>
            <div className='about_count'>
              <span className='about__count__value'>
                <CountUp isCounting end={200} duration={3.2} />+
              </span>
              <span className='about__count__text'>
                Cities {window.innerWidth <= responsive.miniMobile && <br />}{' '}
                Reached
              </span>
            </div>
          </div>
        </div>
        <div className='about__images'>
          <div className='about__images__wrap'>
            <div className='about__image'>
              <img src={aboutImageOne} alt='' className='img-fluid' />
            </div>
            <div className='about__image'>
              <img src={aboutImageTwo} alt='' className='img-fluid' />
            </div>
            <div className='about__image'>
              <img src={aboutImageThree} alt='' className='img-fluid' />
            </div>
            <div className='about__image'>
              <img src={aboutImageFour} alt='' className='img-fluid' />
            </div>
          </div>
        </div>

        <div
          className='about__new-partners'
          style={{
            background: '#fff'
          }}
        >
          <p>Our Partners</p>
          <div className='partner-logos'>
            <img src={ups} alt='' />
            <img src={fedex} alt='' />
            <img src={cargoPlug} alt='' />
            <img src={aramex} alt='' />
            <img src={overland} alt='' />
            <img src={danaAir} alt='' />
            <img src={curacel} alt='' />
            <img src={dellyman} alt='' />
            <img src={uber} alt='' />
            <img src={dhl} alt='' />
          </div>
        </div>
        <div className='about__features'>
          <div className='about__features__container'>
            <div className='about__features__header'>
              <div className='about__features__question'>
                <p>WHY TOPSHIP?</p>
              </div>
              <div className='about__features__answer'>
                <h1>
                  We provide a full featured logistics solution for your
                  business
                </h1>
              </div>
            </div>
            <div className='about__features__wrap'>
              <div className='about__feature'>
                <div className='about__feature__wrap'>
                  <div className='about__feature__icon'>
                    <img src={featureIconOne} className='img-fluid' alt='' />
                  </div>
                  <div className='about__feature__header'>
                    <h4>
                      Dedicated agent for your <br />
                      shipping needs
                    </h4>
                  </div>
                  <div className='about__feature__content'>
                    <p>
                      Our customer support representatives are friendly and
                      equipped with answers and delivery updates. We serve you
                      with a smile and you will never speak to a robot!{' '}
                    </p>
                  </div>
                </div>
              </div>
              <div className='about__feature'>
                <div className='about__feature__wrap'>
                  <div className='about__feature__icon'>
                    <img src={featureIconTwo} className='img-fluid' alt='' />
                  </div>
                  <div className='about__feature__header'>
                    <h4>Flexible Pricing</h4>
                  </div>
                  <div className='about__feature__content'>
                    <p>
                      Choose your speed and price. We provide express delivery
                      and basic (cargo) delivery options to any destination
                      within Africa and across the world.
                    </p>
                  </div>
                </div>
              </div>
              <div className='about__feature'>
                <div className='about__feature__wrap'>
                  <div className='about__feature__icon'>
                    <img src={featureIconOne} className='img-fluid' alt='' />
                  </div>
                  <div className='about__feature__header'>
                    <h4>
                      Reach international <br />
                      suppliers
                    </h4>
                  </div>
                  <div className='about__feature__content'>
                    <p>
                      Never run out of inventory! Import goods from
                      manufacturers and vendors in China, UK, US, Turkey and
                      more at pocket-friendly rates.
                    </p>
                  </div>
                </div>
              </div>
              <div className='about__feature'>
                <div className='about__feature__wrap'>
                  <div className='about__feature__icon'>
                    <img src={featureIconThree} className='img-fluid' alt='' />
                  </div>
                  <div className='about__feature__header'>
                    <h4>
                      Last-mile, <br />
                      doorstep delivery
                    </h4>
                  </div>
                  <div className='about__feature__content'>
                    <p>
                      We pick up from your home, office, or warehouse and
                      deliver to any location worldwide. Same-day pick up within
                      Lagos, Abuja, Ibadan and PHC. (Coming soon to Abia)
                    </p>
                  </div>
                </div>
              </div>
              <div className='about__feature'>
                <div className='about__feature__wrap'>
                  <div className='about__feature__icon'>
                    <img src={featureIconFour} className='img-fluid' alt='' />
                  </div>
                  <div className='about__feature__header'>
                    <h4>Sell {'&'} Ship to Africa</h4>
                  </div>
                  <div className='about__feature__content'>
                    <p>
                      Tap into the billion-dollar buying power of Africans. Ship
                      your products from the USA or UK to customers in Nigeria.
                    </p>
                  </div>
                </div>
              </div>
              <div className='about__feature'>
                <div className='about__feature__wrap'>
                  <div className='about__feature__icon'>
                    <img src={featureIconOne} className='img-fluid' alt='' />
                  </div>
                  <div className='about__feature__header'>
                    <h4>Visibility</h4>
                  </div>
                  <div className='about__feature__content'>
                    <p>
                      Track your shipments in real-time. We deliver shipment
                      notifications straight to your phone and email inbox.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='about__howItWorks'>
          <div className='about__howItWorks__container'>
            <div className='about__howItWorks__description'>
              <div className='about__howItWorks__header'>
                <h3>How it works</h3>
              </div>
              <div className='about__howItWorks__content'>
                <h2>
                  It’s simple - You{' '}
                  {window.innerWidth >= responsive.desktopAverage && <br />}
                  sell, We fulfil
                </h2>
              </div>
            </div>
            <div className='about__howItWorks__items'>
              <div className='about__howItWorks__items__wrap'>
                <div className='about__howItWorks__item'>
                  <div className='about__howItWorks__item__wrap'>
                    <div className='about__howItWorks__item__icon'>
                      <img
                        src={howItWorksIconOne}
                        className='img-fluid'
                        alt=''
                      />
                    </div>
                    <div className='about__howItWorks__item__header'>
                      <h4>We pick up</h4>
                    </div>
                    <div className='about__howItWorks__item__content'>
                      <p>
                        Sit back and relax in the comfort of your home, office
                        or warehouse while we pick your items up and deliver
                        them to their destinations.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='about__howItWorks__item'>
                  <div className='about__howItWorks__item__wrap'>
                    <div className='about__howItWorks__item__icon'>
                      <img
                        src={howItWorksIconTwo}
                        className='img-fluid'
                        alt=''
                      />
                    </div>
                    <div className='about__howItWorks__item__header'>
                      <h4>We package with care</h4>
                    </div>
                    <div className='about__howItWorks__item__content'>
                      <p>
                        We provide you with free standard packaging materials so
                        your items can get to the recipients the same way you
                        intend for it to be delivered.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='about__howItWorks__item'>
                  <div className='about__howItWorks__item__wrap'>
                    <div className='about__howItWorks__item__icon'>
                      <img
                        src={howItWorksIconThree}
                        className='img-fluid'
                        alt=''
                      />
                    </div>
                    <div className='about__howItWorks__item__header'>
                      <h4>We Ship</h4>
                    </div>
                    <div className='about__howItWorks__item__content'>
                      <p>
                        Enjoy fast, flexible, and convenient shipping solutions
                        that allow you to send items all over the world while
                        you stay in control of the price and delivery times.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='about__howItWorks__item'>
                  <div className='about__howItWorks__item__wrap'>
                    <div className='about__howItWorks__item__icon'>
                      <img
                        src={howItWorksIconFour}
                        className='img-fluid'
                        alt=''
                      />
                    </div>
                    <div className='about__howItWorks__item__header'>
                      <h4>Receive tracking updates</h4>
                    </div>
                    <div className='about__howItWorks__item__content'>
                      <p>
                        Get shipment tracking updates delivered straight to your
                        email. You never have to guess the location of your item
                        with Topship.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='about__services'>
          <div className='about__services__container'>
            <div className='about__services__header'>
              <h2>Our Services</h2>
            </div>
            <div className='about__services__wrap'>
              <div
                onClick={() => openUrl('lagos')}
                className='about__service about__feature'
              >
                <div className='about__feature__wrap'>
                  <div className='about__feature__icon'>
                    <img src={serviceIconOne} className='img-fluid' alt='' />
                  </div>
                  <div className='about__feature__header'>
                    <h4>Inter-city Deliveries</h4>
                  </div>
                  <div className='about__feature__content'>
                    <p>
                      Pickup and Delivery to any in Nigeria, e.g. Lagos -
                      Ibadan, Lagos - PHC. We do not offer intra-city deliveries
                      e.g. Lagos - Lagos
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={() => openUrl('exports')}
                className='about__service about__feature'
              >
                <div className='about__feature__wrap'>
                  <div className='about__feature__icon'>
                    <img src={serviceIconTwo} className='img-fluid' alt='' />
                  </div>
                  <div className='about__feature__header'>
                    <h4>Exports</h4>
                  </div>
                  <div className='about__feature__content'>
                    <p>
                      International shipping from Nigeria to over 200+ cities
                      worldwide
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={() => openUrl('imports')}
                className='about__service about__feature'
              >
                <div className='about__feature__wrap'>
                  <div className='about__feature__icon'>
                    <img src={serviceIconThree} className='img-fluid' alt='' />
                  </div>
                  <div className='about__feature__header'>
                    <h4>Imports</h4>
                  </div>
                  <div className='about__feature__content'>
                    <p>
                      Shop from UK {'&'} US stores and ship to Nigeria. Express{' '}
                      {'&'} Cargo delivery options available.
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={() => openUrl('shop-&-ship')}
                className='about__service about__feature'
              >
                <div className='about__feature__wrap'>
                  <div className='about__feature__icon'>
                    <img
                      style={{
                        width: '40px'
                      }}
                      src={ShopnShipIcon}
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                  <div className='about__feature__header'>
                    <h4>Shop & Ship</h4>
                  </div>
                  <div className='about__feature__content'>
                    <p>
                      Shop from online stores and ship it to our addresses in
                      the uk, USA, and Canada.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='about__team'>
          <div className='about__team__wrap'>
            <div className='about__team__text'>
              <div className='about__team__header'>
                <h4>Our Team</h4>
              </div>
              <div className='about__team__content'>
                <h2>The People behind us</h2>
              </div>
            </div>
            <div className='about__team__members__wrap'>
              <div className='about__team__member'>
                <div className='about__team__member__wrap'>
                  <div className='about__team__member__image'>
                    <img
                      src={louis}
                      className='img-fluid'
                      loading='lazy'
                      alt='Louis Essien'
                    />
                  </div>
                  <div className='about__team__member__content'>
                    <div className='about__team__member__name'>
                      <h4>Louis Essien</h4>
                    </div>
                    <div className='about__team__member__role'>
                      <p>Design</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='about__team__member'>
                <div className='about__team__member__wrap'>
                  <div className='about__team__member__image'>
                    <img
                      src={mosope}
                      className='img-fluid'
                      loading='lazy'
                      alt='Mosope Owasanoye'
                    />
                  </div>
                  <div className='about__team__member__content'>
                    <div className='about__team__member__name'>
                      <h4>Mosope Owasanoye</h4>
                    </div>
                    <div className='about__team__member__role'>
                      <p>Operations</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='about__team__member'>
                <div className='about__team__member__wrap'>
                  <div className='about__team__member__image'>
                    <img
                      src={ima}
                      className='img-fluid'
                      loading='lazy'
                      alt='Ima Ekpo'
                    />
                  </div>
                  <div className='about__team__member__content'>
                    <div className='about__team__member__name'>
                      <h4>Ima Ekpo</h4>
                    </div>
                    <div className='about__team__member__role'>
                      <p>Marketing</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='about__team__member'>
                <div className='about__team__member__wrap'>
                  <div className='about__team__member__image'>
                    <img
                      src={junaid}
                      className='img-fluid'
                      loading='lazy'
                      alt='Babatunde Junaid'
                    />
                  </div>
                  <div className='about__team__member__content'>
                    <div className='about__team__member__name'>
                      <h4>Babatunde Junaid</h4>
                    </div>
                    <div className='about__team__member__role'>
                      <p>Co-Founder/CTO</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='about__team__member'>
                <div className='about__team__member__wrap'>
                  <div className='about__team__member__image'>
                    <img
                      src={kenny}
                      className='img-fluid'
                      loading='lazy'
                      alt='Kehinde Afolabi'
                    />
                  </div>
                  <div className='about__team__member__content'>
                    <div className='about__team__member__name'>
                      <h4>Kehinde Afolabi</h4>
                    </div>
                    <div className='about__team__member__role'>
                      <p>Operations</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='about__team__member'>
                <div className='about__team__member__wrap'>
                  <div className='about__team__member__image'>
                    <img
                      src={frank}
                      className='img-fluid'
                      loading='lazy'
                      alt='Franklin Ugobude'
                    />
                  </div>
                  <div className='about__team__member__content'>
                    <div className='about__team__member__name'>
                      <h4>Franklin Ugobude</h4>
                    </div>
                    <div className='about__team__member__role'>
                      <p>Marketing</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='about__team__member'>
                <div className='about__team__member__wrap'>
                  <div className='about__team__member__image'>
                    <img
                      src={michael}
                      className='img-fluid'
                      loading='lazy'
                      alt='Michael Nwuju'
                    />
                  </div>
                  <div className='about__team__member__content'>
                    <div className='about__team__member__name'>
                      <h4>Michael Nwuju</h4>
                    </div>
                    <div className='about__team__member__role'>
                      <p>Engineering</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='about__team__member'>
                <div className='about__team__member__wrap'>
                  <div className='about__team__member__image'>
                    <img
                      src={blessing}
                      className='img-fluid'
                      loading='lazy'
                      alt='Blessing Sanni'
                    />
                  </div>
                  <div className='about__team__member__content'>
                    <div className='about__team__member__name'>
                      <h4>Blessing Sanni</h4>
                    </div>
                    <div className='about__team__member__role'>
                      <p>Merchant Support</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='about__team__member'>
                <div className='about__team__member__wrap'>
                  <div className='about__team__member__image'>
                    <img
                      src={moses}
                      className='img-fluid'
                      loading='lazy'
                      alt='Moses Enweali'
                    />
                  </div>
                  <div className='about__team__member__content'>
                    <div className='about__team__member__name'>
                      <h4>Moses Enenwali</h4>
                    </div>
                    <div className='about__team__member__role'>
                      <p>Co-Founder/CEO</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='about__team__member'>
                <div className='about__team__member__wrap'>
                  <div className='about__team__member__image'>
                    <img
                      src={charity}
                      className='img-fluid'
                      loading='lazy'
                      alt='Charity Ika'
                    />
                  </div>
                  <div className='about__team__member__content'>
                    <div className='about__team__member__name'>
                      <h4>Charity Ika</h4>
                    </div>
                    <div className='about__team__member__role'>
                      <p>Merchant Support</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='about__team__member'>
                <div className='about__team__member__wrap'>
                  <div className='about__team__member__image'>
                    <img
                      src={ope}
                      className='img-fluid'
                      loading='lazy'
                      alt='Opeyemi Sekinat'
                    />
                  </div>
                  <div className='about__team__member__content'>
                    <div className='about__team__member__name'>
                      <h4>Opeyemi Saliu</h4>
                    </div>
                    <div className='about__team__member__role'>
                      <p>Merchant Support</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='about__team__member'>
                <div className='about__team__member__wrap'>
                  <div className='about__team__member__image'>
                    <img
                      src={kayode}
                      className='img-fluid'
                      loading='lazy'
                      alt='Kayode Sijuola'
                    />
                  </div>
                  <div className='about__team__member__content'>
                    <div className='about__team__member__name'>
                      <h4>Kayode Sijuola</h4>
                    </div>
                    <div className='about__team__member__role'>
                      <p>Operations</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='about__team__member'>
                <div className='about__team__member__wrap'>
                  <div className='about__team__member__image'>
                    <img
                      src={erma}
                      className='img-fluid'
                      loading='lazy'
                      alt='Erma Okafor'
                    />
                  </div>
                  <div className='about__team__member__content'>
                    <div className='about__team__member__name'>
                      <h4>Erma Okafor</h4>
                    </div>
                    <div className='about__team__member__role'>
                      <p>Merchant Support</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='about__team__member'>
                <div className='about__team__member__wrap'>
                  <div className='about__team__member__image'>
                    <img
                      src={adun}
                      className='img-fluid'
                      loading='lazy'
                      alt='Adunola Odetola'
                    />
                  </div>
                  <div className='about__team__member__content'>
                    <div className='about__team__member__name'>
                      <h4>Adunola Odetola</h4>
                    </div>
                    <div className='about__team__member__role'>
                      <p>Engineering</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactUs>
          <FooterCta heading='Create an account and start shipping in minutes' />
        </ContactUs>
      </div>
      <section className='new-home-page__footer'>
        <NewFooter />
      </section>
    </>
  );
};

export default About;
