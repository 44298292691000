import { ArrowUp } from '../../constants/asset-constants';

export const ShippingTime = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'shipping-time' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>How long does the shipping take?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'shipping-time' ? 'show' : 'hide'}`}>
        <p>Delivery timeline depends on the shipping service you select.</p>
        <p>
          The delivery timeline depends on the shipping service you select.
          Express takes 3 - 7 working days (depending on pick-up and destination
          addresses), The timeline for Sea Freight import service differs. To
          get an accurate quote, visit the Topship app and request a quote or
          contact support.
        </p>
      </div>
    </div>
  );
};

export const MinimumWeight = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${
        activeArticle === 'minimum-weight' ? 'active' : ''
      }`}
    >
      <div className='flexed-heading'>
        <h2>Is there a minimum weight?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div
        className={`${activeArticle === 'minimum-weight' ? 'show' : 'hide'}`}
      >
        <p>No, there is not</p>
      </div>
    </div>
  );
};

export const PickupForMe = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'pickup-for-me' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>Can you pick up from me?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'pickup-for-me' ? 'show' : 'hide'}`}>
        <p>
          Yes, Pick-ups are available for Express and Sea Freight (
          <b>Ship Via Sea</b>). However, we will provide you with a shipping
          address for Shop & Ship and China Imports.
        </p>
      </div>
    </div>
  );
};

export const DoorstepDelivery = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${
        activeArticle === 'doorstep-delivery' ? 'active' : ''
      }`}
    >
      <div className='flexed-heading'>
        <h2>Do you offer doorstep delivery?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div
        className={`${activeArticle === 'doorstep-delivery' ? 'show' : 'hide'}`}
      >
        <p>
          This depends on the service you select and your destination address.
        </p>

        <p>
          If you live in Lagos, Nigeria, Topship will deliver your Express or
          Shop and Ship orders to your home at no additional cost. However,
          orders booked via China Imports or Sea Frieght will be charged for
          home delivery. The same thing applies to Shop & Ship orders delivered
          to other cities in Nigeria, outside Lagos.
        </p>
      </div>
    </div>
  );
};
