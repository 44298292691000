import { NigerianPopularCities } from '../constants/cities';
import { allCountriesJSONData } from '../constants/countries';
import { NigerianStates } from '../constants/states';

const getShippingLocationInfo = () => {
  const urlPath = window.location.pathname.split('/');
  const shippingLocation = urlPath[1];
  const removeWhiteSpace = (array: string[]) =>
    array.map(state => state.split(' ').join(''));
  const statesAndCities = [...NigerianPopularCities, ...NigerianStates];
  const regex = (word?: string) =>
    urlPath[2]?.toLowerCase() !== 'nigeria'
      ? new RegExp(removeWhiteSpace(statesAndCities).join('|'), 'i').test(
          word || urlPath[2]
        )
      : false;
  let senderFromURL = urlPath[1] ? 'ng' : urlPath[1];
  let receiverFromURL =
    urlPath[2] === 'uk' ? 'gb' : regex() ? 'ng' : urlPath[2];

  const getCountryCode = (codeFromURL: string) => {
    const fetchedCountryInfo = allCountriesJSONData.find(
      country =>
        country.code?.toLocaleLowerCase() === codeFromURL?.toLocaleLowerCase()
    );

    return {
      country: fetchedCountryInfo?.name,
      countryCode: fetchedCountryInfo?.code
    };
  };

  let senderCountryInfoFromURL;
  let receiverCountryInfoFromURL;

  switch (shippingLocation) {
    case 'us':
      senderCountryInfoFromURL = getCountryCode((senderFromURL = 'US'));
      receiverCountryInfoFromURL = getCountryCode(receiverFromURL);
      break;
    case 'uk':
      senderCountryInfoFromURL = getCountryCode((senderFromURL = 'GB'));
      receiverCountryInfoFromURL = getCountryCode(receiverFromURL);
      break;
    case 'gb':
      senderCountryInfoFromURL = getCountryCode((senderFromURL = 'GB'));
      receiverCountryInfoFromURL = getCountryCode(receiverFromURL);
      break;
    case 'ng':
      senderCountryInfoFromURL = getCountryCode(senderFromURL);
      receiverCountryInfoFromURL = getCountryCode(receiverFromURL);
      break;
    case 'local-delivery':
      senderCountryInfoFromURL = getCountryCode(senderFromURL);
      receiverCountryInfoFromURL = getCountryCode((receiverFromURL = 'NG'));
      break;
    case 'lagos':
      senderCountryInfoFromURL = getCountryCode(senderFromURL);
      receiverCountryInfoFromURL = getCountryCode(receiverFromURL);
      break;
    case 'abuja':
      senderCountryInfoFromURL = getCountryCode(senderFromURL);
      receiverCountryInfoFromURL = getCountryCode(receiverFromURL);
      break;
    case 'ibadan':
      senderCountryInfoFromURL = getCountryCode(senderFromURL);
      receiverCountryInfoFromURL = getCountryCode(receiverFromURL);
      break;
  }

  return {
    sender: { ...senderCountryInfoFromURL },
    receiver: { ...receiverCountryInfoFromURL }
  };
};

export default getShippingLocationInfo;
