import React from 'react';

const SubmitButton = ({
  loading = false,
  text = '',
  onClick = () => {},
  disabled = false,
  className = '',
  ...rest
}) => {
  return (
    <button
      {...rest}
      type='submit'
      className={`submit-button ${disabled && 'disable-element'} ${className} ${
        loading && 'loading'
      }`}
      disabled={loading || disabled}
      onClick={onClick}
    >
      <span>{text}</span>
    </button>
  );
};

export default SubmitButton;
