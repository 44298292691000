import { YellowInfo } from './asset-constants';
import { chinaListNotice, shipmentNotice } from './exports-lists';

export const TermsAndConditions = ({ isChina = false }) => {
  return (
    <section className='exports'>
      <div className='exports__terms-condition'>
        <h2 className='title'>
          Booking a {isChina ? 'China Imports' : 'shipment'}? Keep these in mind
        </h2>

        {isChina ? (
          <div className='china-conditions'>
            {chinaListNotice.map((shipment, index) => {
              return (
                <div className='condition-item'>
                  <span className='m-0 index'>{index + 1}</span>
                  <div>
                    <p
                      style={{
                        color: '#545859'
                      }}
                      className='item-text'
                    >
                      {shipment.content}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className='conditions'>
            {shipmentNotice.map(shipment => {
              return (
                <div className='condition-item'>
                  <img src={shipment.icon} alt='' />
                  <div>
                    <p className='item-title'>{shipment.title}</p>
                    <p className='item-text'>{shipment.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className='cancellation'>
          <img src={YellowInfo} alt='' />
          <p>
            <b>Cancellation Policy: </b>
            Please be aware that we have a 14-day cancellation policy for all
            shipments that have been booked and paid for. If you wish to cancel
            your shipment, please ensure that you do so within 14 days of the
            booking date.
          </p>
        </div>
      </div>
    </section>
  );
};
