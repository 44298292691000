import { ArrowUp } from '../../constants/asset-constants';

export const ViewPrices = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'view-prices' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>What are your prices?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'view-prices' ? 'show' : 'hide'}`}>
        <p>
          Use the price calculator on our{' '}
          <a
            target='_blank'
            rel='noreferrer'
            href='https://ship.topship.africa'
          >
            website
          </a>{' '}
          or app.
        </p>
      </div>
    </div>
  );
};

export const ShippingPrices = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${
        activeArticle === 'shipping-price' ? 'active' : ''
      }`}
    >
      <div className='flexed-heading'>
        <h2>
          The prices on the Topship web app are different from what was
          advertised. Why is that?
        </h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div
        className={`${activeArticle === 'shipping-price' ? 'show' : 'hide'}`}
      >
        <p>
          Shipping costs vary depending on item weight and destination address.
          Heavier items and remote locations may attract higher charges.
        </p>
      </div>
    </div>
  );
};

export const CustomsDuty = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'customs-duty' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>How much should I budget for Customs Clearance?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'customs-duty' ? 'show' : 'hide'}`}>
        {/* <p>Customs Duty are</p> */}
        <ul>
          <li>
            Customs clearance can be unpredictable as they are at the discretion
            of customs officials and import regulations in the recipient’s
            country. However, the charge depends on the declared value of your
            package and is typically 30% - 50% of the declared value.
          </li>
          <li>
            Please note that declaring a lower value than your item is worth
            could attract an additional fine from customs officials.
          </li>
        </ul>
        <p>
          To waive this charge, consider shipping with Topship’s Budget service
          (export) or Shop and Ship service (import).
        </p>
      </div>
    </div>
  );
};

// export const CargoShipping = ({ activeArticle, handleToggle }: any) => {
//   return (
//     <div
//       onClick={handleToggle}
//       className={`content ${
//         activeArticle === 'cargo-shipping' ? 'active' : ''
//       }`}
//     >
//       <div className='flexed-heading'>
//         <h2>What is the price of cargo shipping?</h2>
//         <img
//           style={{
//             height: '10px',
//             width: '10px'
//           }}
//           src={ArrowUp}
//           alt='accordion toggle button'
//         />
//       </div>
//       <div
//         className={`${activeArticle === 'cargo-shipping' ? 'show' : 'hide'}`}
//       >
//         <p>
//           Cargo shipping is N3,000 per kg to the USA and N3,500 to Canada,
//           exclusive of customs clearance and last-mile delivery fees. While it
//           is N5,000 to the UK, inclusive of customs clearance and last-mile
//           delivery fees.
//         </p>
//       </div>
//     </div>
//   );
// };
