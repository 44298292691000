import React from "react";
import footerLogo from "../assets/svgs/white-logo.svg";
import facebookLogo from "../assets/images/facebook-logo.png";
import instagramLogo from "../assets/images/instagram-icon.png";
import twitterLogo from "../assets/images/twitter-icon.png";
import youtubeLogo from "../assets/images/youtube-icon.png";
import linkedinLogo from "../assets/images/linkedin-icon.png";
// import { Link } from "react-router-dom";

const Footer = () => {
  // const [showFaq, setShowFaq] = useState("default");

  return (
    <div className="footer">
      <div className="footer__left">
        <div className="footer__left__logo">
          <img src={footerLogo} alt="logo" />
        </div>

        <div className="footer__left__contact">
          <p>hello@topship.africa</p> <p>+234-908-077-7728</p>
        </div>

        <div className="footer__left__socials">
          <div className="footer__left__socials__icon footer__left__socials__icon--facebook">
            <a href="https://www.facebook.com/topshipafrica">
              <img src={facebookLogo} alt="facebook" />
            </a>
          </div>
          <div className="footer__left__socials__icon">
            <a href="https://instagram.com/topshipafrica">
              <img src={instagramLogo} alt="instagram" />
            </a>
          </div>
          <div className="footer__left__socials__icon">
            <a href="https://twitter.com/topship_africa">
              <img src={twitterLogo} alt="twitter" />
            </a>
          </div>
          <div className="footer__left__socials__icon">
            <a href="https://www.youtube.com/channel/UC8q9ckTRsIfnE1srJOWPnug">
              <img src={youtubeLogo} alt="youtube" />
            </a>
          </div>
          <div className="footer__left__socials__icon">
            <a href="https://www.linkedin.com/company/topshipafrica/">
              <img src={linkedinLogo} alt="linkedin" />
            </a>
          </div>
        </div>
      </div>

      <div className="footer__right">
        {/* <div className="footer__right__group">
          <h3 className="footer__right__title">Topship HQ (Main Office)</h3>
          <p className="footer__right__sub-title">
            268 Herbert Macaulay Way, Yaba, Lagos
          </p>
        </div> */}

        <div className="footer__right__group">
          <h3 className="footer__right__title">Lagos Island Hub</h3>
          <p className="footer__right__sub-title">
            25B Bisola Durosinmi Etti Drive Lekki Phase 1
          </p>
        </div>

        <div className="footer__right__group">
          <h3 className="footer__right__title">Lagos Mainland Hub</h3>
          <p className="footer__right__sub-title">
            3 Dapo Bode Thomas Street, Sabo, Yaba
          </p>
        </div>

        <div className="footer__right__group ">
          <p className="footer__right__sub-title">
            <a href="https://ship.topship.africa/terms">
              <strong>Terms of Service</strong>
            </a>
          </p>

          <p className="footer__right__sub-title">
            <a href="https://intercom.help/topship/en/">
              <strong>FAQ</strong>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
