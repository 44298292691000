import { atom } from "recoil";

const busyOverlayState = atom({
  key: "busyOverlayState",
  default: false,
});

const faqOverlayState = atom({
  key: "faqOverlayState",
  default: "default",
});

const saveShipmentRateState = atom({
  key: "saveShipmentRate",
  default: [],
});

const localAndExportsQuotesLocationDataState = atom({
  key: "localAndExportsQuotesLocationDataState",
  default: {
    sender: {
      countries: [],
      states: [],
      cities: [],
    },
    receiver: {
      countries: [],
      states: [],
      cities: [],
    },
  },
});

const quotesAddressesState = atom({
  key: "quotesAddressesState",
  default: {
    receiver: {},
    sender: {},
  },
});

const quotesWeight = atom({
  key: "quotesWeight",
  default: null,
});

const quotesLocationDataState = atom({
  key: "quotesLocationDataState",
  default: {
    sender: {
      countries: [],
      states: [],
      cities: [],
    },
    receiver: {
      countries: [],
      states: [],
      cities: [],
    },
  },
});

const quoteRequestState = atom({
  key: "quoteRequestState",
  default: 0,
});

const localAndExportsQuotesSenderAddress = atom({
  key: "localAndExportsQuotesSenderAddress",
  default: {},
});
const localAndExportsQuotesReceiverAddress = atom({
  key: "localAndExportsQuotesReceiverAddress",
  default: {},
});

const localAndExportsQuotesAddressesState = atom({
  key: "localAndExportsQuotesAddressesState",
  default: {
    receiver: {},
    sender: {},
  },
});
const localAndExportsQuotesWeight = atom({
  key: "localAndExportsQuotesWeight",
  default: null,
});

const senderDropDown = atom({
  key: "senderDropDown",
  default: false,
});

const receiverDropDown = atom({
  key: "receiverDropDown",
  default: false,
});

export {
  busyOverlayState,
  faqOverlayState,
  saveShipmentRateState,
  localAndExportsQuotesLocationDataState,
  localAndExportsQuotesSenderAddress,
  localAndExportsQuotesReceiverAddress,
  localAndExportsQuotesAddressesState,
  localAndExportsQuotesWeight,
  quotesLocationDataState,
  quotesAddressesState,
  quotesWeight,
  quoteRequestState,
  senderDropDown,
  receiverDropDown,
};
