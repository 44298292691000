import { ArrowUp } from '../../constants/asset-constants';

export const ChinaAddress = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'china-address' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>How do I access your address in China?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'china-address' ? 'show' : 'hide'}`}>
        <p>
          You will be responsible for shipping the goods to the provided China
          warehouse in Guangzhou. There are no pick-ups from your supplier.,
        </p>{' '}
      </div>
    </div>
  );
};

export const ChinaPickup = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'china-pickup' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>Who handles pick-up from my supplier in China?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'china-pickup' ? 'show' : 'hide'}`}>
        <p>
          You will be responsible for shipping the goods to the provided China
          warehouse in Guangzhou. There are no pick-ups from your supplier.
        </p>
      </div>
    </div>
  );
};

export const LagosPickup = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'lagos-pickup' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>Who handles delivery when it arrives in Lagos?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'lagos-pickup' ? 'show' : 'hide'}`}>
        <p>
          You will be responsible for delivery of your package. Additional
          charges will be determined once it lands in Lagos based on the final
          destination address.
        </p>
      </div>
    </div>
  );
};

export const CancelChina = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'cancel-china' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>If I cancel my order, how do you handle returns?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'cancel-china' ? 'show' : 'hide'}`}>
        <p>
          If, at any point, you decide not to proceed with shipping your
          package, you are responsible for covering the return delivery costs to
          their designated destination within China. Please note that you are
          unable to arrange pickups directly from the China warehouse.
        </p>
      </div>
    </div>
  );
};

export const ReturnFee = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'return-fee' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>How much is the return fee?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'return-fee' ? 'show' : 'hide'}`}>
        <p>
          If you no longer want to ship your package once it arrives at the
          China warehouse, please be aware that you are responsible for paying
          for the delivery back to the specified destination in China. You
          cannot make pickups from the China warehouse.
        </p>
        <p>
          The shipping price for returns starts at $3.00 for the first kg, while
          the remaining kg will be $.50 for each kg. The maximum you will be
          charged is $50.{' '}
        </p>
        <p>
          For example,
          <br />
          Returning a 1kg package to your supplier will cost $3. Returning a
          10kg package to your supplier will cost $7.50. Returning a 100kg
          package to your supplier will cost a maximum of $50.
        </p>
      </div>
    </div>
  );
};

export const Prohibited = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'prohibited' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>Are there any prohibited items on this service?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'prohibited' ? 'show' : 'hide'}`}>
        <p>
          At the moment, the following are prohibited: guns, military items, gas
          canister, chemicals and food items. Please reach out to our customer
          support team to clarify before shipping. Email hello@topship.africa.
        </p>
      </div>
    </div>
  );
};
