

const CustomLoader = () => {
  return (
    <div className="container">
      <div className="item item-1" />
      <div className="item item-2" />
      <div className="item item-3" />
      <div className="item item-4" />
    </div>
  );
};

export default CustomLoader;
