// import { ApolloClient } from 'apollo-client';
// import { InMemoryCache } from 'apollo-cache-inmemory';
// import { createUploadLink } from 'apollo-upload-client';
// import { ApolloLink } from 'apollo-link';
// import { useAuthToken } from '../token';
// import { shuffleToken } from '../authorization';

// import { environment } from '../config/environment';

// // just in case we need to upload files to our servers
// const link = new createUploadLink({ uri: process.env.REACT_APP_GRAPHQL_API });

// // this will append our token  on every request
// const authMiddleware = authToken =>
//   new ApolloLink((operation, forward) => {
//     if (authToken) {
//       operation.setContext({
//         headers: { authorization: `Bearer ${shuffleToken(authToken)}` }
//       });
//     }
//     return forward(operation);
//   });
// const cache = new InMemoryCache({});

// const useAppApolloClient = () => {
//   const [authToken] = useAuthToken();
//   return new ApolloClient({
//     link: authMiddleware(authToken).concat(link),
//     cache
//   });
// };
// export default useAppApolloClient;

import {
  ApolloClient,
  InMemoryCache,
} from "@apollo/client";

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache()
});

export default client;
