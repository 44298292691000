import React, { Fragment, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Home from "./pages/index";
// import AboutUs from "./pages/about-us";
// import Careers from "./pages/careers";
// import Jobs from "./pages/jobs";
// import Press from "./pages/press";
// import Services from "./pages/services";
// import logo from './logo.svg';
import NotFound from './pages/notfound';
import DeleteAccount from './pages/delete-account';
import About from './pages/about';
import Faq from './pages/faq';
import { usePageViews } from './utilities/usePageViews';
import './App.css';
import Blogs from './pages/blog';
import BlogDetail from './components/blog/blog-detail';
import ScrollTop from './utilities/scroll-top';
import NewHomePage from './pages/new-home-page';
import Navigation from './components/navigation';
import ApiDocumentation from './pages/api-documentation';
import Plugin from './pages/plugin';
import ShopNShipPage from './pages/shop-n-ship';
import GatewayPage from './pages/gateway';
import FaqArticle from './pages/faq-content';
import ExportsPage from './pages/exports';
import ChinaImports from './pages/china-imports';
import ImportsPage from './pages/imports';
import ShippingChecklistPage from './pages/shipping-checklist';
import ProhibitedItemsPage from './pages/prohibited-items';
import ShippingDocumentationPage from './pages/shipping-doc';
import NonServingCountryPage from './pages/non-serving-country';
import FrozenFoodsPage from './pages/frozen-foods';
import ResourcesPage from './pages/resources';
import BrokerDirectoryPage from './pages/broker-directory';
import SeaFreightPage from './pages/sea-freight';

// Create a client
const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    const domainRegExp = new RegExp('www');
    if (domainRegExp.test(window.location.origin)) {
      const domain =
        process.env.NODE_ENV === 'production'
          ? 'https://topship.africa'
          : 'http://localhost:3000';

      window.location.href = `${domain}${window.location.pathname}`;
    }
  }, []);
  usePageViews();
  return (
    <div className='App'>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <Fragment>
            <ScrollTop />
            <Navigation />
            <Switch>
              <Redirect
                from='/:url*(/+)'
                to={window.location.pathname.slice(0, -1)}
              />
              <Route exact path='/' component={NewHomePage}></Route>

              <Route exact path='/about' component={About}></Route>
              <Route exact path='/api' component={ApiDocumentation}></Route>
              <Route exact path='/plug-ins' component={Plugin}></Route>
              <Route
                exact
                path='/sea-freight'
                component={SeaFreightPage}
              ></Route>

              <Route path='/:sender/:receiver' component={NewHomePage}></Route>

              <Route path='/local-delivery' component={NewHomePage}></Route>
              <Route path='/faq-article' exact component={FaqArticle}></Route>
              <Route exact path='/faq' component={Faq}></Route>
              <Route exact path='/exports' component={ExportsPage}></Route>
              <Route exact path='/imports' component={ImportsPage}></Route>
              <Route exact path='/resources' component={ResourcesPage}></Route>
              <Route
                exact
                path='/broker-directory'
                component={BrokerDirectoryPage}
              ></Route>
              <Route
                exact
                path='/shipping-checklist'
                component={ShippingChecklistPage}
              ></Route>
              <Route
                exact
                path='/non-served-countries'
                component={NonServingCountryPage}
              ></Route>
              <Route
                exact
                path='/prohibited-items'
                component={ProhibitedItemsPage}
              ></Route>
              <Route
                exact
                path='/shipping-documentation'
                component={ShippingDocumentationPage}
              ></Route>
              <Route exact path='/blog' component={Blogs}></Route>
              <Route exact path='/gateway' component={GatewayPage}></Route>
              <Route
                exact
                path='/chinaimports'
                component={ChinaImports}
              ></Route>
              <Route
                exact
                path='/shop-&-ship'
                component={ShopNShipPage}
              ></Route>
              <Route
                exact
                path='/frozen-foods'
                component={FrozenFoodsPage}
              ></Route>
              <Route exact path='/blog/:slug' component={BlogDetail}></Route>

              <Route
                path='/account-delete'
                render={props => <DeleteAccount />}
              />

              {/* <Route exact path="/careers" component={Careers}></Route> */}

              {/* <Route exact path="/jobs" component={Jobs}></Route> */}

              {/* <Route exact path="/press" component={Press}></Route> */}

              {/* <Route exact path="/services" component={Services}></Route> */}

              {/* <Route exact path="/blog/:id" component={BlogDetail}></Route> */}
              <Route
                path='/404'
                render={props => <NotFound heading='Page not found' />}
              />

              <Route path='/*' component={NewHomePage}></Route>

              <Route render={props => <NotFound heading='Page not found' />} />
            </Switch>
          </Fragment>
        </RecoilRoot>
      </QueryClientProvider>
      <ToastContainer />
    </div>
  );
}

export default App;
