import { useState } from 'react';
import NewFooter from '../components/new-footer';
import GetMobileApp from '../components/new-home-page/get-mobile-app';
import {
  budgetUkLists,
  prohibitedExpressInter,
  prohibitedExpressNationwide,
  prohibitedSavers
} from '../constants/prohibited-lists';

const ProhibitedItemsPage = () => {
  const [currentService, setCurrentService] = useState('express');
  return (
    <>
      <div className='shipping-checklist'>
        <section className='shipping-checklist__prohibited-item'>
          <div className='intro-wrap'>
            <h2 className='heading'>Prohibited Items</h2>
            <p>
              The following items will not be accepted for transportation under
              any circumstances.
            </p>
            <div className='services-tab'>
              <span
                className={`service ${
                  currentService === 'express' ? 'active' : ''
                }`}
                onClick={() => setCurrentService('express')}
              >
                Express
              </span>
              <span
                className={`service ${
                  currentService === 'saver' ? 'active' : ''
                }`}
                onClick={() => setCurrentService('saver')}
              >
                Saver
              </span>
              <span
                className={`service ${
                  currentService === 'budget' ? 'active' : ''
                }`}
                onClick={() => setCurrentService('budget')}
              >
                Budget
              </span>
            </div>
            {currentService === 'express' && (
              <div>
                <div className='mt-5 list-group'>
                  <h3
                    className='mb-6 '
                    style={{
                      borderBottom: '1px solid #e2e4e8',
                      paddingBottom: '20px'
                    }}
                  >
                    Express - Nationwide
                  </h3>
                  <div className='list-container'>
                    <ul className='' style={{ flex: '1' }}>
                      {prohibitedExpressNationwide
                        .slice(
                          0,
                          Math.ceil(prohibitedExpressNationwide.length / 2)
                        )
                        .map((list, index) => {
                          return (
                            <li key={index} className='list'>
                              <div>{list}</div>
                            </li>
                          );
                        })}
                    </ul>
                    <ul className='' style={{ flex: '1' }}>
                      {prohibitedExpressNationwide
                        .slice(
                          Math.ceil(prohibitedExpressNationwide.length / 2)
                        )
                        .map((list, index) => {
                          return (
                            <li key={index} className='list'>
                              <div>{list}</div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className='mt-10 list-group'>
                  <h3
                    className='mb-6 '
                    style={{
                      borderBottom: '1px solid #e2e4e8',
                      paddingBottom: '20px'
                    }}
                  >
                    Express - International
                  </h3>
                  <div className='list-container'>
                    <ul className='' style={{ flex: '1' }}>
                      {prohibitedExpressInter
                        .slice(0, Math.ceil(prohibitedExpressInter.length / 2))
                        .map((list, index) => {
                          return (
                            <li key={index} className='list'>
                              <div>{list}</div>
                            </li>
                          );
                        })}
                    </ul>
                    <ul className='' style={{ flex: '1' }}>
                      {prohibitedExpressInter
                        .slice(Math.ceil(prohibitedExpressInter.length / 2))
                        .map((list, index) => {
                          return (
                            <li key={index} className='list'>
                              <div>{list}</div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {currentService === 'saver' && (
              <div>
                <div className='mt-5 list-group'>
                  <h3
                    className='mb-6 '
                    style={{
                      borderBottom: '1px solid #e2e4e8',
                      paddingBottom: '20px'
                    }}
                  >
                    Saver
                  </h3>
                  <div className='list-container'>
                    <ul className='' style={{ flex: '1' }}>
                      {prohibitedSavers
                        .slice(0, Math.ceil(prohibitedSavers.length / 2))
                        .map((list, index) => {
                          return (
                            <li key={index} className='list'>
                              <div>{list}</div>
                            </li>
                          );
                        })}
                    </ul>
                    <ul className='' style={{ flex: '1' }}>
                      {prohibitedSavers
                        .slice(Math.ceil(prohibitedSavers.length / 2))
                        .map((list, index) => {
                          return (
                            <li key={index} className='list'>
                              <div>{list}</div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {currentService === 'budget' && (
              <div>
                <div className='mt-5 list-group'>
                  <h3
                    className='mb-6 '
                    style={{
                      borderBottom: '1px solid #e2e4e8',
                      paddingBottom: '20px'
                    }}
                  >
                    Budget - UK 🇬🇧
                  </h3>
                  <div className='list-container'>
                    <ul className='' style={{ flex: '1' }}>
                      {budgetUkLists
                        .slice(0, Math.ceil(budgetUkLists.length / 2))
                        .map((list, index) => {
                          return (
                            <li key={index} className='list'>
                              <div>{list}</div>
                            </li>
                          );
                        })}
                    </ul>
                    <ul className='' style={{ flex: '1' }}>
                      {budgetUkLists
                        .slice(Math.ceil(budgetUkLists.length / 2))
                        .map((list, index) => {
                          return (
                            <li key={index} className='list'>
                              <div>{list}</div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>

                <div className='mt-10 list-group'>
                  <h3
                    className='mb-6 '
                    style={{
                      borderBottom: '1px solid #e2e4e8',
                      paddingBottom: '20px'
                    }}
                  >
                    Budget - USA 🇺🇸
                  </h3>
                  <div className='list-container'>
                    <ul className='' style={{ flex: '1' }}>
                      {budgetUkLists
                        .slice(0, Math.ceil(budgetUkLists.length / 2))
                        .map((list, index) => {
                          return (
                            <li key={index} className='list'>
                              <div>{list}</div>
                            </li>
                          );
                        })}
                    </ul>
                    <ul className='' style={{ flex: '1' }}>
                      {budgetUkLists
                        .slice(Math.ceil(budgetUkLists.length / 2))
                        .map((list, index) => {
                          return (
                            <li key={index} className='list'>
                              <div>{list}</div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className='mt-10 list-group'>
                  <h3
                    className='mb-6 '
                    style={{
                      borderBottom: '1px solid #e2e4e8',
                      paddingBottom: '20px'
                    }}
                  >
                    Budget - CANADA 🇨🇦
                  </h3>
                  <div className='list-container'>
                    <ul className='' style={{ flex: '1' }}>
                      {budgetUkLists
                        .slice(0, Math.ceil(budgetUkLists.length / 2))
                        .map((list, index) => {
                          return (
                            <li key={index} className='list'>
                              <div>{list}</div>
                            </li>
                          );
                        })}
                    </ul>
                    <ul className='' style={{ flex: '1' }}>
                      {budgetUkLists
                        .slice(Math.ceil(budgetUkLists.length / 2))
                        .map((list, index) => {
                          return (
                            <li key={index} className='list'>
                              <div>{list}</div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>

        <GetMobileApp />
        <section className='new-home-page__footer'>
          <NewFooter />
        </section>
      </div>
    </>
  );
};

export default ProhibitedItemsPage;
