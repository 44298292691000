import React, { useState } from 'react';
import { ArrowUp, FaqIcon } from '../constants/asset-constants';

interface FaqBlockProps {
  style?: any;
  questions: {
    question: string;
    expand: boolean;
    answer: JSX.Element | string;
    id?: string;
  }[];
}

const FaqBlock: React.FC<FaqBlockProps> = ({ style, questions = [] }) => {
  const [faqs, setFaqs] = useState(questions);

  const handleToggleAccordion = (faqIndex: number) => {
    const expandedFaq = questions.map((faq, index) => {
      if (faqIndex === index) {
        faq.expand = !faq.expand;
      } else {
        faq.expand = false;
      }
      return faq;
    });

    setFaqs(expandedFaq);
  };

  return (
    <div style={style} className='faq-content'>
      <img className='img' src={FaqIcon} alt='' />
      <h2>Frequently Asked Questions</h2>
      <div className='questions-wrap'>
        <div className='faq-items'>
          {faqs.map((faq, index) => (
            <div
              id={faq.id}
              onClick={() => handleToggleAccordion(index)}
              key={index}
              className={`mb-4 faq-item ${faq.expand ? 'chosen' : ''}`}
            >
              <div className='faq-item-header'>
                <h5>{faq.question}</h5>
                <img
                  className='faq-item-accordion-button'
                  src={ArrowUp}
                  style={{
                    transform: faq.expand ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease-in-out'
                  }}
                  alt='accordion toggle button'
                />
              </div>
              <p className={faq.expand ? 'show' : 'hide'}>{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqBlock;
