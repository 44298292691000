import { ArrowUp } from '../../constants/asset-constants';

export const PartnerAddress = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${
        activeArticle === 'partner-address' ? 'active' : ''
      }`}
    >
      <div className='flexed-heading'>
        <h2>How do i get your UK/US/Canada address?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div
        className={`${activeArticle === 'partner-address' ? 'show' : 'hide'}`}
      >
        <p>
          Our partner addresses can be found on the Topship app. Sign in, go to
          Shop & Ship, select the country you are shopping from and follow the
          instructions listed on the page.
        </p>{' '}
      </div>
    </div>
  );
};

export const DeliveryTime = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'delivery-time' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>How long does delivery take?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'delivery-time' ? 'show' : 'hide'}`}>
        <p>
          Delivery takes 7-14 working days. Packages are shipped out on Fridays
          and the delivery timeline will begin the Friday after your package has
          been delivered to our Canada, UK or USA partner hubs.
        </p>
      </div>
    </div>
  );
};

export const DeliveryDelay = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${
        activeArticle === 'delivery-delay' ? 'active' : ''
      }`}
    >
      <div className='flexed-heading'>
        <h2>Why is my delivery taking longer than 7-14 days?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div
        className={`${activeArticle === 'delivery-delay' ? 'show' : 'hide'}`}
      >
        <p>
          We ship once a week on Fridays and your delivery timeline will begin
          on the Friday after your package is delivered. Also, peculiar items
          like drones, pure gold or precious metals may require longer customs
          processing times.
        </p>
      </div>
    </div>
  );
};

export const OurRates = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'our-rates' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>What are your rates?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'our-rates' ? 'show' : 'hide'}`}>
        <p>
          Phones, smart watches, tablets and laptops cost $20 or £20 per kg,
          while fashion items, packaged dry foods and other items cost $15 or
          £10 per kg (inclusive of customs fees and doorstep delivery in Lagos).
          Delivery to cities outside Lagos will attract an additional delivery
          charge.
        </p>
      </div>
    </div>
  );
};

export const OtherFees = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'other-fees' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>Do I have to pay any other fee?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'other-fees' ? 'show' : 'hide'}`}>
        <p>
          No, you do not. Our prices are inclusive of customs duties and
          doorstep delivery to Lagos. However, if you live outside Lagos, you’ll
          have to either arrange for the item to be picked up or pay an extra
          fee to have it delivered to you.
        </p>
      </div>
    </div>
  );
};
export const ProofOfId = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'proof-of-id' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>Why do you need my ID and Proof of purchase?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'proof-of-id' ? 'show' : 'hide'}`}>
        <p>
          This is in line with our fraud protection policy. It helps us run the
          Shop & Shop service in line with law enforcement requirements. We only
          require you to upload your proof of identification (a
          government-issued ID) and clear evidence of payment (bank statement or
          a transaction receipt from your bank). If you used another person’s
          card for shopping and shipping, please provide the person’s ID and
          evidence of payment.
        </p>
      </div>
    </div>
  );
};
