import React, { useCallback, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import {
  ProhibitedItems,
  VolumetricWeight,
  PricingInfo
} from '../components/faq-articles/deliveries';
import {
  AllowedFoodstuffs,
  ImportLocation,
  ProhibitedFoodstuffs
} from '../components/faq-articles/foodstuffs';
import {
  AccountCreation,
  SetPricing,
  WhyGateway
} from '../components/faq-articles/gateway';
import {
  AccountType,
  GetPricing,
  GettingStartedFaq,
  VideoGuide,
  WhyTopship
} from '../components/faq-articles/get-started';
import {
  CustomsDuty,
  ShippingPrices
} from '../components/faq-articles/shipping-cost';
import GetMobileApp from '../components/new-home-page/get-mobile-app';
import SearchInput from '../components/search-component';
import { JetCircleBlue } from '../constants/asset-constants';
import { searchFaqs } from '../constants/faqs';
import { Article } from './faq';
import {
  CustomsDutyBudget,
  CustomsDutyExport
} from '../components/faq-articles/customs-duty';
import {
  DeliveryDelay,
  DeliveryTime,
  OtherFees,
  OurRates,
  PartnerAddress,
  ProofOfId
} from '../components/faq-articles/shop-n-ship';
import {
  BudgetAndCargo,
  DoorstepDeliveryExport,
  MinimumWeight,
  ShippingTimeExport
} from '../components/faq-articles/exports';
import {
  DoorstepDelivery,
  PickupForMe,
  ShippingTime
} from '../components/faq-articles/imports';
import {
  CancelChina,
  ChinaAddress,
  ChinaPickup,
  LagosPickup,
  Prohibited,
  ReturnFee
} from '../components/faq-articles/china-import';

const FaqArticle = () => {
  const location: {
    state: { title: string; textContent: string; articleNumber: number };
  } = useLocation();
  const [article, setArticle] = useState(location.state);
  const [activeArticle, setActiveArticle] = useState('');
  const [searchText, setSearchText] = useState('');
  const [showFilter, setShowFilter] = useState(false);

  const [filteredLists, setFilteredLists] = useState<Article>([]);
  const handleSearchChange = (e: any) => {
    const query = e.target.value;
    setSearchText(query);
    if (query.trim() === '') {
      setArticle(location.state);
    } else {
      const result = searchFaqs(query);
      if (result.length > 0) {
        setShowFilter(true);
        setFilteredLists(result);
      }
      setSearchText(query);
    }
  };
  const handleToggleArticle = (current: string) => {
    if (current === activeArticle) {
      setActiveArticle('');
    } else {
      setActiveArticle(current);
    }
  };

  const request = debounce(query => {
    if (query.trim() === '') {
      setArticle(location.state);
    } else {
      const result = searchFaqs(query);
      if (result.length > 0) {
        setShowFilter(true);
        setFilteredLists(result);
      }
    }
  }, 1000);

  const handleOpenPricingOptions = () => {
    setArticle(article => ({
      ...article,
      title: 'Shipping Options & Considerations'
    }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouceRequest = useCallback(value => request(value), []);

  return (
    <div className='new-faq'>
      <section className='new-faq__wrapper'>
        <div className='new-faq__header'>
          <p className='sub-heading'>Topship Help Center</p>
          <div className=''>
            <SearchInput
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              setArticleTitle={setArticle}
              searchData={filteredLists}
              value={searchText}
              onChange={(e: any) => {
                debouceRequest(e.target.value);
                setSearchText(e.target.value);
              }}
              onSearch={handleSearchChange}
            />
          </div>
        </div>
      </section>

      <section className='new-faq__article-body'>
        <div className='page-title'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Link to='/faq'>
              <p className='prev-link'>All Collections</p>
            </Link>
            <div className='icon'>&gt;</div>
          </div>
          <p className='current-link'>{article.title}</p>
        </div>
        <div className='article-heading'>
          <h2>{article.title}</h2>
          <p>{article.textContent}</p>
          <div className='footer-span'>
            <img src={JetCircleBlue} alt='' />
            <span>
              By Blessing <strong className='inline-dot'> . </strong>
              {article.articleNumber} Articles
            </span>
          </div>
        </div>
        <div className='article-container'>
          {article.title === 'Getting started on Topship (video guides)' && (
            <div className='accordion-wrapper'>
              <GettingStartedFaq
                handleToggle={() => handleToggleArticle('get-started')}
                activeArticle={activeArticle}
              />
              <GetPricing
                handleToggle={() => handleToggleArticle('get-pricing')}
                activeArticle={activeArticle}
              />
              <VideoGuide
                handleToggle={() => handleToggleArticle('video-guide')}
                activeArticle={activeArticle}
              />
              <AccountType
                handleToggle={() => handleToggleArticle('account-type')}
                activeArticle={activeArticle}
              />
              <WhyTopship
                handleOpenPricing={handleOpenPricingOptions}
                handleToggle={() => handleToggleArticle('why-topship')}
                activeArticle={activeArticle}
              />
            </div>
          )}
          {article?.title === 'Shipping Options & Considerations' && (
            <div className='accordion-wrapper'>
              <PricingInfo
                handleToggle={() => {
                  handleToggleArticle('pricing-info');
                }}
                activeArticle={activeArticle}
              />

              <VolumetricWeight
                handleToggle={() => handleToggleArticle('volumetric-weight')}
                activeArticle={activeArticle}
              />
              <ProhibitedItems
                handleToggle={() => handleToggleArticle('prohibited-lists')}
                activeArticle={activeArticle}
              />
            </div>
          )}
          {article?.title === 'Shipping Costs' && (
            <div className='accordion-wrapper'>
              <GetPricing
                handleToggle={() => handleToggleArticle('get-pricing')}
                activeArticle={activeArticle}
              />
              <ShippingPrices
                handleToggle={() => handleToggleArticle('shipping-price')}
                activeArticle={activeArticle}
              />
              <CustomsDuty
                handleToggle={() => handleToggleArticle('customs-duty')}
                activeArticle={activeArticle}
              />
            </div>
          )}
          {article?.title.includes('foodstuff') && (
            <div className='accordion-wrapper'>
              <ProhibitedFoodstuffs
                handleToggle={() =>
                  handleToggleArticle('prohibited-foodstuffs')
                }
                activeArticle={activeArticle}
              />
              <AllowedFoodstuffs
                handleToggle={() => handleToggleArticle('allowed-foodstuffs')}
                activeArticle={activeArticle}
              />
              <ImportLocation
                handleToggle={() => handleToggleArticle('import-location')}
                activeArticle={activeArticle}
              />
            </div>
          )}
          {article?.title ===
            'Getting started as a Logistics Business on Topship (GateWay by Topship)' && (
            <div className='accordion-wrapper'>
              <AccountCreation
                handleToggle={() => handleToggleArticle('account-creation')}
                activeArticle={activeArticle}
              />
              <SetPricing
                handleToggle={() => handleToggleArticle('set-pricing')}
                activeArticle={activeArticle}
              />
              <WhyGateway
                handleToggle={() => handleToggleArticle('why-gateway')}
                activeArticle={activeArticle}
              />
            </div>
          )}
          {article?.title.includes('Customs Clearance Fee') && (
            <div className='accordion-wrapper'>
              <CustomsDutyBudget
                handleToggle={() => handleToggleArticle('customs-budget')}
                activeArticle={activeArticle}
              />
              <CustomsDutyExport
                handleToggle={() => handleToggleArticle('customs-export')}
                activeArticle={activeArticle}
              />
            </div>
          )}
          {article?.title.includes('Exports') && (
            <div className='accordion-wrapper'>
              <ShippingTimeExport
                handleToggle={() => handleToggleArticle('shipping-time-export')}
                activeArticle={activeArticle}
              />

              <MinimumWeight
                handleToggle={() => handleToggleArticle('minimum-weight')}
                activeArticle={activeArticle}
              />
              <BudgetAndCargo
                handleToggle={() => handleToggleArticle('budget-cargo')}
                activeArticle={activeArticle}
              />

              <DoorstepDeliveryExport
                handleToggle={() =>
                  handleToggleArticle('doorstep-delivery-export')
                }
                activeArticle={activeArticle}
              />
            </div>
          )}{' '}
          {article?.title === 'Imports' && (
            <div className='accordion-wrapper'>
              <ShippingTime
                handleToggle={() => handleToggleArticle('shipping-time')}
                activeArticle={activeArticle}
              />
              <MinimumWeight
                handleToggle={() => handleToggleArticle('minimum-weight')}
                activeArticle={activeArticle}
              />
              <PickupForMe
                handleToggle={() => handleToggleArticle('pickup-for-me')}
                activeArticle={activeArticle}
              />
              <DoorstepDelivery
                handleToggle={() => handleToggleArticle('doorstep-delivery')}
                activeArticle={activeArticle}
              />
            </div>
          )}{' '}
          {article?.title.includes('Shop & Ship') && (
            <div className='accordion-wrapper'>
              <PartnerAddress
                handleToggle={() => handleToggleArticle('partner-address')}
                activeArticle={activeArticle}
              />
              <DeliveryTime
                handleToggle={() => handleToggleArticle('delivery-time')}
                activeArticle={activeArticle}
              />
              <DeliveryDelay
                handleToggle={() => handleToggleArticle('delivery-delay')}
                activeArticle={activeArticle}
              />{' '}
              <OurRates
                handleToggle={() => handleToggleArticle('our-rates')}
                activeArticle={activeArticle}
              />{' '}
              <OtherFees
                handleToggle={() => handleToggleArticle('other-fees')}
                activeArticle={activeArticle}
              />{' '}
              <ProofOfId
                handleToggle={() => handleToggleArticle('proof-of-id')}
                activeArticle={activeArticle}
              />{' '}
            </div>
          )}{' '}
          {article?.title === 'China Imports' && (
            <div className='accordion-wrapper'>
              <ChinaAddress
                handleToggle={() => handleToggleArticle('china-address')}
                activeArticle={activeArticle}
              />
              <ChinaPickup
                handleToggle={() => handleToggleArticle('china-pickup')}
                activeArticle={activeArticle}
              />
              <LagosPickup
                handleToggle={() => handleToggleArticle('lagos-pickup')}
                activeArticle={activeArticle}
              />
              <CancelChina
                handleToggle={() => handleToggleArticle('cancel-china')}
                activeArticle={activeArticle}
              />
              <ReturnFee
                handleToggle={() => handleToggleArticle('return-fee')}
                activeArticle={activeArticle}
              />
              <Prohibited
                handleToggle={() => handleToggleArticle('prohibited')}
                activeArticle={activeArticle}
              />
            </div>
          )}
        </div>
      </section>
      <GetMobileApp />
    </div>
  );
};

export default FaqArticle;
