import { ArrowUp, GetPricingImg } from '../../constants/asset-constants';
const downloadAppLink = 'https://onelink.to/jzcdu2';

export const GettingStartedFaq = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'get-started' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>Where do I begin?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'get-started' ? 'show' : 'hide'}`}>
        <p>
          Topship helps businesses and individuals ship Worldwide at flexible
          prices and with excellent customer service. We partner with vetted
          courier companies to provide imports, exports, domestic and
          international shipping services.
        </p>
        <p>
          To begin, you need to create an account. If you choose to use our web
          app, go to our{' '}
          <a
            target='_blank'
            rel='noreferrer'
            href='https://ship.topship.africa/signup'
          >
            signup page
          </a>{' '}
          and create an account that suits your needs.
        </p>
        <p>
          If you choose to use our mobile app,{' '}
          <a target='_blank' rel='noreferrer' href={downloadAppLink}>
            download the Topship App
          </a>{' '}
          to create your account and start shipping.
        </p>
        <p>
          Need more help? Here's a{' '}
          <a
            target='_blank'
            rel='noreferrer'
            href='https://www.youtube.com/playlist?list=PLQyw6_SoJ0v2PIjCAW_KOqGxcAL50gvNs'
          >
            step-by-step video guide
          </a>{' '}
          on how to use key features on Topship.
        </p>
      </div>
    </div>
  );
};

export const GetPricing = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'get-pricing' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>How much does it cost to ship?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'get-pricing' ? 'show' : 'hide'}`}>
        <img
          style={{
            objectFit: 'contain',
            width: '300px',
            marginTop: '20px'
          }}
          src={GetPricingImg}
          alt=''
        />
        <p>
          Take advantage of the price calculator on our{' '}
          <a target='_blank' rel='noreferrer' href='https://topship.africa/'>
            website
          </a>{' '}
          to get a delivery estimate. For specific remote locations, please
          reach out to customer support at hello@topship.africa.
        </p>
      </div>
    </div>
  );
};

export const VideoGuide = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'video-guide' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>Watch our step-by-step video guides</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'video-guide' ? 'show' : 'hide'}`}>
        <p>
          Watch our how-to videos on how to navigate the Topship web app and get
          the most out of our many features: {'  '}
          <a
            target='_blank'
            rel='noreferrer'
            href='https://www.youtube.com/playlist?list=PLQyw6_SoJ0v2PIjCAW_KOqGxcAL50gvNs'
          >
            Click here to watch
          </a>
        </p>
      </div>
    </div>
  );
};

export const AccountType = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'account-type' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>What type of account should I create?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'account-type' ? 'show' : 'hide'}`}>
        <p>
          <strong>Personal Account</strong> - For individuals looking to send or
          receive a package using a reliable app and delivery service.
        </p>
        <p>
          <strong>Business Account</strong> - For small, large, and growing
          businesses, create a business account to access discounts and special
          benefits for businesses.
        </p>
        <p>
          <strong>Logistics Company Account</strong> - (a.k.a GateWay by
          Topship) - For third-party logistics businesses and freight forwarding
          companies. Access tools and discounted pricing to help you grow and
          manage your logistics company.
        </p>
      </div>
    </div>
  );
};

export const WhyTopship = ({
  activeArticle,
  handleToggle,
  handleOpenPricing
}: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'why-topship' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>Why Topship?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'why-topship' ? 'show' : 'hide'}`}>
        <ul>
          <li>
            Send and receive packages from 150+ cities, including cities in
            Nigeria and across Africa
          </li>
          <li>Tap into our network of vetted courier and airline partners</li>
          <li>
            Flexible pricing that suits your budget{' '}
            <span
              style={{
                cursor: 'pointer',
                textDecoration: 'underline'
              }}
              onClick={handleOpenPricing}
            >
              (see our pricing options)
            </span>
          </li>
          <li>Real-time tracking for all shipments</li>
          <li>Get shipping estimates instantly</li>
          <li>
            Send packages from one location to multiple locations with the
            Multiple Destination feature on our apps
          </li>
          <li>
            Shop from international stores and ship to your home in Nigeria with
            our{' '}
            <a
              target={'_blank'}
              rel='noreferrer'
              href='https://topship.africa/shop-&-ship'
            >
              Shop&Ship
            </a>{' '}
            service
          </li>
          <li>
            Integrate global shipping into your e-commerce website with our{' '}
            <a
              target={'_blank'}
              rel='noreferrer'
              href='https://topship.africa/api'
            >
              API
            </a>{' '}
            and{' '}
            <a
              rel='noreferrer'
              target={'_blank'}
              href='https://form.typeform.com/to/klclH8qF'
            >
              Plug-in
            </a>{' '}
            , so much more! Get the Topship app today to enjoy these benefits
            and discover new ones for yourself!
          </li>
        </ul>
      </div>
    </div>
  );
};
