import { ArrowUp } from '../../constants/asset-constants';

export const CustomsDutyBudget = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${
        activeArticle === 'customs-budget' ? 'active' : ''
      }`}
    >
      <div className='flexed-heading'>
        <h2>How much should I budget for Customs Clearance?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div
        className={`${activeArticle === 'customs-budget' ? 'show' : 'hide'}`}
      >
        <p>
          Customs clearance can be unpredictable as they are at the discretion
          of customs officials and import regulations in the recipient’s
          country. However, the charge depends on the declared value of your
          package and is typically 30% - 50% of the declared value.
        </p>{' '}
        <p>
          Please note that declaring a lower value than your item is worth could
          attract an additional fine from customs officials.
        </p>
        <p>
          To waive this charge, consider shipping with Topship’s Budget service
          (export) or Shop and Ship service (import).
        </p>
      </div>
    </div>
  );
};

export const CustomsDutyExport = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${
        activeArticle === 'customs-export' ? 'active' : ''
      }`}
    >
      <div className='flexed-heading'>
        <h2>
          How do I import or export without paying customs clearance fees?
        </h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div
        className={`${activeArticle === 'customs-export' ? 'show' : 'hide'}`}
      >
        <p>
          Consider shipping with Topship’s Budget service (export) or Shop and
          Ship service (import).
        </p>
      </div>
    </div>
  );
};
