import React, { useState } from 'react';
import FaqBlock from '../components/faq-block';
import NewFooter from '../components/new-footer';
import GetMobileApp from '../components/new-home-page/get-mobile-app';
import Testimonials from '../components/testimonials';
import {
  CountryFlags,
  SafetyPin,
  SafetyPinMobile
} from '../constants/asset-constants';
import {
  deliveryBenefitsArray,
  packagesArray
} from '../constants/exports-lists';
import { exportsquestions } from '../constants/faqs';
import { TermsAndConditions } from '../constants/terms-&-condition';
import { CardComponent } from './imports';
import { ContactForm } from '../components/contact-form';
import { Helmet } from 'react-helmet';

const ExportsPage = () => {
  const [activeTab, setActiveTab] = useState('weighs-less');

  return (
    <>
      <Helmet>
        <title>Export Shipping from Nigeria with Topship Africa</title>
        <meta
          name='description'
          content='Export from Nigeria to over 150 cities worldwide with Topship. Ship abroad to family, friends, and customers. Enjoy the best shipping rates and fast delivery with Topship.'
        />
        <meta
          name='keywords'
          content='Topship, Export shipping services, export shipping, international shipping, best shipping rates, most affordable shipping, shipping cargo, express shipping, fast delivery, international delivery services'
        />
      </Helmet>
      <div className='exports'>
        <section className='exports__flexible-options'>
          <div className='intro-wrap'>
            <h2 className='heading'>
              <img className='safety-pin' src={SafetyPin} alt='' />
              <img className='safety-pin-mobile' src={SafetyPinMobile} alt='' />
            </h2>
            <p>
              Send and receive packages from Nigeria to over 150 cities with
              Topship.
            </p>
            <img src={CountryFlags} alt='country flags' />
            <div className='cta-group mt-5'>
              <a href='#contact-form' className='contact-support'>
                Contact Us
              </a>
            </div>
          </div>
        </section>
        <section className='exports__package-options'>
          <p
            className='title mb-3  hide-title'
            style={{
              textAlign: 'center',
              width: 'auto'
            }}
          >
            Package weighs
          </p>

          <div className='package-switch'>
            <div
              onClick={() => setActiveTab('weighs-less')}
              className={`package-switch-item ${
                activeTab === 'weighs-less' ? ' isActive' : ''
              }`}
            >
              less than 10kg
            </div>
            <div
              onClick={() => setActiveTab('weighs-more')}
              className={`package-switch-item ${
                activeTab === 'weighs-more' ? ' isActive' : ''
              }`}
            >
              more than 10kg
            </div>
          </div>
          <div className='web-version'>
            {activeTab === 'weighs-less' && (
              <div className='card-container'>
                {packagesArray
                  .filter(item => item.title !== 'Cargo' && !item.weighsMore)
                  .map(item => {
                    return <CardComponent data={item} />;
                  })}
              </div>
            )}
            {activeTab === 'weighs-more' && (
              <div className={`card-container isMore`}>
                {packagesArray
                  .filter(item => !item.weighsLess)

                  .map(item => {
                    return <CardComponent data={item} />;
                  })}
              </div>
            )}
          </div>
          <div className='mobile-version'>
            <div className='card-container'>
              <p className='label'>
                Package weighs <br /> less than 10kg
              </p>
              {packagesArray
                .filter(item => item.title !== 'Cargo' && !item.weighsMore)
                .map(item => {
                  return <CardComponent data={item} />;
                })}
            </div>

            <div className={`card-container`}>
              <p className='label'>
                Package weighs <br /> more than 10kg
              </p>

              {packagesArray
                .filter(item => !item.weighsLess)

                .map(item => {
                  return <CardComponent data={item} />;
                })}
            </div>
          </div>
        </section>
        <section className='exports__shipping-option'>
          <h2
            className='title'
            style={{
              textAlign: 'left'
            }}
          >
            Enjoy all of these and more on each shipping options
          </h2>
          <div className='benefit-wrap'>
            {deliveryBenefitsArray.map(benefit => {
              return (
                <div className='benefit-item'>
                  <img src={benefit.icon} alt='' />
                  <p>{benefit.content}</p>
                </div>
              );
            })}
          </div>
        </section>

        <TermsAndConditions />
        <Testimonials />
        <ContactForm serviceTitle={'Export'} />
        <FaqBlock questions={exportsquestions} />
        <GetMobileApp />
        <section className='new-home-page__footer'>
          <NewFooter />
        </section>
      </div>
    </>
  );
};

export default ExportsPage;
