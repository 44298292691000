import { FC } from "react";

import { Link } from "react-router-dom";
import ScrollTop from "../../utilities/scroll-top";
import BlogItem from "./blog-item";

interface Props {
  posts: Array<{}>;
  post: Array<{}>;
}

const Blogs: FC<Props> = ({ posts }): JSX.Element => {
  return (
    <div className="">
      <ScrollTop />
      {posts.map((post: any) => {
        return (
          <Link
            key={post.slug}
            className="no-link-styles"
            to={{
              pathname: `/blog/${post.slug}`,
              state: { post },
            }}
          >
            <BlogItem
              title={post.title.rendered.replace("&#8217;", "'")}
              description={
                post.yoast_head_json.og_description
                  ? post.yoast_head_json?.og_description
                  : post.yoast_head_json.description
              }
              date={post.date}
              author={post.yoast_head_json.twitter_misc["Written by"]}
              image={
                post?.yoast_head_json?.og_image === undefined
                  ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoBrkn-BRHxL0K7mN_7Kfbj8ptPaUhaqrrFw&usqp=CAU"
                  : post?.yoast_head_json?.og_image[0]?.url
              }
              post={post}
            />
          </Link>
        );
      })}
    </div>
  );
};

export default Blogs;
