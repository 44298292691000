import {
  CancelCheck,
  CustomerServiceIcon,
  Customs,
  InsuranceIcon,
  ItemValue,
  Label,
  LiveTrackingIcon,
  RatesIcon,
  Returns,
  SeaIcon1,
  SeaIcon2,
  SeaIcon3,
  SeaIcon4,
  SeaIcon5,
  SeaIcon6
} from './asset-constants';

const packagesArray = [
  {
    title: 'Budget',
    amount: 'NGN 64,000',
    tags: [
      'Delivery in 7 - 15 working days',
      'Doorstep Delivery',
      'Inclusive of customs clearance charges'
    ],
    isRecommended: true,
    weighsLess: true,
    additionalNote: `Best used for heavy packages`
  },
  {
    title: 'Budget',
    amount: 'NGN 17,000',
    tags: [
      'Delivery in 7 - 15 working days',
      'Doorstep Delivery',
      'Inclusive of customs clearance charges'
    ],
    isRecommended: true,
    weighsMore: true,
    additionalNote: `Best used for heavy packages.`
  },
  {
    title: 'Saver',
    amount: 'NGN 28,485',
    tags: [
      'Delivery in 7 - 10 working days',
      'Ships to 150+ cities worldwide (including cities in Nigeria)',
      'Doorstep Delivery',
      'Not inclusive of customs clearance charges'
    ],
    weighsLess: true
  },
  {
    title: 'Saver',
    amount: 'NGN 123,985',
    tags: [
      'Delivery in 7 - 10 working days',
      'Ships to 150+ cities worldwide (including cities in Nigeria)',
      'Doorstep Delivery',
      'Not inclusive of customs clearance charges'
    ],
    weighsMore: true
  },
  {
    title: 'Express',
    amount: 'NGN 55,655',
    tags: [
      'Delivery in 3 - 7 working days',
      'Ships to 150+ cities worldwide (including cities in Nigeria)',
      'Doorstep Delivery',
      'Not inclusive of customs clearance charges'
    ],
    weighsLess: true
  },
  {
    title: 'Express',
    amount: 'NGN 280,187',
    tags: [
      'Delivery in 3 - 7 working days',
      'Ships to 150+ cities worldwide (including cities in Nigeria)',
      'Doorstep Delivery',
      'Not inclusive of customs clearance charges'
    ],
    weighsMore: true
  }
];

const deliveryBenefitsArray = [
  {
    icon: CustomerServiceIcon,
    content: 'Reliable  and friendly customer support representatives'
  },
  {
    icon: RatesIcon,
    content: 'Affordable Rates'
  },
  {
    icon: LiveTrackingIcon,
    content: 'Live-tracking and notifications on shipments'
  },
  {
    icon: InsuranceIcon,
    content: 'Flexible insurance options'
  }
];

const chinaListNotice = [
  {
    content:
      'Please note that the standard 14-24 days delivery window will begin when your package is shipped out of our China partner Drop-off center'
  },
  {
    content: `All packages are shipped out of our Lagos Drop-off center. Delivery to thee receiver's address will be charged separately`
  }
];

const shipmentNotice = [
  {
    icon: Label,
    title: 'Proper Labelling',
    content: `If you selected a pick-up, label your package with your tracking
                number for easy identification and faster processing`
  },
  {
    icon: Customs,
    title: 'Customs Clearance Fees',
    content: `Most international shipments (exports or imports) will require
                you to pay customs clearance fees (usually 30 - 50% of item
                value). To avoid this fee, consider shipping with Topship's
                Budget service (exports) or Shop & Ship service (imports).`
  },
  {
    icon: Returns,
    title: 'Returns',
    content: `If we need to return your package due to the inability to pay
                customs fees or provide required shipping documents, an
                additional return fee will be charged to the sender.`
  },
  {
    icon: ItemValue,
    title: 'Declared Item Value',
    content: `Declaring a lower value than your item is worth could attract an
                additional fine from customs officials and does not necessarily
                reduce customs clearance charges. Please declare honestly.`
  }
];

const seaFreightExportList = [
  {
    country: 'UK',
    items: [
      {
        icon: SeaIcon1,
        item: (
          <div>
            <b>£4.00</b> per kg
          </div>
        )
      },
      {
        icon: SeaIcon6,
        item: <div>Price includes customs clearance charges</div>
      },
      {
        icon: SeaIcon5,
        item: <div>Doorstep delivery comes at an additional charge</div>
      },
      {
        icon: SeaIcon3,
        item: (
          <div>
            Minimum Weight of <b>100</b> kg
          </div>
        )
      },
      {
        icon: SeaIcon4,
        item: (
          <div>
            Items are shipped once a month and delivery timeline is 2 - 3 months
            from shipment date
          </div>
        )
      }
    ]
  },
  {
    country: 'USA',
    items: [
      {
        icon: SeaIcon1,
        item: (
          <div>
            <b>₦600</b> per kg
          </div>
        )
      },
      {
        icon: CancelCheck,
        item: <div>Price does NOT include customs clearance charges</div>
      },
      {
        icon: SeaIcon5,
        item: (
          <div>
            Doorstep delivery comes at an additional charge OR pick up from our
            partner warehouse in Dallas
          </div>
        )
      },
      {
        icon: SeaIcon2,
        item: (
          <div>
            Pick up from warehouse in New Jersey, Atlanta, Maryland and Dallas
          </div>
        )
      },
      {
        icon: SeaIcon3,
        item: (
          <div>
            Minimum Weight of <b>100</b> kg
          </div>
        )
      },
      {
        icon: SeaIcon4,
        item: (
          <div>
            Items are shipped once a month and delivery timeline is 2 - 3 months
            from shipment date
          </div>
        )
      }
    ]
  }
];

const seaFreightImportList = [
  {
    country: '',
    items: [
      {
        icon: SeaIcon1,
        item: <div>Request a price quote</div>
      },
      {
        icon: SeaIcon2,
        item: <div>No Minimum Weight</div>
      },
      {
        icon: SeaIcon3,
        item: (
          <div>Delivery timeline depends on pick up and drop off addresses</div>
        )
      },
      {
        icon: SeaIcon4,
        item: <div>Inclusive of customs clearance charges</div>
      },
      {
        icon: SeaIcon5,
        item: (
          <div>
            Doorstep delivery is included in price (unless stated otherwise)
          </div>
        )
      }
    ]
  }
];

export {
  seaFreightExportList,
  seaFreightImportList,
  deliveryBenefitsArray,
  packagesArray,
  shipmentNotice,
  chinaListNotice
};
