import { useState } from 'react';
import NewFooter from '../components/new-footer';
import { getTestimonials } from '../components/new-home-page/constants';
import {
  DeveloperImg1,
  DigitalProduct,
  Shipbubble,
  Nnamdi,
  Thrindle,
  EComm,
  OnlineRetail,
  CustomBranding,
  Wave,
  ReferBusiness,
  SlackSupport
} from '../constants/asset-constants';
import { Helmet } from 'react-helmet';

const ApiDocumentation = () => {
  const [testimonialInfo, settestimonialInfo] = useState({
    id: '1',
    description:
      '"Integrating Topship into our platform was surprisingly fast and easy. Their developer support team were hands-on, helping to resolve blockers both during and post-integration. The team is highly collaborative, technically savvy, and we have no complaints so far!"',
    author: 'Nnamdi Okoh - Co-Founder & CEO',
    company: 'Terminal Africa'
  });
  const [selectedTestimonial, setSelectedTestimonial] = useState(false);

  const toggleSelectedTestimonialIndicator = () => {
    setSelectedTestimonial(!selectedTestimonial);
  };

  const handleTestimonialInfo = (testimonial: any) => () => {
    settestimonialInfo(testimonial);
    toggleSelectedTestimonialIndicator();
  };

  const renderYoutubeImage = (index: number) => {
    switch (index) {
      case 0:
        return Nnamdi;
      case 1:
        return Thrindle;
      case 2:
        return Shipbubble;
      default:
        return Nnamdi;
    }
  };
  return (
    <>
      <Helmet>
        <title>Do e-commerce better with the Topship API</title>
        <meta
          name='description'
          content="Integrate Topship's global, door-to-door delivery into your WordPress, Shopify or WooCommerce website"
        />
        <meta
          name='keywords'
          content='Online shop, e-commerce, e-commerce business, API, Topship, digital product, online retailer, online marketplace'
        />
      </Helmet>
      <div className='api-documentation'>
        <section className='api-documentation__developers'>
          <div className='developers-content'>
            <div className='tag'>For Developers</div>
            <h1 className='header'>
              Integrate global, door-to-door delivery into your product
            </h1>
            <p>
              Integrate door-to-door local and international delivery into the
              user journey of your digital product. Give your customers the
              ability to ship items without leaving your app or website.
              <p>
                <b>For support {'&'} enquiries, email tech@topship.africa</b>
              </p>
            </p>
            <a
              rel='noreferrer'
              target='_blank'
              href='https://api-topship.com/shipping/docs#/'
              className='access-button'
            >
              Access Documentation
            </a>
          </div>

          <div className='image-container'>
            <img src={DeveloperImg1} alt='developer-img' />
          </div>
        </section>

        <section className='api-documentation__great-for'>
          <h1 className='header'>Great for </h1>

          <div className='card-div'>
            <div className='card-content'>
              <img src={DigitalProduct} alt='' />
              <h5>Digital Products</h5>
            </div>
            <div className='card-content'>
              <img src={EComm} alt='' />
              <h5>E-commerce brands</h5>
            </div>
            <div className='card-content'>
              <img src={OnlineRetail} alt='' />
              <h5>Online retailers and marketplaces</h5>
            </div>
          </div>
        </section>

        <section className='api-documentation__custom-branding'>
          <div className='image-container'>
            <img src={CustomBranding} alt='custom-img' />
          </div>

          <div className='custom-content'>
            <h1 className='header'>Custom Branding</h1>
            <p>
              Give your customers a consistent brand experience with branded
              shipment notifications sent from your company’s email.
            </p>
            <a
              rel='noreferrer'
              target='_blank'
              href='https://api-topship.com/shipping/docs#/'
              className='access-button'
            >
              Access Documentation
            </a>
          </div>
        </section>

        <section className='api-documentation__refer-business'>
          <div className='refer-content'>
            <h1 className='header'>Tracking and Visibility</h1>
            <p>
              Keep track of your deliveries with the Topship dashboard. Live
              status updates are sent to the dashboard daily.
            </p>
            <a
              rel='noreferrer'
              target='_blank'
              href='https://api-topship.com/shipping/docs#/'
              className='access-button'
            >
              Access Documentation
            </a>
          </div>

          <div className='image-container'>
            <img src={ReferBusiness} alt='refer-img' />
          </div>
        </section>

        <section className='api-documentation__slack-support'>
          <div className='image-container'>
            <img src={SlackSupport} alt='custom-img' />
          </div>

          <div className='support-content'>
            <h1 className='header'>Support</h1>
            <p>
              Dedicated Slack support channel for escalations and technical
              assistance. Get updates and responses from our operations and
              developer teams.
            </p>
            <a
              rel='noreferrer'
              target='_blank'
              href='https://api-topship.com/shipping/docs#/'
              className='access-button'
            >
              Access Documentation
            </a>
          </div>
        </section>

        <section className='new-home-page__testimonial'>
          <div className='homepage-container'>
            <div className='testimonial-contents'>
              <div className='testimonial-content-video'>
                <img
                  alt='testimonial author'
                  className='img-fluid testimonial-image'
                  src={renderYoutubeImage(
                    testimonialInfo
                      ? getTestimonials?.findIndex(
                          (testimonial: any) =>
                            testimonial.id === testimonialInfo?.id
                        )
                      : 0
                  )}
                />
              </div>

              <div className='testimonial-content-details'>
                <div className='testimonial-details'>
                  <p className='testimonial-caption'>
                    Trusted by Africa’s leading technology solution providers
                  </p>
                  <div className='testimonial-merchant-contents'>
                    <p className='details testimonial-description '>
                      {testimonialInfo.description}
                    </p>
                    <p className='testimonial-author'>
                      {testimonialInfo.author}
                    </p>
                    <p className='testimonial-company'>
                      {testimonialInfo.company}
                    </p>
                  </div>
                </div>

                <div className='testimonial-selectors'>
                  <div id='testimonial-carousel-selectors'>
                    {getTestimonials?.map(
                      (testimonial: any, testimonialIndex: any) => {
                        const upperIndex = testimonialInfo
                          ? getTestimonials.findIndex(
                              (t: any) => t.id === testimonialInfo.id
                            ) + 2
                          : 2;
                        return (
                          <div
                            key={testimonial.id}
                            className={
                              (!testimonialInfo && testimonialIndex === 0) ||
                              testimonialInfo?.id === testimonial.id
                                ? 'testimonial-carousel-selector'
                                : 'testimonial-carousel-not-selected'
                            }
                            style={{
                              opacity:
                                testimonialIndex >= upperIndex ? '0.2' : ''
                            }}
                            onClick={handleTestimonialInfo(testimonial)}
                          ></div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='api-documentation__ready'>
          <div className='ready-container'>
            <div className='ready-content'>
              <h1 className='header'>Ready to begin?</h1>
              <p>
                Integrate door-to-door and international delivery into the user
                journey of your digital product.
              </p>
              <a
                rel='noreferrer'
                target='_blank'
                href='https://api-topship.com/shipping/docs#/'
                className='access-button'
              >
                Access Documentation
              </a>
            </div>
          </div>

          <img src={Wave} alt='wavy icon' />
        </section>
      </div>
      <section className='new-home-page__footer'>
        <NewFooter />
      </section>
    </>
  );
};

export default ApiDocumentation;
