import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { toast } from "react-toastify";
import { useMutation as useReactQueryMutation } from "react-query";
import {
  GET_SHIPMENT_RATES,
  GET_COUNTRIES,
  GET_STATES,
  GET_CITIES_QUERY,
} from "./definitions/queries.def";
import { useRecoilState } from "recoil";
import {
  saveShipmentRateState,
  quotesLocationDataState,
} from "../recoil/atoms";
import { parseGraphqlError } from "../utilities/parse-graphql-error";
import { useSendAlert } from "../utilities/send-alert";

const useGetShipmentRateQuery = onCompleted => {
  const [, setCurrentRates] = useRecoilState(saveShipmentRateState);
  const [getRate, getShipmentRateResult] = useLazyQuery(GET_SHIPMENT_RATES, {
    onCompleted: (data) => {
      setCurrentRates(data.getShipmentRate);
      onCompleted && onCompleted(data);
    },
    onError: (error) => {
      // sendAlert(parseGraphqlError(error), 'error');
      toast.error(
        "The city selected is currently not shipped to, you could choose another city of close proximity or contact support"
      );
    },
  });

  const getShipmentRates = (shipmentDetail, destinationType) => {
    getRate({
      variables: {
        shipmentDetail,
      },
    });
  };

  return [getShipmentRates, getShipmentRateResult];
};

const useGetCountriesQuery = () => {
  return useQuery(GET_COUNTRIES, {
    onCompleted: (data) => {},
  });
};

const useGetCountriesLazyQuery = (locationType, shipmentRoute) => {
  const [getCountries, countriesResult] = useLazyQuery(GET_COUNTRIES, {
    onCompleted: (data) => {},
  });

  const getCountriesList = () => {
    getCountries();
  };

  return [getCountriesList, countriesResult];
};

const useGetStatesQuery = (locationType, locationDataState) => {
  const [quotesLocation, setQuotesLocation] = useRecoilState(
    locationDataState || quotesLocationDataState
  );
  const [getStates, statesResult] = useLazyQuery(GET_STATES, {
    onError: (error) => toast.error(parseGraphqlError(error)),
    onCompleted: (data) => {
      setQuotesLocation({
        ...quotesLocation,
        [locationType]: {
          ...quotesLocation[locationType],
          states: data.getStates,
        },
      });
    },
  });

  const getStatesList = (countryCode) => {
    getStates({ variables: { countryCode } });
  };

  return [getStatesList, statesResult];
};

const useGetCitiesQuery = (onCompleted, locationType) => {
  const sendAlert = useSendAlert();
  const [quotesLocation, setQuotesLocation] = useRecoilState(
    quotesLocationDataState
  );
  const {
    mutate: getCities,
    isLoading,
    isError,
    data,
  } = useReactQueryMutation(GET_CITIES_QUERY, {
    onSuccess: (data) => {
      onCompleted && onCompleted(data?.data?.postalLocationList);
      if (locationType) {
        setQuotesLocation({
          ...quotesLocation,
          [locationType]: {
            ...quotesLocation[locationType],
            cities: data?.data?.postalLocationList,
          },
        });
      }
    },
    onError: (error) => {
      sendAlert(parseGraphqlError(error), "error");
    },
  });
  const getCitiesMutationResult = {
    loading: isLoading,
    error: isError,
    data,
  };

  return [getCities, getCitiesMutationResult];
};

export {
  useGetShipmentRateQuery,
  useGetCountriesQuery,
  useGetStatesQuery,
  useGetCitiesQuery,
  useGetCountriesLazyQuery,
};
