import { ResourcesArray, productArray } from './constants';

export const ServiceDropdownComponent = ({ handleServiceMenuClick }) => {
  return (
    <div className='new-home-page__services service__dropdown'>
      <div className='dropdown__content'>
        <div className='column__items'>
          <p className='column-item-header'>International Shipping</p>
          <ul>
            <li onClick={handleServiceMenuClick}>
              <a href='/ng'>Ship from Nigeria to UK, USA, {'&'} 150+ cities</a>
            </li>
            <li onClick={handleServiceMenuClick}>
              <a href='/us/ng'>Ship from the US to Nigeria</a>
            </li>
            <li onClick={handleServiceMenuClick}>
              <a href='/uk/ng'>Ship from the UK to Nigeria</a>
            </li>
          </ul>
        </div>
        <div className='new-home-page__services column__items'>
          <p className='column-item-header'>Inter-state Delivery</p>
          <ul>
            <li onClick={handleServiceMenuClick}>
              <a href='/lagos'>Lagos to other States</a>
            </li>
            <li onClick={handleServiceMenuClick}>
              <a href='/abuja'>Abuja to other States</a>
            </li>
            <li onClick={handleServiceMenuClick}>
              <a href='/ibadan'>Ibadan to other States</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export const ProductsDropdownComponent = ({ handleProductMenuClick }) => {
  return (
    <div className='new-home-page__services service__dropdown'>
      <div className='dropdown__content'>
        <div className='product__items'>
          <ul>
            {productArray.map((item, index) => {
              return (
                <li key={index} onClick={handleProductMenuClick}>
                  <a href={item.path}>
                    <span>{item.product}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export const ResourcesDropdownComponent = ({
  handleProductMenuClick,
  setNavMobileMenu
}) => {
  return (
    <div className='new-home-page__services service__dropdown'>
      <div className='dropdown__content'>
        <div className='product__items'>
          <ul>
            {ResourcesArray.map((item, index) => {
              return (
                <li key={index} onClick={handleProductMenuClick}>
                  <a href={item.path}>
                    <span>{item.product}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
