import React from 'react';
import { addressLists, socialLists } from '../constants/contact-lists';
import {
  buildingImage,
  mailImage,
  phoneImage
} from '../constants/asset-constants';

const ContactUs = ({ children }: any) => {
  return (
    <div className='about'>
      <div className='about__contact'>
        <div className='about__contact__wrap'>
          <div className='about__contact__header'>
            <h2>Contact Us</h2>
          </div>
          <div className='about__contact__items'>
            <div className='about__contact__items__wrap'>
              <div className='about__contact__item'>
                <div className='about__contact__item__wrap'>
                  <div className='about__contact__item__icon'>
                    <img src={buildingImage} className='img-fluid' alt='' />
                  </div>
                  <div className='about__contact__item__content'>
                    <div className='addresses'>
                      {addressLists.map((item, index) => {
                        return (
                          <div key={index} className='address_item'>
                            <div className='address_header'>
                              <h4>{item.location} Drop-off center</h4>
                            </div>
                            <div className='address_content'>
                              <p>{item.address}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className='about__contact__item'>
                <div className='about__contact__item__wrap'>
                  <div className='about__contact__item__icon'>
                    <img src={phoneImage} className='img-fluid' alt='' />
                  </div>
                  <div className='about__contact__item__content'>
                    <p>02013302594</p>
                  </div>
                </div>
              </div>
              <div className='about__contact__item'>
                <div className='about__contact__item__wrap'>
                  <div className='about__contact__item__icon'>
                    <img src={mailImage} className='img-fluid' alt='' />
                  </div>
                  <div className='about__contact__item__content'>
                    <p>hello@topship.africa</p>
                  </div>
                </div>
              </div>
              <div className='about__contact__item'>
                <div className='about__contact__item__wrap'>
                  <div className='about__contact__item__header'>
                    <h6>Connect with us on</h6>
                  </div>
                  <div className='about__contact__item__content'>
                    <div className='social-items'>
                      {socialLists.map((social, index) => {
                        return (
                          <div key={index} className='social-item'>
                            <a
                              href={social.link}
                              target='_blank'
                              rel='noreferrer'
                            >
                              <img src={social.imgSrc} alt='' />
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
