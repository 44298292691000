import React from 'react';

const CustomSpinner = ({
  size,
  thickness,
  background,
  spinColor,
  text = '',
  textSize = '',
  textColor = '',
  speed = '1.5s',
  style = {}
}) => {
  return (
    <div className='custom-spinner' style={style}>
      <div
        className='custom-spinner__spinner'
        style={{
          height: size || '7rem',
          width: size || '7rem',
          border: `${thickness || '1rem' || '1rem'} solid ${
            background || 'grey'
          } `,
          borderTop: `${thickness || '1rem'} solid ${
            spinColor || 'var(--color-primary)'
          } `,
          borderRight: `${thickness || '1rem'} solid ${
            spinColor || 'var(--color-primary)'
          } `,

          background: 'transparent',
          animation: `spin ${speed || '1.5s'} linear infinite`
        }}
      ></div>
      <span style={{ fontSize: textSize, color: textColor }}>{text}</span>
    </div>
  );
};

export default CustomSpinner;
