import {
  AccessRoutesImg,
  CompleteVisibilityImg,
  CustomsSupportImg,
  FreightSupport,
  IncreaseProfitsImg,
  ProfitArrow,
  RoutesArrow,
  StrengthenBrandImg
} from './asset-constants';

export const gatewayBenefits = [
  {
    title: 'Access more routes',
    service:
      ' Offer your customers reliable international and inter-state deliveries when you tap into our network of vetted routes and courier partners.',
    icon: AccessRoutesImg,
    textIcon: RoutesArrow
  },
  {
    title: 'Increase Profits',
    service: ' Set your prices and earn money on every shipment.',
    icon: IncreaseProfitsImg,
    textIcon: ProfitArrow
  },
  {
    title: 'No unexpected charges & Complete Visibility',
    service:
      'We proactively alert you to weight discrepancies BEFORE your package leaves the country.',
    icon: CompleteVisibilityImg
  },
  {
    title: 'Strengthen Your Brand & Customer Experience',
    service:
      'Generate instant waybills and shipping quotes customised with your business name and logo.',
    icon: StrengthenBrandImg
  },
  {
    title: 'Freight Management Technology with Support',
    service:
      'Request a demo or register for a free account to experience the power of GateWay firsthand. Our dedicated customer support representatives are always ready to assist you every step of the way.',
    icon: FreightSupport
  },
  {
    title: 'Customs Support',
    service:
      ' Gain support predicting and negotiating customs charges for your shipments.',
    icon: CustomsSupportImg
  }
];
