import { useState } from 'react';
import NewFooter from '../components/new-footer';
import GetMobileApp from '../components/new-home-page/get-mobile-app';
import { GatewayTransparentBg } from '../constants/asset-constants';
import { gatewayFaqs } from '../constants/faqs';
import Slider, { Settings } from 'react-slick';
import { gatewayBenefits } from '../constants/gateway-benefits';
import BusyOverlay from '../components/busy-overlay';
import FaqBlock from '../components/faq-block';
import { ContactForm } from '../components/contact-form';
import { Helmet } from 'react-helmet';

export default function GatewayPage() {
  const [submitEmailLoading] = useState(false);

  const currentURL = 'https://ship.topship.africa';

  const settings: Settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,

    autoplaySpeed: 2500
  };

  return (
    <>
      <Helmet>
        <title>
          Gateway by Topship: The Partner for your Logistics Business
        </title>
        <meta
          name='description'
          content='Gateway by Topship is the growth partner for third-party logistics businesses in Nigeria. Elevate your logistics operations with our comprehensive suite of services and expert support.'
        />
        <meta
          name='keywords'
          content='Logistics business, third-party logistics, Topship, logistics service, logistics company, freight forwarding, 3PL, logistics provider, logistics shipping company, supply chain management'
        />
      </Helmet>
      <div className='gateway'>
        <section className='gateway__intro'>
          <div className='intro-content'>
            <h1 className='heading'>
              The Growth Partner for your Logistics Business
            </h1>
            <p>
              Welcome to GateWay by Topship. Let us help you access more routes
              and better prices for your third-party logistics business.
            </p>
            <div className='cta-group mt-5'>
              <a href='#contact-form' className='start-shipping'>
                Contact Us
              </a>
            </div>
          </div>
        </section>

        <section className='gateway__routes'>
          <div className='routes-body'>
            <h1 className='heading'>
              GateWay by Topship helps you grow and manage your third-party
              logistics or freight-forwarding business.
            </h1>
            <div
              className='benefit-card'
              style={{
                position: 'relative'
              }}
            >
              <Slider {...settings}>
                {gatewayBenefits.map(benefit => {
                  return (
                    <div className='routes-content'>
                      <img
                        src={GatewayTransparentBg}
                        className='transparent-bg'
                        alt=''
                      />
                      <div className='text-content'>
                        <div
                          className='header-flex'
                          style={{
                            display: 'flex'
                          }}
                        >
                          <h4>{benefit.title}</h4>
                          {benefit.textIcon ? (
                            <img src={benefit.textIcon} alt='' />
                          ) : null}
                        </div>

                        <p>{benefit.service}</p>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href={`${currentURL}/signup?isLsp=true`}
                          className='button-white'
                        >
                          Get started
                        </a>
                      </div>
                      <div className='benefit-img-wrap'>
                        <img src={benefit.icon} alt='' />
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </section>
        {/* <section className='gateway__faqs'>
          <div className=''>
            <h1 className='contact-heading mb-5'>
              Need help getting started? Contact our Sales Team at 09163842013
              or email gateway@topship.africa
            </h1>

          </div>
        </section> */}
      </div>
      <FaqBlock questions={gatewayFaqs} />
      <ContactForm serviceTitle={'Gateway'} />

      <GetMobileApp />
      <section className='new-home-page__footer'>
        <NewFooter />
      </section>
      <BusyOverlay loading={submitEmailLoading} />
    </>
  );
}
