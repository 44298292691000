import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import GetMobileApp from '../components/new-home-page/get-mobile-app';
import SearchInput from '../components/search-component';
import { FaqBg, JetCircleBlue } from '../constants/asset-constants';
import { articleData } from '../constants/faqs';

export type Article = {
  title: string;
  textContent: string;
  icon: string;
  articleNumber: number;
}[];

interface ArticleCardProps {
  articles: Article;
}
const ArticleIntroCard: FC<ArticleCardProps> = ({ articles }) => {
  const history = useHistory();

  const toggleRouter = (article: any) => {
    history.replace({
      ...history.location,
      pathname: '/faq-article',
      state: {
        title: article.title,
        textContent: article.textContent,
        articleNumber: article.articleNumber
      }
    });
  };
  return (
    <div className='article-card-group'>
      {articles.map((article, index) => {
        return (
          <div
            key={index}
            onClick={() => toggleRouter(article)}
            className='article-card'
          >
            <img src={article.icon} alt='contact icon' />
            <h4>{article.title}</h4>
            <p>{article.textContent}</p>
            <div className='footer-span'>
              <img src={JetCircleBlue} alt='' />
              <span>{article.articleNumber} Articles</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const FAQ = () => {
  const [searchText, setSearchText] = useState('');
  const [articleLists, setArticleLists] = useState(articleData);
  const [showFilter, setShowFilter] = useState(false);

  const handleSearchChange = (e: any) => {
    const query = e.target.value;
    setSearchText(query);
    if (query.trim() === '') {
      setArticleLists(articleData);
    } else {
      const filtered = articleData.filter(article =>
        article.title.toLowerCase().includes(query.toLowerCase())
      );
      setArticleLists(filtered);
    }
  };

  return (
    <div className='new-faq'>
      <img
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%'
        }}
        src={FaqBg}
        alt=''
      />

      <section className='new-faq__home-intro'>
        <p className='sub-heading'>Topship Help Center</p>
        <div className=''>
          <div className='content-wrapper'>
            <h2>Answers from the Topship team</h2>
            <div className='search-wrap'>
              <SearchInput
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                setArticleTitle={null}
                searchData={[]}
                value={searchText}
                onChange={handleSearchChange}
                onSearch={handleSearchChange}
              />
            </div>
          </div>
        </div>
      </section>
      <section className='new-faq__article-group'>
        <ArticleIntroCard articles={articleLists} />
      </section>
      <GetMobileApp />
    </div>
  );
};

export default FAQ;
