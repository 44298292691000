import { Link } from 'react-router-dom';
import NewFooter from '../components/new-footer';
import { ResourcesBg, WhiteArrow } from '../constants/asset-constants';

const ResourcesArray = [
  {
    path: '/shipping-checklist',
    product: 'Smart Shipping Checklist'
  },
  {
    path: '/prohibited-items',
    product: 'Prohibited Items'
  },
  {
    path: '/non-served-countries',
    product: 'Non-Served Countries & Territories'
  },
  {
    path: '/shipping-documentation',
    product: 'Shipping Documentation'
  }
];

const ResourcesPage = () => {
  return (
    <>
      <div className='resources'>
        <section className='resources__header-wrap'>
          <div className='intro-wrap'>
            <h2 className='heading'>Resources</h2>
            <p>Find guides and resources for a smoother shipping experience</p>
          </div>
          <img src={ResourcesBg} alt='resources' />
        </section>
        <section className='resources__resources-wrap'>
          {ResourcesArray.map((resource, index) => {
            return (
              <div className='resource-item'>
                <p className='mb-0'>{resource.product}</p>
                <Link to={resource.path}>
                  <span className='view'>
                    View
                    <img
                      style={{
                        paddingLeft: '5px'
                      }}
                      src={WhiteArrow}
                      alt=''
                    />
                  </span>
                </Link>
              </div>
            );
          })}
        </section>

        <section className='new-home-page__footer'>
          <NewFooter />
        </section>
      </div>
    </>
  );
};

export default ResourcesPage;
