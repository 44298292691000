export const prohibitedExpressNationwide = [
  'Cooked meals',
  'Alcohol and Non-alcoholic drinks',
  'Pets',
  'Fruits & Vegetables',
  'Ammunition',
  'Bank bills, notes, or currency',
  'Fireworks',
  'Hazardous Waste',
  'Perishables',
  'Radioactive materials',
  'Illegal Items, according to law enforcement'
];
export const prohibitedExpressInter = [
  'Cooked meals',
  'Raw meat',
  'Snails',
  'Fruits & Vegetables',
  'Pets',
  'Alcohol',
  'Grains',
  'Pasta (Noodles)',
  'Perfumes',
  'Gold',
  'Minerals & Precious Stones',
  'Ammunition',
  'Bank bills, notes or currency',
  'Fireworks',
  'Hazardous Waste',
  'Perishables',
  'Radioactive materials',
  'Illegal items, according to law enforcement',
  'Herbs/ Herbal medicine- Can be shipped with a phytosanitary/Quarantine certificate',
  "Medication- Prohibited in Canada/US- Can be shipped with a signed and stamped doctor's prescription to other countries",
  'Dried Fish- Should not exceed 0.5kg per waybill',
  'Milk/ Dairy products',
  'Skincare to Spain- The receiver must have a sanitary import license for clearance',
  'Batteries (Prohibited in all countries)',
  'Power bank(Prohibited in all countries)',
  'Phones (Only 2 pcs of phones per waybill is allowed)',
  'Alcoholic Beverages',
  'Palm oil'
];

export const prohibitedSavers = [
  'Cooked meals',
  'Raw meat',
  'Snails',
  'Fruits & Vegetables',
  'Pets',
  'Alcohol',
  'Grains',
  'Pasta (Noodles)',
  'Perfumes',
  'Gold',
  'Minerals & Precious Stones',
  'Ammunition',
  'Bank bills, notes or currency',
  'Fireworks',
  'Hazardous waste',
  'Perishables',
  'Radioactive materials',
  'Illegal items, according to law enforcement',
  'All forms of fish',
  'Bees',
  'Bearer Bonds',
  'Cash, currency, negotiable instruments',
  'Cash on Delivery (COD) shipment',
  'Gambling devices',
  'Hazardous Waste',
  'Human Remains',
  'Live Fish',
  'Live Household Pets',
  'Live Monkeys',
  'Lottery tickets and materials',
  'Money (Paper /coins)',
  'Negotiable Instrument (stocks, bonds, cash letters, and other negotiable instruments equivalent to cash)',
  'Pornography and items of pornography',
  'Shipments prohibited by any law, statute, or regulation',
  'Switchblades and flick knives',
  'Arms and ammunition',
  'Maize',
  'Firearms',
  'Scrap metals',
  'Dry fish/meat/crayfish',
  'Maggi cubes of chicken flavour/ crayfish flavour/ meat flavour',
  'Timber (rough or sawn)',
  'Artifacts and Antiquities',
  'Certain fruits and vegetables',
  'Red Star Express Plc',
  'Unprocessed rubber latex and rubber lumps',
  'Animal products, by products and some animals (elephant tusks, original coral beads)',
  'Raw hides and skin (including wet blue and all unfinished leather)',
  'Wildlife animals classified as endangered species and their products'
];

export const budgetUkLists = [
  'Fruits and Vegetables',
  'Ammunition',
  'Bank bills, notes, or currency',
  'Fireworks',
  'Hazardous waste',
  'Radioactive materials',
  'Illegal items, according to law enforcement',
  'Perfumes',
  'Unground Ogbono',
  'pepper/melon/crayfish',
  'Dairy products',
  'Yam',
  'Beef/Ponmo',
  "Medication- Must have a doctor's prescription",
  'Snails',
  'Alcohol'
];
