import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { GatewayLogo } from '../constants/asset-constants';
import { TopShipBlueLogo, DownChevron } from './new-home-page/asset-constants';
import {
  ProductsDropdownComponent,
  ResourcesDropdownComponent,
  ServiceDropdownComponent
} from './new-home-page/dropdowns';

const Navigation = () => {
  const [navMobileMenu, setNavMobileMenu] = useState(false);
  const [fadeMenu, setFadeMenu] = useState('');
  const [serviceDropdown, setServiceDropdown] = useState(false);
  const [productDropdown, setProductDropdown] = useState(false);
  const [resourceDropdown, setResourceDropdown] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const html = window.document.querySelector('html');
    const body = window.document.querySelector('body');
    const root = window.document.querySelector('#root');
    if (navMobileMenu) {
      setFadeMenu('fade');
      html.style.overflow = 'hidden';
      body.style.overflow = 'hidden';
      root.style.overflow = 'hidden';
      setTimeout(() => {
        setFadeMenu('show');
      }, 50);
    } else {
      html.style.overflow = '';
      body.style.overflow = '';
      root.style.overflow = '';
    }
  }, [navMobileMenu]);

  const handleMenuClick = () => {
    navMobileMenu && setNavMobileMenu(!navMobileMenu);
  };

  return (
    <>
      <div className={`overlay ${navMobileMenu ? 'enabled' : ''}`}></div>
      <main className='new-home-page__main'>
        <nav
          className={`new-home-page__navigation${
            location.pathname === '/chinaimports' ? ' use-china' : ''
          }`}
        >
          <ul className='new-home-page__container header__navigation'>
            <li className='logo'>
              <a href='/'>
                {location.pathname === '/gateway' ? (
                  <img
                    className='gateway-logo'
                    src={GatewayLogo}
                    alt='gateway logo'
                  />
                ) : (
                  <img src={TopShipBlueLogo} alt='topship logo' />
                )}
              </a>
            </li>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li>
              <div
                onClick={() => setProductDropdown(!productDropdown)}
                className='new-home-page__services service__dropdown'
              >
                <div className=''>Services</div>
                {
                  <ProductsDropdownComponent
                    handleProductMenuClick={handleMenuClick}
                  />
                }
              </div>
            </li>
            <li className=''>
              <div
                onClick={() => setServiceDropdown(!serviceDropdown)}
                className='new-home-page__services service__dropdown'
              >
                <div className=''>Routes</div>
                {
                  <ServiceDropdownComponent
                    handleServiceMenuClick={handleMenuClick}
                  />
                }
              </div>
            </li>
            <li className=''>
              <div
                onClick={() => setServiceDropdown(!serviceDropdown)}
                className='new-home-page__services service__dropdown'
              >
                <Link to='/resources'>Resources</Link>
                {
                  <ResourcesDropdownComponent
                    handleServiceMenuClick={handleMenuClick}
                  />
                }
              </div>
            </li>
            <li>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://blog.topship.africa'
              >
                Blog
              </a>
            </li>
            <li>
              <Link to='/faq'>FAQs</Link>
            </li>
            <li>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://ship.topship.africa/tracking'
              >
                Track Shipment
              </a>
            </li>
            <li>
              <div className='cta-items'>
                <div className='cta-item'>
                  <a href='https://ship.topship.africa/login'>
                    <div className='new-home-page__link-button bttn-sm bttn-light-blue'>
                      Sign in
                    </div>
                  </a>
                </div>
                <div className='cta-item'>
                  <a href='/#contact-form'>
                    <div
                      style={{
                        width: '180px'
                      }}
                      className='new-home-page__link-button bttn-sm bttn-yellow'
                    >
                      Contact Sales
                    </div>
                  </a>
                </div>
              </div>
            </li>
          </ul>
          <div
            onClick={() => setNavMobileMenu(prev => !prev)}
            className={`new-home-page__mobile__menu__hamburger ${
              navMobileMenu ? 'open' : ''
            }`}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </nav>
        <div
          className={`new-home-page__mobile__menu ${
            navMobileMenu ? `open ${fadeMenu}` : ''
          }`}
        >
          <ul className='new-home-page__mobile__menu__wrap'>
            <li onClick={() => setNavMobileMenu(false)}>
              <Link to='/about'>About</Link>
            </li>

            <li
              className='mobile-service'
              onClick={() => setProductDropdown(!productDropdown)}
            >
              <div className='d-flex align-items-center'>
                Services
                <img
                  style={{ transform: productDropdown ? 'rotate(180deg)' : '' }}
                  src={DownChevron}
                  alt='down chevron'
                />
              </div>
              <div className='new-home-page__services service__dropdown'>
                {productDropdown && (
                  <ProductsDropdownComponent
                    handleProductMenuClick={handleMenuClick}
                  />
                )}
              </div>
            </li>
            <li
              className='mobile-service'
              onClick={() => setServiceDropdown(!serviceDropdown)}
            >
              <div className='d-flex align-items-center'>
                Routes
                <img
                  style={{ transform: serviceDropdown ? 'rotate(180deg)' : '' }}
                  src={DownChevron}
                  alt='down chevron'
                />
              </div>
              <div className='new-home-page__services service__dropdown'>
                {serviceDropdown && (
                  <ServiceDropdownComponent
                    handleServiceMenuClick={handleMenuClick}
                  />
                )}
              </div>
            </li>
            <li
              className='mobile-service'
              onClick={() => setResourceDropdown(!resourceDropdown)}
            >
              <div className='d-flex align-items-center'>
                Resources
                <img
                  style={{
                    transform: resourceDropdown ? 'rotate(180deg)' : ''
                  }}
                  src={DownChevron}
                  alt='down chevron'
                />
              </div>
              <div className='new-home-page__services service__dropdown'>
                {resourceDropdown && (
                  <ResourcesDropdownComponent
                    handleServiceMenuClick={handleMenuClick}
                    setNavMobileMenu={setNavMobileMenu}
                  />
                )}
              </div>
            </li>
            <li onClick={() => setNavMobileMenu(false)}>
              <Link to='/faq'>FAQs</Link>
            </li>
            <li onClick={() => setNavMobileMenu(false)}>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://ship.topship.africa/tracking'
              >
                Track Shipment
              </a>
            </li>
            <li onClick={() => setNavMobileMenu(false)}>
              <div className='cta-items'>
                <div className='cta-item'>
                  <a href='https://ship.topship.africa/login'>
                    <div className='new-home-page__link-button bttn-lg bttn-blue'>
                      Sign in
                    </div>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </main>
    </>
  );
};

export default Navigation;
