import React from 'react';
import { Link } from 'react-router-dom';
import Robot from './../assets/svgs/robot.svg';

const NotFound = ({
  heading = 'Nothing here',
  body = 'Come back later',
  children
}: any) => (
  <div
    className='notfound'
    style={{
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex'
    }}
  >
    <div className='text-center'>
      <figure>
        <img src={Robot} alt='not found' className='img' />
      </figure>

      <h1>{heading}</h1>

      {!children && (
        <div>
          <h3>{body}</h3>
          <div className='button'>
            <Link to='/'>
              <button className='submit-button'>Back To Homepage</button>
            </Link>
          </div>
        </div>
      )}

      {children}
    </div>
  </div>
);

export default NotFound;
