import { TopShipWhiteLogo } from './new-home-page/asset-constants';
import {
  footerFacebook,
  footerTwitter,
  footerLinkedin,
  footerInstagram,
  footerYoutube
} from '../constants/asset-constants';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useState } from 'react';
import axios from 'axios';
import { useSendAlert } from '../utilities/send-alert';
import { parseGraphqlError } from '../utilities/parse-graphql-error';
import BusyOverlay from './busy-overlay';
import { supportArray } from './new-home-page/constants';

const NewFooter = () => {
  const [submitEmailLoading, setSubmitEmailLoading] = useState(false);
  const sendAlert = useSendAlert();
  const SLACK_URL = process.env.REACT_APP_SLACK_API;

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm
  } = useFormik({
    initialValues: {
      email: '',
      firstName: ''
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email('Please enter a valid email')
        .required('Please enter an email'),
      firstName: yup.string().required('Please enter a name')
    }),
    onSubmit: async values => {
      setSubmitEmailLoading(true);

      try {
        const response = await axios.post(SLACK_URL, {
          text: `Email: ${values.email} First Name: ${values.firstName}`,
          channel: 'marketing'
        });

        if (response.data) {
          setSubmitEmailLoading(false);
          sendAlert(
            'Demo requested successfully. We would contact you shortly!',
            'success'
          );
          resetForm();
        }
      } catch (error) {
        setSubmitEmailLoading(false);

        sendAlert(parseGraphqlError(error), 'error');

        throw error;
      }
    }
  });

  return (
    <footer className='new-home-page__footer-container'>
      <div className='footer-content-items'>
        <div className='help-wrap mobile-version'>
          {supportArray.map(support => {
            return (
              <div className='help-item'>
                <div className='mb-1'>
                  <img
                    style={{
                      paddingRight: '4px'
                    }}
                    src={support.icon}
                    alt=''
                  />
                  <span>{support.title}</span>
                </div>
                <a
                  style={{
                    textDecoration: 'underline'
                  }}
                  href={`${
                    support.label === '02013302594'
                      ? `tel:${support.label}`
                      : support.label === '+2349080777728'
                      ? `https://wa.me/${support.label}`
                      : `mailto:${support.label}`
                  }`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {support.label}
                </a>
              </div>
            );
          })}
          <hr
            style={{
              border: '1px solid rgba(255, 255, 255, 0.7)',
              marginBottom: '30px'
            }}
          />
        </div>

        <div className='footer-content-item'>
          <div className='content-item-columns'>
            <div className='column-items topship-text'>
              <div className='help-item web-version'>
                <div className='mb-1'>
                  <img
                    style={{
                      paddingRight: '4px'
                    }}
                    src={supportArray[0].icon}
                    alt=''
                  />
                  <span>{supportArray[0].title}</span>
                </div>
                <a
                  href={`tel:${supportArray[0].label}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {supportArray[0].label}
                </a>
              </div>
              <img
                className='footer-logo'
                src={TopShipWhiteLogo}
                alt='topship logo'
              />
              <p className='topship-footer-text'>
                Topship helps you send and receive items from your doorstep to
                anywhere in the world
              </p>
            </div>
            <div className='column-items'>
              <div className='help-item web-version'>
                <div className='mb-1'>
                  <img
                    style={{
                      paddingRight: '4px'
                    }}
                    src={supportArray[1].icon}
                    alt=''
                  />
                  <span>{supportArray[1].title}</span>
                </div>
                <a
                  href={`mailto:${supportArray[1].label}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {supportArray[1].label}
                </a>
              </div>
              <ul>
                <li className='column-item column-item-header'>
                  International Shipping
                </li>
                <li>
                  <a href='/ng'>
                    Ship from Nigeria to UK, USA, {'&'} 150+ cities
                  </a>
                </li>
                <li>
                  <a href='/us/ng'>Ship from the US to Nigeria</a>
                </li>
                <li>
                  <a href='/uk/ng'>Ship from the UK to Nigeria</a>
                </li>
              </ul>
            </div>
            <div className='column-items'>
              <div className='help-item web-version'>
                <div className='mb-1'>
                  <img
                    style={{
                      paddingRight: '4px'
                    }}
                    src={supportArray[2].icon}
                    alt=''
                  />
                  <span>{supportArray[2].title}</span>
                </div>
                <a
                  href={`https://wa.me/${supportArray[2].label}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {supportArray[2].label}
                </a>
              </div>
              <ul>
                <li className='column-item column-item-header'>
                  Inter-state Delivery
                </li>
                <li>
                  <a href='/lagos'>Lagos to other States</a>
                </li>
                <li>
                  <a href='/abuja'>Abuja to other States</a>
                </li>
                <li>
                  <a href='/ibadan'>Ibadan to other States</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr
          style={{
            border: '1px solid rgba(255, 255, 255, 0.7)',
            marginBlock: '30px'
          }}
        />
        <div className='footer-content-item-newsletter  mb-5'>
          <div className='newsletter-content'>
            <div
              className='column-item column-item-header'
              style={{
                textAlign: 'left',
                fontSize: '32px',
                lineHeight: '38px',
                fontWeight: '500'
              }}
            >
              Stay up to date
              <br />
              with our newsletter
            </div>

            <form className='' onSubmit={handleSubmit}>
              <div className='input-group'>
                <div className='input-field name-input'>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    type='text'
                    placeholder='Enter your first name'
                    name='firstName'
                    className=''
                  />
                  {touched.firstName && errors.firstName && (
                    <span className='validation-error text-center footer-error-message'>
                      {errors.firstName}
                    </span>
                  )}
                </div>
                <div className='input-field email-input'>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    type='email'
                    placeholder='Enter your email'
                    name='email'
                    className=''
                  />
                  {touched.email && errors.email && (
                    <span className='validation-error text-center footer-error-message'>
                      {errors.email}
                    </span>
                  )}
                </div>
              </div>
              <button
                type='submit'
                disabled={errors.email || errors.firstName ? true : false}
                className='footer-button'
              >
                Subscribe
              </button>
            </form>
          </div>
          <div className='footer-socials'>
            <a
              href={`https://web.facebook.com/topshipafrica?_rdc=1&_rdr`}
              rel='noreferrer'
              target='_blank'
            >
              <img className='facebook-icon' src={footerFacebook} alt='' />
            </a>
            <a
              href='https://twitter.com/topshipafrica'
              rel='noreferrer'
              target='_blank'
            >
              <img src={footerTwitter} alt='' />
            </a>
            <a
              href='https://www.linkedin.com/company/topshipafrica/'
              rel='noreferrer'
              target='_blank'
            >
              <img className='linkedin-icon' src={footerLinkedin} alt='' />
            </a>
            <a
              href='https://www.instagram.com/topshipafrica/'
              rel='noreferrer'
              target='_blank'
            >
              <img src={footerInstagram} alt='' />
            </a>
            <a
              href='https://www.youtube.com/channel/UC8q9ckTRsIfnE1srJOWPnug'
              rel='noreferrer'
              target='_blank'
            >
              <img src={footerYoutube} alt='' />
            </a>
          </div>
        </div>
        <hr
          style={{
            border: '1px solid rgba(255, 255, 255, 0.7)',
            marginBlock: '30px'
          }}
        />
        <div className='footer-content-item'>
          <div className='rights-reserved-item mb-3'>
            <p>
              &copy; {new Date().getFullYear()} Topship Africa. All rights
              reserved.
            </p>
          </div>
          <div className='rights-reserved-items'>
            <ul className='rights-reserved-contents'>
              <li>
                <a href='tel:+2349080777728' target='_blank' rel='noreferrer'>
                  Contact&#8599;
                </a>
              </li>
              <li>
                <a
                  href='https://api.whatsapp.com/send?phone=2349080777728'
                  target='_blank'
                  rel='noreferrer'
                >
                  WhatsApp&#8599;
                </a>
              </li>
              <li className='terms-privacy-cookies'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://ship.topship.africa/terms'
                >
                  Terms&#8599;
                </a>
              </li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://ship.topship.africa/terms'
                >
                  Privacy&#8599;
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <BusyOverlay loading={submitEmailLoading} />
    </footer>
  );
};

export default NewFooter;
