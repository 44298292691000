import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Blogs from "../components/blog/blog-list";
import CustomLoader from "../components/custom-loader";
import Footer from "../components/footer";
import { MailChimpForm } from "../components/mailchimp";
import Nav from "../components/nav";

const Blog = () => {
  const [mobileMenu, setMobileMenu] = useState<string>("default");
  const [isLoading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      try {
        const response = await axios.get(
          "https://blog.topship.africa/wp-json/wp/v2/posts"
        );
        setPosts(response.data);
        setLoading(false);
      } catch (error) {
        if (error) {
          console.error(error);
        }
      }
    };
    getPosts();
  }, [setPosts]);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Updates, announcements, and tips from Team Topship!"
        />
        <title>Topship Africa: Blog</title>
        <link rel="canonical" href="https://topship.africa/blog" />
      </Helmet>
      <Nav mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
      <main className="blog">
        <section className="blog__header">
          <h1>The Topship Blog</h1>
          <p>Updates, announcements, and tips from Team Topship!</p>

          <MailChimpForm
            emailLabel="Email Address"
            firstNameLabel="First name"
          />
        </section>
        <div className="blog__container">
          {isLoading ? (
            <div className="blog__loader">
              <CustomLoader />
            </div>
          ) : (
            <Blogs posts={posts} post={[]} />
          )}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Blog;
