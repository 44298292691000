import { ArrowUp } from '../../constants/asset-constants';

export const AccountCreation = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${
        activeArticle === 'account-creation' ? 'active' : ''
      }`}
    >
      <div className='flexed-heading'>
        <h2>How to create an account (it’s free!)</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div
        className={`${activeArticle === 'account-creation' ? 'show' : 'hide'}`}
      >
        <ul>
          <li>
            Go to the GateWay{' '}
            <a
              target={'_blank'}
              rel='noreferrer'
              href='https://ship.topship.africa/signup?isLsp=true'
            >
              sign up page
            </a>
          </li>
          <li>
            Fill out the sign up form and selected “Logistics Company” as
            Account Type
          </li>
          <li>Enter your business name and upload your business logo</li>
          <li>You’re all set!</li>
        </ul>
      </div>
    </div>
  );
};

export const SetPricing = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'set-pricing' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>How to set your prices</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'set-pricing' ? 'show' : 'hide'}`}>
        <ul>
          <li>
            <a
              target={'_blank'}
              rel='noreferrer'
              href='https://ship.topship.africa/login'
            >
              Sign in to your account
            </a>
          </li>
          <li>Go to “Settings” on the left menu bar </li>
          <li>
            Enter your preferred charge (this can be an additional charge or
            reduced charge, e.g. enter 2000 to increase the price by N2,000 or
            enter -2000 to reduce the price by N2,000)
          </li>
          <li>Save</li>
        </ul>
      </div>
    </div>
  );
};

export const WhyGateway = ({ activeArticle, handleToggle }: any) => {
  return (
    <div
      onClick={handleToggle}
      className={`content ${activeArticle === 'why-gateway' ? 'active' : ''}`}
    >
      <div className='flexed-heading'>
        <h2>Why GateWay by Topship?</h2>
        <img
          style={{
            height: '10px',
            width: '10px'
          }}
          src={ArrowUp}
          alt='accordion toggle button'
        />
      </div>
      <div className={`${activeArticle === 'why-gateway' ? 'show' : 'hide'}`}>
        <ul>
          <li>
            Access more international routes at discounted prices, through our
            network of vetted routes and trusted courier partners.
          </li>
          <li>Set your own prices and earn money on each shipment.</li>
          <li>
            GateWay proactively alerts you to weight discrepancies before your
            package leaves the country, allowing you to control your expenses.
          </li>
          <li>Real-time tracking for all services.</li>
          <li>
            Generate instant waybills and shipping quotes customised with your
            business name and logo.
          </li>
          <li>Gain support negotiating customs charges for your shipments.</li>
        </ul>
      </div>
    </div>
  );
};
