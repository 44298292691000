import React, { useRef } from 'react';
import {
  DoorToDoor,
  SuccessfulDelivery,
  Rates,
  Support,
  Tracking
} from './asset-constants';
import Slider, { Settings } from 'react-slick';
import { NextIcon, PrevIcon } from '../../constants/asset-constants';
const data: { type: string; icon: string }[] = [
  {
    type: 'Door to door Delivery',
    icon: DoorToDoor
  },
  {
    type: '3-5 days express delivery',
    icon: SuccessfulDelivery
  },
  {
    type: 'Affordable rates',
    icon: Rates
  },
  {
    type: 'Friendly customer service and merchant support reps',
    icon: Support
  },
  {
    type: 'Live tracking and notifications on all shipments',
    icon: Tracking
  }
];

const Carousel: React.FC = () => {
  const sliderRef = useRef<Slider>(null);
  const settings: Settings = {
    infinite: true,
    centerMode: true,
    centerPadding: '60px',
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '30px'
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '15px'
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '7.5px'
        }
      }
    ]
  };
  return (
    <div className='shipping-experience-container'>
      <div className='shipping-experience-item-container'>
        <Slider {...settings} ref={sliderRef}>
          {data.map((service, index) => {
            return (
              <div key={index} className='shipping-experience-item'>
                <img src={service.icon} alt='imports' />
                <p className='bold-text'>{service.type}</p>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className='carousel-arrows'>
        <img
          src={PrevIcon}
          alt=''
          className='carousel-arrow'
          onClick={() => sliderRef.current?.slickPrev()}
        />
        <img
          src={NextIcon}
          alt=''
          className='carousel-arrow'
          onClick={() => sliderRef.current?.slickNext()}
        />
      </div>
    </div>
  );
};

export default Carousel;
